import { NavigationList, NavigationListItemModel } from 'components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Typography } from '@material-ui/core';
import ChannelIcon from '@material-ui/icons/PlayCircleFilled';

import { useStyles } from './stream-form.jss';

interface StreamFormActionsProps {
  selectChannel: () => void;
  selectedChannelName?: string;
  loadingChannel: boolean;
}

export const StreamFormActions = React.memo((props: StreamFormActionsProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const items = useMemo<NavigationListItemModel[]>(
    () => [
      {
        icon: <ChannelIcon />,
        labelKey: 'streams.nav.selectChannel',
        onClick: props.selectChannel,
        subtitle: (
          <div className={classes.actionSelectedChannel}>
            {props.loadingChannel ? (
              t<string>('streams.nav.loadingFirstChannel')
            ) : props.selectedChannelName ? (
              props.selectedChannelName
            ) : (
              <div className={classes.actionSelectedChannelValidation}>
                {t<string>('streams.nav.noChannelSelected')}
              </div>
            )}
          </div>
        ),
      },
    ],
    [props.selectChannel, props.selectedChannelName]
  );

  return <NavigationList items={items} />;
});
