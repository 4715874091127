import { FlexFadeIn } from 'components';
import { WorkgroupFilterFooter } from 'components/ExternalFilterFooter';
import { WorkgroupLocation } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DataGrid, DataGridHeader, DataGridSelectionType, Footer, GridCard, IdName, useBladeButtons,
    useBladeClosing, useGridApi
} from 'react-tools';

import { useLocationListBladeButtons } from './buttons.hook';
import { locationsColumnSettings } from './column.settings';
import { LocationListLoader } from './loader';
import { useStyles } from './locations.jss';

export interface WorkgroupLocationsProps {
  items: Array<WorkgroupLocation>;
  workgroup: IdName;
  preselected: WorkgroupLocation[];
  fetchingLocations: boolean;
  bladeId: string;
}

export interface WorkgroupLocationsActions {
  onClose: () => void;
  fetchLocations: (workgroupId: number) => void;
  createLocation: () => void;
  editLocation: (workgroup: WorkgroupLocation) => void;
  deleteLocations: (workgroups: WorkgroupLocation[]) => void;
  clearPreselected: () => void;
}

type Props = WorkgroupLocationsProps & WorkgroupLocationsActions;

export const WorkgroupLocations: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  
  const [columns] = useState(locationsColumnSettings);
  const gridApi = useGridApi();

  const itemsCount = useMemo(() => {
    const topLevel = props.items.reduce(
      (c, item) => (item.workgroupId === props.workgroup.id ? c + 1 : c),
      0
    );
    const all = props.items.length;
    return { topLevel, all };
  }, [props.items]);

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {
      props.onClose();
    }
  );
  const [selected, setSelected] = useState<WorkgroupLocation[]>([]);

  useEffect(() => {
    if (props.workgroup.id) {
      props.fetchLocations(props.workgroup.id);
    }
  }, [props.workgroup.id]);

  useEffect(() => {
    setSelected(props.preselected);
  }, [props.preselected]);

  const fetchLocationsCallback = useCallback(() => {
    props.fetchLocations(props.workgroup.id);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchLocations, props.workgroup.id]);

  const recurseFilterFn = useCallback(
    (row: any) => {
      return row.data.workgroupId === props.workgroup.id;
    },
    [props.workgroup.id]
  );

  const buttons = useLocationListBladeButtons(
    fetchLocationsCallback,
    props.createLocation,
    props.editLocation,
    selected,
    props.fetchingLocations,
    gridApi
  );

  useBladeButtons(buttons, [buttons]);

  const onWorkgroupLocations = useCallback(
    (items: IdName[]) => setSelected(items as WorkgroupLocation[]),
    [props.editLocation]
  );

  const MemoizedGrid = useMemo(() => {
    return (
      <DataGrid
        onSelect={onWorkgroupLocations}
        identifier={'workgroupLocations'}
        noDataMessage={'workgroupLocations.noResults'}
        selectionType={DataGridSelectionType.Multiple}
        defaultSelection={props.preselected}
        items={props.items}
        columnSettings={columns}
        externalFilterFn={recurseFilterFn}
        isExternalFilterEnabled={() => true}
      />
    );
  }, [props.items, props.preselected, recurseFilterFn]);

  return props.fetchingLocations ? (
    <LocationListLoader />
  ) : (
    <FlexFadeIn>
      <div className={classes.container}>
        <GridCard>
          <DataGridHeader loading={props.fetchingLocations} />
          {MemoizedGrid}
        </GridCard>
        <Footer>
          <WorkgroupFilterFooter />
        </Footer>
      </div>
    </FlexFadeIn>
  );
};
