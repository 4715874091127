import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { CountFooter,WorkgroupFilterFooter } from 'components/ExternalFilterFooter';
import { DeviceListItem } from 'models/devices';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DataGrid, DataGridHeader, DataGridSelectionType, Footer, GridCard, IdName, useBladeButtons,
    useBladeClosing, useGridApi
} from 'react-tools';

import { CircularProgress } from '@material-ui/core';

import { useDeviceListBladeButtons } from './buttons.hook';
import { devicesColumnSettings } from './column.settings';
import { useStyles } from './devices.jss';

export interface DevicesProps {
  bladeId: string;
  items: Array<DeviceListItem>;
  workgroup: IdName;
  location?: IdName;
  fetchingDevices: boolean;
  preselected: DeviceListItem[];
}

export interface DevicesActions {
  fetchDevices: (workgroup: IdName, location?: IdName) => void;
  createDevice: () => void;
  editDevice: (device: DeviceListItem) => void;
  clearPreselected: () => void;
  deleteDevices: (devices: DeviceListItem[]) => void;
}

type Props = DevicesProps & DevicesActions;

export const Devices: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [columns] = useState(devicesColumnSettings);
  const gridApi = useGridApi();
  const [t] = useTranslation();

  const [selected, setSelected] = useState<DeviceListItem[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchDevicesCallback = useCallback(() => {
    props.fetchDevices(props.workgroup, props.location);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchDevices, props.workgroup, props.location]);

  useEffect(() => {
    setSelected(props.preselected);
  }, [props.preselected]);

  const confirmDeleteDevices = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);

  const deleteDeviceCallback = useCallback(() => {
    props.deleteDevices(selected);    
    setDeleteDialogOpen(false);
    setSelected([]);
  }, [selected]);

  const closeDeleteConfirmationCallback = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  const buttons = useDeviceListBladeButtons(
    fetchDevicesCallback,
    props.createDevice,
    props.editDevice,
    confirmDeleteDevices,
    selected,
    props.fetchingDevices,
    gridApi
  );

  useBladeButtons(buttons, [selected, props.fetchingDevices]);

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {}
  );

  useEffect(() => {
    props.fetchDevices(props.workgroup, props.location);
  }, [props.workgroup, props.location]);

  const recurseFilterFn = useCallback(
    (row: any) => {
      return row.data.workgroupId === props.workgroup.id;
    },
    [props.workgroup.id]
  );

  const MemoizedGrid = useMemo(
    () => (
      <DataGrid
        onSelect={setSelected}
        identifier={'devices'}
        noDataMessage={'devices.noResults'}
        defaultSelection={props.preselected}
        selectionType={DataGridSelectionType.Multiple}
        idColumn={'id'}
        items={props.items}
        columnSettings={columns}
        externalFilterFn={props.location ? undefined : recurseFilterFn}
        isExternalFilterEnabled={props.location ? undefined : ()=>true }
      />
    ),
    [props.preselected, props.items, props.location, recurseFilterFn]
  );

  return props.fetchingDevices ? (
    <div className={classes.loadingContainer}>
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={60} />
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <GridCard>
        <DataGridHeader loading={props.fetchingDevices} />
        {MemoizedGrid}
      </GridCard>
      <ConfirmationDialog
        title={t<string>('devices.delete')}
        open={deleteDialogOpen}
        content={t<string>('devices.deleteConfirm.content', {count: selected ? selected.length : 0})}
        countCheck={selected ? selected.length : undefined}
        countCheckLabelKey={t<string>('devices.deleteConfirm.countCheck')}
        onConfirm={deleteDeviceCallback}
        onCancel={closeDeleteConfirmationCallback}
      />
     <Footer>
        {props.location ? <CountFooter /> : <WorkgroupFilterFooter />}
      </Footer>
    </div>
  );
};
