import { createSelector } from 'reselect';

import { areaName, StreamState } from './types';

const selectStreamState = (state: any): StreamState => state[areaName] as StreamState;

export const selectStreams = createSelector([selectStreamState], (state: StreamState) => state.streams);

export const selectIsFetchingStreams = createSelector([selectStreamState], (state: StreamState) => state.isFetching);

export const selectStream = createSelector([selectStreamState], (state: StreamState) => state.details.model);

export const selectIsFetchingStream = createSelector([selectStreamState], (state: StreamState) => state.details.isFetching);

export const selectIsFetchingFirstChannel = createSelector([selectStreamState], (state: StreamState) => state.details.isFetchingFirstChannel);

export const selectChannel = createSelector([selectStreamState], (state: StreamState) => state.details.selectedChannel);

export const selectIsSavingStream = createSelector([selectStreamState], (state: StreamState) => state.details.isSaving);

export const selectPreselected = createSelector([selectStreamState], (state: StreamState) => state.preselected);

export const selectSaveErrorStatusCode = createSelector([selectStreamState], (state: StreamState) => state.details.errorStatusCode);