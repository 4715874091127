import i18n from 'localization/i18n';
import {
  DeviceSettingModel, DeviceSettingSaveModel, EntityType, ThunkTypeAction, ThunkTypeDispatch
} from 'models';
import { pushNotification } from 'react-tools';

import * as actions from './actions';
import { DeviceSettingsService } from './service';
import { Entity } from './types';

const service = new DeviceSettingsService();

export const fetchDeviceSettingsAsync = (
  entity: Entity,
  parentEntity: Entity | null
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchDeviceSettings(entity, parentEntity));

    try {
      const settings = await service.fetchDeviceSettings(entity, parentEntity);
      dispatch(
        actions.fetchDeviceSettingsSuccess(
          settings.entity,
          settings.parent,
          entity,
          parentEntity
        )
      );
    } catch (err) {
      dispatch(actions.fetchDeviceSettingsFail(err));
    }
  };
};

export const saveDeviceSettingsAsync = (
  entity: Entity,
  parentEntity: Entity | null,
  settings: DeviceSettingSaveModel[]
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.saveDeviceSettings(entity, settings));

    try {
      const response = await service.saveDeviceSettings(entity, settings);
      dispatch(
        pushNotification(i18n.t('deviceSettings.saveSuccessToast'), 'success')
      );
      dispatch(actions.saveDeviceSettingsSuccess(response));
    } catch (e) {
      dispatch(pushNotification(e.message, 'error'));
      dispatch(actions.saveDeviceSettingsFail(e.message));
    }
  };
};
