import { Entity } from 'device-settings/duck/types';
import { WizardBladeName, WizardContainerProps } from 'device-settings/wizard/wizard.container';
import i18n from 'localization/i18n';
import { EntityType } from 'models';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeConfig, BladeProps, DataGridContextProvider, IdName, openBlade, withRegisterBlade
} from 'react-tools';

import { selectors, thunks, actions } from '../duck';
import { DeviceSettings } from './device-settings';

export const DeviceSettingsBladeName = 'DEVICE_SETTINGS';

export interface DeviceSettingsContainerProps {
  workgroup?: IdName;
  location?: IdName;
  device?: IdName;
  parentEntity: Entity | null;
}

const bladeConfig: BladeConfig<DeviceSettingsContainerProps> = {
  bladeType: DeviceSettingsBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  title: () => i18n.t('deviceSettings.title'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroup,
  location,
  device,
  bladeId,
  parentEntity,
}: DeviceSettingsContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const deviceSettings = useSelector(selectors.selectEntitySettings);
  const isFetching = useSelector(selectors.selectIsFetchingDeviceSettings);

  const fetchDevicesSettings = useCallback(
    (entity: Entity,
      parentEntity: Entity | null) =>
      dispatch(thunks.fetchDeviceSettingsAsync(entity, parentEntity)),
    [dispatch]
  );

  const openWizard = useCallback(
    () =>
      dispatch(openBlade<WizardContainerProps>(bladeId, WizardBladeName, { parentEntity })),
    []
  );

  const clearDeviceSettings = useCallback(() => dispatch(actions.clearDeviceSettings()), [])

  return (
    <DataGridContextProvider>
      <DeviceSettings
        clearDeviceSettings={clearDeviceSettings}
        workgroup={workgroup}
        location={location}
        device={device}
        bladeId={bladeId}
        fetchDevicesSettings={fetchDevicesSettings}
        openWizard={openWizard}
        deviceSettings={deviceSettings}
        isFetching={isFetching}
        parentEntity={parentEntity}
      />
    </DataGridContextProvider>
  );
};

export const DeviceSettingsContainer = withRegisterBlade<
  DeviceSettingsContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);
