import classNames from 'classnames';
import { CriticalSettingUnlockDialog } from 'device-settings/dialogs/critical-setting-unlock';
import { Entity } from 'device-settings/duck/types';
import { groupSettings, formValuesToNodeIds, safeSettingKey, overrideKeyPrefix } from 'device-settings/duck/utils';
import { Form, Formik, FormikHelpers, useField } from 'formik';
import { DeviceSettingModel } from 'models';
import React, { useCallback, useMemo, useState } from 'react';

import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { useStyles } from './editor.jss';
import { SettingValueEditor } from './settingEditor';
import { diff } from 'deep-diff';

export interface SettingsEditorProps {
  settings: DeviceSettingModel[];
  parentSettings: DeviceSettingModel[] | null;
  readOnly: boolean;
  groupSettings?: boolean;
  entity: Entity;
  parentEntity: Entity | null;
  onSubmit: (
    values: {
      [key: string]: any;
    },
    formikHelpers: FormikHelpers<{
      [key: string]: any;
    }>
  ) => void;
  classes?: Record<'form', string>;
  setDirty?: (dirty: boolean) => void;
  setDirtySettings?: (dirtySettings: string[]) => void;
}

const createFormValues = (settings: DeviceSettingModel[]) =>
  settings.reduce<{ [key: string]: any }>((o, s) => {
    o[safeSettingKey(s.key)] = s.value;
    return o;
  }, {});

export const SettingsEditor = React.forwardRef<any, SettingsEditorProps>(
  (props, ref) => {
    const classes = useStyles();
    const { readOnly, onSubmit } = props;
    const [settings, setSettings] = useState(props.settings);
    const [critDialogState, setCritDialogState] = useState({
      open: false,
      setting: '',
      callback: () => {},
    });
    const openUnlockDialog = useCallback(
      (setting: string, saveCallback: () => void) =>
        setCritDialogState({ open: true, setting, callback: saveCallback }),
      [setCritDialogState]
    );
   
    const formValues = createFormValues(settings);

    const onSettingRemove = useCallback(
      (settingId: number) => {
        const settingIndex = settings.findIndex((s) => s.id === settingId);
        const newSettings = [...settings];
        newSettings.splice(settingIndex, 1);
        setSettings(newSettings);
      },
      [settings, setSettings]
    );

    const editors = useMemo(() => {
      const groups = groupSettings(settings);
      
      const nodeIds = formValuesToNodeIds(formValues);

      const settingToEditor = (s: DeviceSettingModel) => (
        <SettingValueEditor
          key={safeSettingKey(s.key)}
          settingLabel={s.path[s.path.length - 1]}
          readOnly={readOnly}
          riskLevel={s.riskLevel}
          nodeIds={nodeIds}
          propKey={safeSettingKey(s.key)}
          parentValue={
            props.parentSettings
              ? props.parentSettings.find((ps) => ps.id === s.id)?.value
              : undefined
          }
          schema={s.schema}
          value={s.value}
          settingId={s.id}
          override={
            props.parentSettings !== null &&
            s.entityId === props.entity.entityId &&
            s.entityType == props.entity.entityType
          }
          openUnlockDialog={openUnlockDialog}
          removeSelf={onSettingRemove}
        />
      );
      const sortedGroups = Array.from(groups.keys()).sort();

      if (props.groupSettings) {
        return sortedGroups.map((groupName: string) => (
          <Card
            className={classes.settingGroupCard}
            classes={{ root: classes.settingGroupCardRoot }}
            key={groupName}
          >
            <CardHeader title={groupName} />
            <CardContent>
              {(groups.get(groupName) as DeviceSettingModel[])
                .sort((a, b) => {
                  if (a.key < b.key) {
                    return -1;
                  }
                  if (a.key > b.key) {
                    return 1;
                  }
                  return 0;
                })
                .map(settingToEditor)}
            </CardContent>
          </Card>
        ));
      } else {
        return sortedGroups.map((groupName) => {
          const group = groups.get(groupName);
          if (group) {
            return group.map(settingToEditor);
          }
        });
      }
    }, [settings]);

    const onFormChanged = useCallback(
      (initialValues: any, values: any) => () => {
        if (props.setDirty === undefined) {
          return;
        }

        const rawDiffs = diff(initialValues, values);

        if (rawDiffs === undefined) {
          props.setDirty(false);
        } else {
          const uniqueArr = (arr: string[]) => Array.from(new Set(arr));
          const diffs = rawDiffs.map((d: any) => d.path[0]);
          const revertObj = values[overrideKeyPrefix]
          const reverts = revertObj
            ? Object.keys(revertObj).filter((k) => revertObj[k] === true)
            : [];
          const dirtySettings = uniqueArr(
            diffs.concat(reverts).filter((d) => d !== overrideKeyPrefix)
          );

          if (dirtySettings.length === 0) {
            props.setDirty(false);
          } else {
            props.setDirty(true);
            if (props.setDirtySettings) {
              props.setDirtySettings(dirtySettings);
            }
          }
        }
      },
      [props.setDirty]
    );

    return (
      <Formik
        initialValues={formValues}
        validateOnBlur
        onSubmit={onSubmit}
        innerRef={ref as any}
      >
        {({ initialValues, values }) => (
          <Form
            onBlur={onFormChanged(initialValues, values)}
            className={classNames(classes.form, props.classes?.form)}
          >
            {editors}
            <CriticalSettingUnlockDialog
              open={critDialogState.open}
              onClose={() =>
                setCritDialogState({
                  open: false,
                  setting: '',
                  callback: () => {},
                })
              }
              save={() => {
                critDialogState.callback();
                setCritDialogState({
                  open: false,
                  setting: '',
                  callback: () => {},
                });
              }}
            />
          </Form>
        )}
      </Formik>
    );
  }
);
