import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem } from 'react-tools';

import Report from '@material-ui/icons/Report';

export const useDeviceSettingsBladeButtons = (
  criticalVisible: boolean,
  hideShowCriticalSettingsButton: boolean,
  showCritical: () => void
) => {
  const [] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => hideShowCriticalSettingsButton ? [] : [
      {
        tooltip: 'deviceSettings.showCritical',
        icon: () => <Report color={criticalVisible ? 'error' : 'inherit'} />,
        onClick: showCritical,
        disabled: false,
      },
    ],
    [showCritical, criticalVisible, hideShowCriticalSettingsButton]
  );

  return buttons;
};
