import classNames from 'classnames';
import React from 'react';

import { Divider, Tooltip, Typography } from '@material-ui/core';
import HelpRounded from '@material-ui/icons/HelpRounded';

import { useStyles } from './editor.jss';

export interface TreeItemContentProps {
  label: string;
  tooltip?: string;
  classes?: Record<'label', string>;
}

export const TreeItemLabel: React.FunctionComponent<TreeItemContentProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        className={classNames(classes.treeItemLabel, props.classes?.label)}
      >{`${props.label}:`}</Typography>
      {props.children}
      <Tooltip title={props.tooltip ? props.tooltip : props.label}>
        <HelpRounded
          name="helpIcon"
          className={classNames([
            classes.treeItemLabelTooltipIcon,
            classes.onHoverOnly,
          ])}
        />
      </Tooltip>
    </>
  );
};
