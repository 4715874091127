import { NavigationList, NavigationListItemModel } from 'components';
import React, { useMemo } from 'react';

import DeviceIcon from '@material-ui/icons/Router';
import DeviceSettingsIcon from '@material-ui/icons/Settings';

interface LocationFormProps {
  openLocationDevices: () => void;
  openLocationDeviceSettings: () => void;
}

export const LocationFormActions = React.memo((
  props: LocationFormProps
) => {
  const items = useMemo<NavigationListItemModel[]>(() => [
    { icon: <DeviceIcon/>, labelKey: "workgroupDetails.nav.devices", onClick: props.openLocationDevices },
    { icon: <DeviceSettingsIcon/>, labelKey: "workgroupDetails.nav.deviceSettings", onClick: props.openLocationDeviceSettings },
  ], [props.openLocationDevices, props.openLocationDeviceSettings]);

  return <NavigationList items={items} />;
});
