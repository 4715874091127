import i18next from 'i18next';
import { StreamAudioOutput } from 'models/streams';
import { ColumnSettings } from 'react-tools';

import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';

const audioOutputFormatter = (params: ValueFormatterParams) => {
  switch (+params.value) {
    case StreamAudioOutput.All:
      return i18next.t<string>('streams.audioOutputType.all');
    case StreamAudioOutput.Left:
      return i18next.t<string>('streams.audioOutputType.left');
    case StreamAudioOutput.Right:
      return i18next.t<string>('streams.audioOutputType.right');
    default:
      return i18next.t<string>('streams.audioOutputType.unsupported');
  }
};

export const streamsColumnSettings = (
  showDevices: boolean
): Array<ColumnSettings> => {
  const columns: Array<ColumnSettings> = [
    {
      name: 'id',
      width: 140,
      sort: { order: 0, direction: 'asc' },
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      name: 'name',
      width: 170,
      filter: 'agTextColumnFilter',
      lockVisibility: true,
    },
    {
      name: 'audioOutputType',
      width: 180,
      valueFormatter: audioOutputFormatter,
      customFloatingFilterParams: {
        valueFormatter: audioOutputFormatter,
      },
      filter: 'agSetColumnFilter',
    },
  ];
  if (showDevices) {
    columns.push({
      name: 'hardwareName',
      width: 140,
      filter: 'agTextColumnFilter',
    });
  } else {
    columns.push({
      name: 'channelName',
      width: 140,
      filter: 'agTextColumnFilter',
    });
  }
  return columns;
};
