import React from 'react';
import ContentLoader from 'react-content-loader';

export const WorkgroupDetailsContentLoader = () => (
  <ContentLoader speed={2}
    width={530}
    height={460}
    backgroundColor="#CCC"
    foregroundColor="#BBB">
    <rect x="16" y="38" rx="3" ry="3" width="568" height="12" />

    <rect x="16" y="98" rx="3" ry="3" width="48" height="48" />

    <rect x="94" y="98" rx="3" ry="3" width="100" height="12" />
    <rect x="94" y="121" rx="3" ry="3" width="140" height="10" />
    <rect x="94" y="140" rx="3" ry="3" width="110" height="10" />

    <rect x='540' y='98' width="20" height="20" rx="10" ry="10" />

    <rect x="16" y="174" rx="3" ry="3" width="568" height="1" />

    <rect x="16" y="196" rx="3" ry="3" width="180" height="12" />

    <rect x="16" y="224" rx="3" ry="3" width="120" height="8" />

    <rect x="514" y="238" rx="3" ry="3" width="54" height="27" />

    {/* Expansion Box */}
    <rect x="16" y="285" rx="3" ry="3" width="568" height="1" />
    <rect x="16" y="285" rx="3" ry="3" width="1" height="68" />
    <rect x="583" y="285" rx="3" ry="3" width="1" height="69" />

    <rect x="16" y="352" rx="3" ry="3" width="568" height="1" />

    <rect x="32" y="312" rx="3" ry="3" width="18" height="18" />

    <rect x="64" y="315" rx="3" ry="3" width="120" height="12" />

  </ContentLoader>
)