import {
    bladeAreaName, bladeReducer, BladeState, notificationsAreaName, notificationsReducer,
    NotificationsState, treeAreaName, TreeReducer, TreeReduxState
} from 'react-tools';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { channelReducer, types as ChannelStore } from './channel/duck';
import { deviceSettingsReducer, types as DeviceSettingsStore } from './device-settings/duck';
import { deviceReducer, types as DeviceStore } from './device/duck';
import { locationReducer, types as LocationStore } from './location/duck';
import { streamReducer, types as StreamStore } from './stream/duck';
import { types as WorkgroupStore, workgroupReducer } from './workgroup/duck';

export interface AppState {
  [bladeAreaName]: BladeState;
  [notificationsAreaName]: NotificationsState;
  [treeAreaName]: TreeReduxState;
  [WorkgroupStore.areaName]: WorkgroupStore.WorkgroupState;
  [LocationStore.areaName]: LocationStore.LocationState;
  [DeviceStore.areaName]: DeviceStore.DeviceState;
  [ChannelStore.areaName]: ChannelStore.ChannelState;
}

export const createDefaultStore = () => {
  const store = createStore(
    combineReducers({
      [bladeAreaName]: bladeReducer,
      [notificationsAreaName]: notificationsReducer,
      [WorkgroupStore.areaName]: workgroupReducer,
      [LocationStore.areaName]: locationReducer,
      [DeviceStore.areaName]: deviceReducer,
      [ChannelStore.areaName]: channelReducer,
      [DeviceSettingsStore.areaName]: deviceSettingsReducer,
      [StreamStore.areaName]: streamReducer,
      [treeAreaName]: TreeReducer,
    }),
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
  return store;
};
