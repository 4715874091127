import { WorkgroupFilterFooter } from 'components/ExternalFilterFooter';
import { ChannelListItem, ChannelType } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    DataGrid, DataGridHeader, DataGridSelectionType, Footer, GridCard, IdName, useBladeButtons,
    useBladeClosing, useGridApi
} from 'react-tools';

import { useChannelListBladeButtons } from './buttons.hook';
import { useStyles } from './channels.jss';
import { channelsColumnSettings } from './column.settings';
import { ChannelListLoader } from './loader';

export interface ChannelsProps {
  items: Array<ChannelListItem>;
  workgroup: IdName;
  preselected: ChannelListItem[];
  fetchingChannels: boolean;
  bladeId: string;
}

export interface ChannelsActions {
  onClose: () => void;
  fetchChannels: (workgroupId: number) => void;
  createChannel: (channelType: ChannelType) => void;
  editChannel: (workgroup: ChannelListItem) => void;
  deleteChannels: (workgroups: ChannelListItem[]) => void;
  clearPreselected: () => void;
}

type Props = ChannelsProps & ChannelsActions;

export const Channels: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [columns] = useState(channelsColumnSettings);
  const gridApi = useGridApi();


  useBladeClosing(
    props.bladeId,
    () => true,
    () => {
      props.onClose();
    }
  );
  const [selected, setSelected] = useState<ChannelListItem[]>([]);

  useEffect(() => {
    if (props.workgroup.id) {
      props.fetchChannels(props.workgroup.id);
    }
  }, [props.workgroup.id]);

  useEffect(() => {
    setSelected(props.preselected);
  }, [props.preselected]);

  const fetchChannelsCallback = useCallback(() => {
    props.fetchChannels(props.workgroup.id);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchChannels, props.workgroup.id]);

  const recurseFilterFn = useCallback(
    (row: any) => {
      return row.data.workgroupId === props.workgroup.id;
    },
    [props.workgroup.id]
  );

  const buttons = useChannelListBladeButtons(
    fetchChannelsCallback,
    props.createChannel,
    props.editChannel,
    selected,
    props.fetchingChannels,
    gridApi
  );

  useBladeButtons(buttons, [buttons]);

  const onChannelsSelected = useCallback(
    (items: IdName[]) => setSelected(items as ChannelListItem[]),
    [props.editChannel]
  );

  const MemoizedGrid = useMemo(() => {
    return (
      <DataGrid
        onSelect={onChannelsSelected}
        identifier={'channels'}
        noDataMessage={'channels.noResults'}
        selectionType={DataGridSelectionType.Multiple}
        defaultSelection={props.preselected}
        items={props.items}
        columnSettings={columns}
        externalFilterFn={recurseFilterFn}
        isExternalFilterEnabled={() => true}
      />
    );
  }, [props.items, props.preselected, recurseFilterFn]);

  return props.fetchingChannels ? (
    <ChannelListLoader />
  ) : (
    <div className={classes.container}>
      <GridCard>
        <DataGridHeader loading={props.fetchingChannels} />
        {MemoizedGrid}
      </GridCard>

      <Footer>
        <WorkgroupFilterFooter />
      </Footer>
    </div>
  );
};
