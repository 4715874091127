import { JSONObjectSchema } from 'device-settings/duck/json-schema-types';
import React from 'react';

import { SchemaEditorProps } from '../types';
import { ObjectDynamicEditor } from './dynamicEditor';
import { ObjectNoPropsError } from './objectNoPropsError';
import { ObjectStaticEditor } from './staticEditor';

export const ObjectEditor: React.FC<SchemaEditorProps<JSONObjectSchema>> = React.memo((props) => {
  const { propKey, schema } = props;
  const schemaProps = schema.properties;
  const schemaPatternProps = schema.patternProperties;
  if (schemaProps) {
    return <ObjectStaticEditor {...props} />;
  } else if (schemaPatternProps) {
    return <ObjectDynamicEditor {...props} />;
  }
  return <ObjectNoPropsError propKey={propKey} />;
});
