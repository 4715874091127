import i18n from 'localization/i18n';
import { StreamFormModel } from 'models/streams';
import { number, object, string } from 'yup';

export const StreamValidationScheme = object().shape<StreamFormModel>({
  id: number(),
  name: string().required(i18n.t('validation.required')).max(255),
  channelId: number(),
  channelName: string(),
  hardwareId: number(),
  hardwareName: string(),
  x: number().moreThan(-1).required(),
  y: number().moreThan(-1).required(),
  width:  number().moreThan(-1).lessThan(101, i18n.t('')).required(),
  height: number().moreThan(-1).lessThan(101, i18n.t('')).required(),
  audioOutputType: number(),
  rowVersion: string(),
});
