import { FastField, FieldProps, Form, FormikErrors } from 'formik';
import { TemplateLibrary, WorkgroupDetailsModel } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCard, TextFieldInnerWrapper } from 'react-tools';
import { WorkgroupDetailsTabs } from 'workgroup/duck/types';

import {
    Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, Divider, FormControlLabel, Grid,
    Tab, Tabs, TextField, TextFieldProps
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';

import { countries } from '../../utils/countries';
import { getEmptyCompany } from './workgroup-details';
import { WorkgroupDetailsLogo } from './workgroup-details-logo';
import { WorkgroupDetailsTemplates } from './workgroup-details-templates';
import { useStyles } from './workgroup-details.jss';

export interface WorkgroupFieldsProps {
  workgroup: WorkgroupDetailsModel;
  errors: FormikErrors<WorkgroupDetailsModel>;
  setWorkgroup: (workgroup: WorkgroupDetailsModel) => void;
  onWorkgroupChange: (e: React.ChangeEvent<any>) => void;

  workgroupTemplates: TemplateLibrary[];
  openTemplateLibrarySelection: () => void;

  dirty: boolean;
  setDirty: (dirty: boolean) => void;
}

const TextFastFieldWrapper = (props: TextFieldProps) => (
  <FastField name={props.name}>{() => <TextFieldInnerWrapper {...props} />}</FastField>
);

export const WorkgroupField = (props: TextFieldProps) => (
  <TextFastFieldWrapper
    {...props}
    autoComplete="off"
    variant="filled"
    fullWidth
    InputProps={{
      ...props.InputProps,
      disableUnderline: true,
      autoComplete: 'off',
    }}
  />
);

export const WorkgroupDetailsFields: React.FunctionComponent<WorkgroupFieldsProps> = (
  props
) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [selectedTab, setSelectedTab] = useState(WorkgroupDetailsTabs.COMPANY);
  const [isCompanyExpanded, setIsCompanyExpanded] = useState(
    props.workgroup.isCompany
  );
  const onSelectedTabChange = useCallback(
    (_, value: WorkgroupDetailsTabs) => setSelectedTab(value),
    []
  );

  useEffect(() => {
    setIsCompanyExpanded(props.workgroup.isCompany);
  }, [props.workgroup.id]);

  const onIsCompanyChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      props.setWorkgroup({
        ...props.workgroup,
        companyName: props.workgroup.name,
        isCompany: checked,
      });
    } else {
      props.setWorkgroup({
        ...props.workgroup,
        isCompany: checked,
        ...getEmptyCompany(),
      });
      setSelectedTab(WorkgroupDetailsTabs.COMPANY);
    }
    setIsCompanyExpanded(checked);
  };

  const onIsCompanyExpandedChange = (
    _: React.ChangeEvent<{}>,
    expanded: boolean
  ) => {
    if (props.workgroup.isCompany) {
      setIsCompanyExpanded(expanded);
    }
  };

  const onBlur = () => props.setDirty(props.dirty);

  return (
    <Form onBlur={onBlur} autoComplete="off">
      <FormCard marginBottom>
        {props.workgroup.id !== 0 && (
          <WorkgroupField
            label={t('workgroupDetails.fields.id')}
            classes={{ root: classes.workgroupNameInput }}
            disabled
            InputProps={{
              disableUnderline: true,
            }}
            value={props.workgroup.id}
          />
        )}
        <WorkgroupField
          label={t('workgroupDetails.fields.name')}
          classes={{ root: classes.workgroupNameInput }}
          name="name"
          value={props.workgroup.name}
          error={!!props.errors.name}
          helperText={props.errors.name}
          onChange={props.onWorkgroupChange}
          inputProps={{ maxLength: 150 }}
        />

        <WorkgroupDetailsLogo />

        <Divider />

        <WorkgroupDetailsTemplates
          templates={props.workgroupTemplates}
          openTemplateLibrarySelection={props.openTemplateLibrarySelection}
        />
      </FormCard>
      <Accordion
        className={classes.companyExpander}
        expanded={isCompanyExpanded}
        onChange={onIsCompanyExpandedChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.companySummaryExpanded }}
        >
          <FormControlLabel
            label={t('workgroupDetails.isCompany')}
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            control={
              <Checkbox
                name={'isCompany'}
                onChange={onIsCompanyChange}
                checked={props.workgroup.isCompany}
              />
            }
          />
        </AccordionSummary>
        <AccordionDetails className={classes.companyDetails}>
          <Tabs
            scrollButtons="auto"
            value={selectedTab}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            onChange={onSelectedTabChange}
            className={classes.tabPanel}
          >
            <Tab
              label={t('workgroupDetails.tab.company')}
              value={WorkgroupDetailsTabs.COMPANY}
            />
            <Tab
              label={t('workgroupDetails.tab.address')}
              value={WorkgroupDetailsTabs.ADDRESS}
            />
            <Tab
              label={t('workgroupDetails.tab.contact')}
              value={WorkgroupDetailsTabs.CONTACT}
            />
          </Tabs>
          <Divider className={classes.tabPanel} />
          <Grid container spacing={2} classes={{ root: classes.tabContent }}>
            {selectedTab === WorkgroupDetailsTabs.COMPANY && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyName')}
                  name="companyName"
                  value={props.workgroup.companyName}
                  error={!!props.errors.companyName}
                  helperText={props.errors.companyName}
                  disabled={true}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.COMPANY && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyNumber')}
                  name="companyNumber"
                  value={props.workgroup.companyNumber}
                  error={!!props.errors.companyNumber}
                  helperText={props.errors.companyNumber}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                />
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.ADDRESS && (
              <Grid item>
                <FastField
                  name="companyCountry"
                  render={(fieldProps: FieldProps<WorkgroupDetailsModel>) => (
                    <Autocomplete
                      classes={{ popper: classes.countriesOptions }}
                      options={countries.map((i) => i.name)}
                      value={props.workgroup.companyCountry}
                      onChange={(e: any, v: any) => {
                        fieldProps.form.setFieldValue('companyCountry', v);
                      }}
                      renderInput={(p: any) => (
                        <TextField
                          {...p}
                          label={t('workgroupDetails.fields.companyCountry')}
                          variant="filled"
                          inputProps={{
                            ...p.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  )}
                ></FastField>
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.ADDRESS && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyAddress')}
                  name="companyAddress"
                  value={props.workgroup.companyAddress}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 150, autoComplete: 'new-password' }}
                />
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.ADDRESS && (
              <Grid item>
                <div className={classes.inlineFields}>
                  <WorkgroupField
                    label={t('workgroupDetails.fields.companyCity')}
                    name="companyCity"
                    value={props.workgroup.companyCity}
                    onChange={props.onWorkgroupChange}
                    className={classes.inlineField}
                    inputProps={{
                      maxLength: 150,
                      autoComplete: 'new-password',
                    }}
                  />

                  <WorkgroupField
                    label={t('workgroupDetails.fields.companyState')}
                    name="companyState"
                    value={props.workgroup.companyState}
                    onChange={props.onWorkgroupChange}
                    className={classes.inlineField}
                    inputProps={{
                      maxLength: 150,
                      autoComplete: 'new-password',
                    }}
                  />
                </div>
              </Grid>
            )}

            {selectedTab === WorkgroupDetailsTabs.ADDRESS && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyZip')}
                  name="companyZip"
                  value={props.workgroup.companyZip}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                />
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.CONTACT && (
              <Grid item>
                <div className={classes.inlineFields}>
                  <WorkgroupField
                    label={t('workgroupDetails.fields.companyFirstName')}
                    name="companyFirstName"
                    value={props.workgroup.companyFirstName}
                    onChange={props.onWorkgroupChange}
                    className={classes.inlineFieldEqual}
                    inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                  />
                  <WorkgroupField
                    label={t('workgroupDetails.fields.companyLastName')}
                    name="companyLastName"
                    value={props.workgroup.companyLastName}
                    onChange={props.onWorkgroupChange}
                    className={classes.inlineFieldEqual}
                    inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                  />
                </div>
              </Grid>
            )}

            {selectedTab === WorkgroupDetailsTabs.CONTACT && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyPhone')}
                  name="companyPhone"
                  value={props.workgroup.companyPhone}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                />
              </Grid>
            )}
            {selectedTab === WorkgroupDetailsTabs.CONTACT && (
              <Grid item>
                <WorkgroupField
                  label={t('workgroupDetails.fields.companyEmail')}
                  name="companyEmail"
                  value={props.workgroup.companyEmail}
                  onChange={props.onWorkgroupChange}
                  inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Form>
  );
};
