import { DeviceSettingModel } from 'models';

import { JSONSchema } from './json-schema-types';

export const createObject = (schema: JSONSchema): any => {
  switch (schema.type) {
    case 'string':
      if (schema.enum && schema.enum.length > 0) {
        return schema.enum[0];
      }
      return '';
    case 'number':
    case 'integer':
      if (schema.enum && schema.enum.length > 0) {
        return schema.enum[0];
      }
      if (schema.minimum) {
        return schema.minimum;
      }
      if (schema.exclusiveMinimum) {
        return schema.exclusiveMinimum + 1;
      }
      return 0;
    case 'array':
      return [];
    case 'boolean':
      return false;
    case 'object': {
      if (schema.properties) {
        const sprops = schema.properties;
        return Object.keys(sprops).reduce<{ [key: string]: any }>((o, k) => {
          o[k] = createObject(sprops[k as string]);
          return o;
        }, {});
      }
      if (schema.patternProperties) return {};
    }
    case 'null':
      return null;
    case undefined: {
      console.warn(`Schema has no schemas defined in anyOf`);
      if (schema.anyOf.length === 0) {
        return undefined;
      }
      const nullSchema = schema.anyOf.find((s) => s.type === 'null');
      if (nullSchema) {
        return null;
      } else {
        return createObject(schema.anyOf[0]);
      }
    }
  }
};

export const safeSettingKey = (settingKey: string): string =>
  settingKey.replace(/\./g, '--');

export const formValuesToNodeIds = (formValues: { [key: string]: any }) => {

  const isObject = (val: any) => typeof val === 'object' && !Array.isArray(val);
  const objDelimiter = (a: string, b: string) => a ? `${a}.${b}` : b;
  const arrayDelimiter = (a: string, b: string) => a ? `${a}[${b}]` : b;

  const paths = (obj: any = {}, head: string = '', delimiterFunc: ((a: string, b: string) => string) | null = null): string[] => {

    if (obj === null || obj === undefined) {
      return [head];
    }

    return Object.entries(obj)
        .reduce((product: string[], [key, value]: [string, any]) => 
            {
                const currentDelimiterFunc = delimiterFunc ?? objDelimiter;
                const fullPath = currentDelimiterFunc(head, key);
                
                if (isObject(value)) {
                  product.push(fullPath);
                  return product.concat(paths(value, fullPath));
                } else if (Array.isArray(value)) {
                  product.push(fullPath);
                  return product.concat(paths(value, fullPath, arrayDelimiter));
                } else {
                  return product.concat(fullPath);
                }

            }, []);
  }

  return paths(formValues);
}

export const overrideKeyPrefix = '$revert-overrides';
export const overrideKey = (settingKey: string): string =>
  `${overrideKeyPrefix}.${settingKey}`;

export const flattenSettingPath = (setting: DeviceSettingModel) =>
  setting.path.reduce((p, item, index) => {
    if (index === setting.path.length - 1) return p;
    return p + item + (index !== setting.path.length - 2 ? ' / ' : '');
  }, '');

export const groupSettings = (settings: DeviceSettingModel[]) =>
  settings.reduce<Map<string, DeviceSettingModel[]>>((groups, setting) => {
    const flattenedPath = flattenSettingPath(setting);

    if (!groups.has(flattenedPath)) {
      groups.set(flattenedPath, []);
    }

    const group = groups.get(flattenedPath);
    if (group) {
      group.push(setting);
    }

    return groups;
  }, new Map<string, DeviceSettingModel[]>());
