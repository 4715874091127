import { DeviceSettingModel, DeviceSettingSaveModel, EntityType } from 'models';
import { action } from 'typesafe-actions';

import { ActionTypes, Entity } from './types';

export const fetchDeviceSettings = (
  entity: Entity,
  parentEntity: Entity | null
) => action(ActionTypes.FETCH_DEVICE_SETTINGS, { entity, parentEntity });

export const fetchDeviceSettingsFail = (error: any) =>
  action(ActionTypes.FETCH_DEVICE_SETTINGS_FAIL, { error });

export const fetchDeviceSettingsSuccess = (
  entitySettings: DeviceSettingModel[],
  parentSettings: DeviceSettingModel[] | null,
  entity: Entity,
  parentEntity: Entity | null
) =>
  action(ActionTypes.FETCH_DEVICE_SETTINGS_SUCCESS, {
    entitySettings,
    parentSettings,
    entity,
    parentEntity,
  });

export const saveDeviceSettings = (entity: Entity, settings: any[]) =>
  action(ActionTypes.SAVE_DEVICE_SETTINGS, { entity, settings });

export const saveDeviceSettingsSuccess = (settings: DeviceSettingModel[]) =>
  action(ActionTypes.SAVE_DEVICE_SETTINGS_SUCCESS, { settings });

export const saveDeviceSettingsFail = (error: any) =>
  action(ActionTypes.SAVE_DEVICE_SETTINGS_FAIL);

export const clearDeviceSettings = () => action(ActionTypes.CLEAR_DEVICE_SETTINGS);

export const clearSavedFlag = () => action(ActionTypes.CLEAR_SAVED_FLAG);