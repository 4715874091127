import i18n from 'localization/i18n';
import {
    DeviceSettingGroupTree, DeviceSettingModel, DeviceSettingSaveModel, EntityType,
    FetchDeviceSettingsResponse
} from 'models';
import HttpService from 'utils/http';

import { Entity } from './types';

export interface IDeviceSettingsService {
  fetchDeviceSettings: (
    entity: Entity,
    parentEntity: Entity | null
  ) => Promise<{
    entity: DeviceSettingModel[];
    parent: DeviceSettingModel[] | null;
  }>;
  fetchAffectedDevices: (
    entity: Entity,
    settingIds: number[]
  ) => Promise<number>;
}

export class DeviceSettingsService implements IDeviceSettingsService {
  private readonly HIERARCHY_FORMAT_ID = 2;

  async fetchDeviceSettings(
    entity: Entity,
    parentEntity: Entity | null
  ): Promise<{
    entity: DeviceSettingModel[];
    parent: DeviceSettingModel[] | null;
  }> {
    let url = `v6/deviceSettings?entityType=${entity.entityType}&entityId=${entity.entityId}&format=${this.HIERARCHY_FORMAT_ID}`;
    if (parentEntity) {
      url = `${url}&parentEntityId=${parentEntity.entityId}&parentEntityType=${parentEntity.entityType}`;
    }
    const response = await HttpService.get<FetchDeviceSettingsResponse>(url);

    await this.fetchUsersForDeviceSettingsResponse(response);

    return this.mapSettingJsonString(response, entity);
  }

  async fetchUsersForDeviceSettingsResponse(
    response: FetchDeviceSettingsResponse
  ) {
    try {
      let allUserIds: number[] = [];

      if (response.settings.entity) {
        allUserIds = allUserIds.concat(
          response.settings.entity.map((e) => e.userId)
        );
      }

      if (response.settings.parent) {
        allUserIds = allUserIds.concat(
          response.settings.parent.map((e) => e.userId)
        );
      }

      allUserIds = Array.from(new Set(allUserIds));

      const users = await HttpService.post<any[]>(
        'v6/admin/users/byIds',
        allUserIds
      );

      const normalizedUsers: any = {};
      users.map((u: any) => {
        normalizedUsers[u.id] = u;
      });

      const addUsername = (deviceSetting: DeviceSettingModel[]) => {
        deviceSetting.forEach((s) => {
          if (normalizedUsers[s.userId] && s.userId !== 0) {
            s.username = normalizedUsers[s.userId].login;
          } else {
            s.username = '';
          }
        });
      };

      addUsername(response.settings.entity);
      if (response.settings.parent) {
        addUsername(response.settings.parent);
      }
    } catch (err) {
      console.error('Failed to populate users for settings');
      console.error(err);
    }
  }

  async fetchAffectedDevices(
    entity: Entity,
    settingIds: number[]
  ): Promise<number> {
    const response = await HttpService.post<number>(
      `v6/deviceSettings/affected`,
      {       
          entity: entity,
          settingIds: settingIds        
      }
    );
    return response;
  }

  async saveDeviceSettings(
    entity: Entity,
    settings: DeviceSettingSaveModel[]
  ): Promise<DeviceSettingModel[]> {
    const settingsToSave = settings.map<DeviceSettingSaveModel>((s) => {
      const value = s.revertValue ? null : s.value;
      return { ...s, value };
    });

    const response = await HttpService.put<FetchDeviceSettingsResponse>(
      'v6/deviceSettings',
      {
        changes: {
          entityId: entity.entityId,
          entityType: entity.entityType,
          settings: settingsToSave,
          changeId: new Date(),
        },
      }
    );
    await this.fetchUsersForDeviceSettingsResponse(response);
    return this.mapSettingJsonResponseString(entity, response);
  }

  private mapSettingJsonString(
    settingsResponse: FetchDeviceSettingsResponse,
    entityInfo: Entity
  ) {
    const { settings, tree } = settingsResponse;

    const entity = settings.entity.map<DeviceSettingModel>(
      (setting: DeviceSettingModel) => {
        let groupTree = tree.find((group) => group.settingKey === setting.key);
        let path: string[] = [];
        const isInherited =
          setting.entityId !== entityInfo.entityId ||
          setting.entityType !== entityInfo.entityType;
        if (groupTree) {
          path = groupTree.path.map((e) => i18n.t(e.name).toString());
        }
        path.push(i18n.t(setting.key).toString());
        return {
          ...setting,
          path,
          isInherited,
        };
      }
    );
    return { entity, parent: settings.parent };
  }
  private mapSettingJsonResponseString(
    originalEntity: Entity,
    settingsResponse: FetchDeviceSettingsResponse
  ) {
    const settings = settingsResponse.settings;

    const newSettings = settings.entity.map<DeviceSettingModel>(
      (setting: DeviceSettingModel) => {
        const isInherited =
          originalEntity.entityId !== setting.entityId ||
          originalEntity.entityType !== setting.entityType;

        return {
          ...setting,
          isInherited,
        };
      }
    );
    return newSettings;
  }
}
