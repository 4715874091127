import i18n from 'localization/i18n';
import { DeviceFormModel } from 'models/devices';
import { number, object, string } from 'yup';

export const getDeviceValidationSchema = (serialEditable: boolean) => object().shape<DeviceFormModel>({
  id: number().required(),
  name: string().required(i18n.t('validation.required')).max(255),
  locationId: number().required(i18n.t('validation.required')),
  workgroupId: number().required(),
  workgroupName: string().required(),
  rowVersion: string(),
  deviceTypeId: number().required(''),
  salesOrderNumber: string(),
  serialNumber: serialEditable ? string().max(255).required(i18n.t('validation.required')) : string(),
});
