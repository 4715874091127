import { FastField, FastFieldProps } from 'formik';
import { WorkgroupLocation } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormActionsCard } from 'react-tools';
import { countries, CountryInfo, timezones } from 'utils';

import {
    Accordion, AccordionDetails, AccordionSummary, Grid, TextField, TextFieldProps, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';

import { LocationFormActions } from './location-form.actions';
import { useStyles } from './location-form.jss';

interface Props {
  handleChange: (event: React.ChangeEvent) => void;
  openLocationDevices: () => void;
  openLocationDeviceSettings: () => void;
  values: WorkgroupLocation;
  showActions: boolean;
}

export const LocationFormFields = ({
  handleChange,
  values,
  openLocationDeviceSettings,
  openLocationDevices,
  showActions,
}: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.formContent}>
      <Accordion defaultExpanded classes={{ expanded: classes.expanded }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            {t('locationForm.locationInformation')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            classes={{ root: classes.panelContainer }}
          >
            {values.id !== 0 && (
              <Grid item>
                <TextField
                  label={t('workgroupLocations.id')}
                  variant="filled"
                  fullWidth
                  disabled
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={values.id}
                />
              </Grid>
            )}
            <Grid item>
              <FastField name="oracleNumber">
                {({ form }: FastFieldProps<WorkgroupLocation>) => (
                  <TextField
                    label={t('workgroupLocations.oracleNumber')}
                    variant="filled"
                    fullWidth
                    error={form.errors.oracleNumber ? true : false}
                    helperText={form.errors.oracleNumber}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 50 }}
                    name="oracleNumber"
                    value={values.oracleNumber}
                    onChange={handleChange}
                  />
                )}
              </FastField>
            </Grid>

            <Grid item>
              <FastField name="name">
                {({ form }: FastFieldProps<WorkgroupLocation>) => (
                  <TextField
                    label={t('workgroupLocations.name')}
                    variant="filled"
                    fullWidth
                    error={form.errors.name ? true : false}
                    helperText={form.errors.name}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 255 }}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                )}
              </FastField>
            </Grid>

            <Grid item>
              <FastField name="clientSiteId">
                {() => (
                  <TextField
                    label={t('workgroupLocations.clientSiteId')}
                    variant="filled"
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 64 }}
                    name="clientSiteId"
                    value={values.clientSiteId}
                    onChange={handleChange}
                  />
                )}
              </FastField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={values.id===0} classes={{ expanded: classes.expanded }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            {t('locationForm.locationAddress')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            classes={{ root: classes.panelContainer }}
          >
            <Grid item>
              <FastField name="timezone">
                {({ form }: FastFieldProps<WorkgroupLocation>) => (
                  <Autocomplete
                    options={timezones}
                    getOptionLabel={(option: { id: string; name: string }) =>
                      option.name
                    }
                    fullWidth
                    value={
                      timezones.find((e) => e.name === values.timezone) || null
                    }
                    onChange={(
                      e: any,
                      timezone: { id: string; name: string } | null
                    ) => {
                      if (timezone) {
                        form.setFieldValue('timezone', timezone.name);
                      } else {
                        form.setFieldValue('timezone', '');
                      }
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        name="timezone"
                        error={form.errors.timezone ? true : false}
                        helperText={form.errors.timezone}
                        label={t('workgroupLocations.timezone')}
                        variant="filled"
                        value={values.timezone}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                )}
              </FastField>
            </Grid>

            <Grid item>
              <FastField name="country">
                {({ form, field }: FastFieldProps<WorkgroupLocation>) => (
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option: CountryInfo) => option.name}
                    fullWidth
                    value={
                      countries.find((e) => e.name === values.country) || null
                    }
                    onChange={(e: any, country: CountryInfo | null) => {
                      if (country) {
                        form.setFieldValue('country', country.name);
                      } else {
                        form.setFieldValue('country', '');
                      }
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        name="country"
                        error={form.errors.country ? true : false}
                        helperText={form.errors.country}
                        label={t('workgroupLocations.country')}
                        variant="filled"
                        value={values.country}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                )}
              </FastField>
            </Grid>

            <Grid item>
              <FastField name="address">
                {() => (
                  <TextField
                    label={t('workgroupLocations.address')}
                    variant="filled"
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    name="address"
                    inputProps={{ maxLength: 255 }}
                    value={values.address}
                    onChange={handleChange}
                  />
                )}
              </FastField>
            </Grid>

            <Grid item>
              <div style={{ display: 'flex' }}>
                <FastField name="city">
                  {({ form }: FastFieldProps<WorkgroupLocation>) => (
                    <TextField
                      className={classes.inlineField}
                      label={t('workgroupLocations.city')}
                      variant="filled"
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ maxLength: 255 }}
                      name="city"
                      error={form.errors.city ? true : false}
                      helperText={form.errors.city}
                      value={values.city}
                      onChange={handleChange}
                    />
                  )}
                </FastField>

                <FastField name="state">
                  {({ form }: FastFieldProps<WorkgroupLocation>) => (
                    <TextField
                      className={classes.inlineField}
                      label={t('workgroupLocations.state')}
                      variant="filled"
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      error={form.errors.state ? true : false}
                      inputProps={{ maxLength: 255 }}
                      helperText={form.errors.state}
                    />
                  )}
                </FastField>
              </div>
            </Grid>

            <Grid item>
              <FastField name="zipCode">
                {({ form }: FastFieldProps<WorkgroupLocation>) => (
                  <TextField
                    label={t('workgroupLocations.zipCode')}
                    variant="filled"
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 50 }}
                    name="zipCode"
                    value={values.zipCode}
                    error={form.errors.zipCode ? true : false}
                    helperText={form.errors.zipCode}
                    onChange={handleChange}
                  />
                )}
              </FastField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {showActions && (
        <FormActionsCard marginTop>
          <LocationFormActions
            openLocationDevices={openLocationDevices}
            openLocationDeviceSettings={openLocationDeviceSettings}
          />
        </FormActionsCard>
      )}
    </div>
  );
};
