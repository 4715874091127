import i18n from 'localization/i18n';
import { selectLocation } from 'location/duck/selectors';
import { ChannelType, ThunkTypeAction, ThunkTypeDispatch, Workgroup, WorkgroupLocation } from 'models';
import { StreamFormModel, StreamListItem } from 'models/streams';
import { batch } from 'react-redux';
import { BladeActions, IdName, pushNotification, updateBladeProps } from 'react-tools';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import { BadRequestError, ConcurrencyError, ErrorResponse } from 'utils/http';
import { selectWorkgroupById, selectWorkgroupDetails } from 'workgroup/duck/selectors';

import { StreamFormBladeName } from '../form/stream-form.container';
import * as actions from './actions';
import { StreamService } from './service';

const service = new StreamService();

export const fetchStreamsAsync = (
  channel?: IdName,
  device?: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    if (!channel && !device)
      throw 'Cannot fetch streams without either a channel or a device';

    dispatch(actions.fetchStreamsStart(channel?.id, device?.id));

    try {
      const streams: StreamListItem[] = await service.getStreams(
        channel?.id,
        device?.id
      );
      dispatch(actions.fetchStreamsSuccess(streams));
    } catch (err) {
      dispatch(actions.fetchStreamsFail(err));
    }
  };
};

export const fetchStreamAsync = (streamId: number): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch, getState: () => AppState) => {
    const workgroup: Workgroup = selectWorkgroupDetails(
      getState()
    ) as Workgroup;
    dispatch(actions.fetchStreamStart());

    try {
      const stream: StreamFormModel = await service.getStream(streamId);
      dispatch(actions.fetchStreamSuccess(stream));
    } catch (err) {
      dispatch(actions.fetchStreamFail(err));
    }
  };
};

export const fetchFirstChannelAsync = (
  workgroupId: number,
  channelType: ChannelType
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.firstChannelStart(workgroupId));
    try {
      const channel = await service.getFirstChannelInWorkgroup(workgroupId, channelType);
      dispatch(actions.firstChannelSuccess(channel));
    } catch (err) {
      dispatch(actions.firstChannelFail(err));
    }
  };
};

export const saveStreamAsync = (
  bladeId: string,
  stream: StreamFormModel,
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    const isCreate = stream.id == 0;

    dispatch(actions.saveStreamStart(stream));
    try {
      const fn = isCreate ? service.saveStream : service.updateStream;

      const savedStream = await fn(stream);

      batch(() => {
        dispatch(
          actions.saveStreamSuccess(savedStream, isCreate)
        );
        dispatch(
          actions.setPreselected([
            { id: savedStream.id } as StreamListItem,
          ])
        );
        dispatch(
          updateBladeProps(bladeId, StreamFormBladeName, {
            streamName: savedStream.name,
            streamId: savedStream.id,
          })
        );
      });
    } catch (err) {
      dispatch(catchSaveError(err));
    }
  };
};

export const catchSaveError = (
  error: ConcurrencyError | ErrorResponse
): ThunkTypeAction<void> => {
  return (dispatch: ThunkTypeDispatch) => {
    if (error instanceof ConcurrencyError) {
      dispatch(actions.saveStreamFail(error));
      dispatch(pushNotification(i18n.t('streamForm.concurrency'), 'error'));
      return;
    }

    if (error instanceof BadRequestError) {
      dispatch(actions.saveStreamFail(error));
      dispatch(pushNotification(i18n.t('streamForm.serialConflict'), 'error'));
      return;
    }

    dispatch(actions.saveStreamFail(error));
    dispatch(pushNotification(error.message, 'error'));
  };
};
