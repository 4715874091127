import i18n from 'localization/i18n';
import { ChannelFormModel } from 'models';
import { boolean, number, object, string } from 'yup';

export const ChannelValidationSchema = object().shape<ChannelFormModel>({
  name: string().required(i18n.t('validation.required')).max(255),
  id: number(),
  workgroupId: number(),
  workgroupName: string(),
  channelType: number(),
  rowVersion: string(),
  isExplicit: boolean(),
});
