import i18n from 'localization/i18n';
import { WorkgroupDetailsModel } from 'models';
import { array, boolean, number, object, string } from 'yup';

export const WorkgroupValidationSchema = object().shape<WorkgroupDetailsModel>({
  name: string().required(i18n.t('validation.required')),
  isCompany: boolean(),
  companyNumber: string().when('isCompany', {
    is: true, then: string().required(i18n.t('validation.required'))
  }),
  companyName: string(),
  templates: array(),
  id: number(),
  parentId: number()
});