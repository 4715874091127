import React from 'react';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'red',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
      color: 'red'
  }
}))(Tooltip);
