import { DeviceListItem } from 'models/devices';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem, useGridExportEvents } from 'react-tools';

import { Delete } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';

export const useDeviceListBladeButtons = (
  fetchDevices: () => void,
  createDevice: () => void,
  editDevice: (device: DeviceListItem) => void,
  confirmDeleteDevices: () => void,
  selected: DeviceListItem[],
  loading: boolean,
  gridApi: any
) => {
  const editDeviceCallback = useCallback(
    () => editDevice(selected[0] as DeviceListItem),
    [selected]
  );
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        tooltip: 'devices.refresh',
        icon: () => <Refresh />,
        onClick: fetchDevices,
        disabled: loading,
      },
      {
        tooltip: 'devices.create',
        icon: () => <Add />,
        onClick: createDevice
      },
      {
        tooltip: 'devices.edit',
        icon: () => <Edit />,
        disabled: selected ? selected.length === 0 || selected.length > 1 : true,
        onClick: editDeviceCallback
      },
      {
        tooltip: 'devices.export',
        icon: () => <CloudDownload />,
        onClick: () => {
          if (exportFunctions && exportFunctions.exportCsv) {
            exportFunctions.exportCsv(
              t('devices.exportFileName').toString()
            );
          }
        },
      },
      {
        tooltip: 'devices.delete',
        icon: () => <Delete />,
        disabled: selected ? selected.length === 0 : true,
        onClick: confirmDeleteDevices
      }
    ],
    [selected, loading, gridApi]
  );

  return buttons;
};
