import { DeviceListItem } from 'models/devices';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { DeviceService } from './service';
import { ActionTypes, DeviceState } from './types';

type DeviceActions = ActionType<typeof Actions>;
const service = new DeviceService();

const initialState: DeviceState = {
  devices: [],
  isFetching: false,

  details: {
    errorStatusCode: 0,
    isFetching: false,
    isSaving: false,
    model: null,
  },

  preselected: [],
};

export const deviceReducer: Reducer<DeviceState, DeviceActions> = (
  state: DeviceState = initialState,
  action: DeviceActions
): DeviceState => {
  switch (action.type) {
    case ActionTypes.FETCH_DEVICES_START:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        devices: action.payload.devices,
      };
    }

    case ActionTypes.FETCH_DEVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case ActionTypes.FETCH_DEVICE_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          errorStatusCode: 0,
        },
      };
    }

    case ActionTypes.FETCH_DEVICE_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          model: action.payload.device,
        },
      };
    }

    case ActionTypes.FETCH_DEVICE_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
      };
    }

    case ActionTypes.SAVE_DEVICE_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isSaving: true,
        },
      };
    }

    case ActionTypes.SAVE_DEVICE_SUCCESS: {
      const devices: DeviceListItem[] = [...state.devices];
      const listItem = service.deviceFormModelToDeviceListItem(
        action.payload.device,
        action.payload.sameWorkgroup,
      );

      if (action.payload.create) {
        devices._insert(listItem, 0);
      } else {
        const index = state.devices.findIndex(
          (d) => (d.id === action.payload.device.id)
        );
        devices.splice(index, 1, listItem);
      }
      return {
        ...state,
        devices,
        details: {
          ...state.details,
          model: action.payload.device,
          isSaving: false,
        },
      };
    }

    case ActionTypes.SAVE_DEVICE_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isSaving: false,
          errorStatusCode: action.payload.status,
        },
      };
    }

    case ActionTypes.CLEAR_DEVICE_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          model: null,
        },
      };
    }

    case ActionTypes.SET_PRESELECTED: {
      return {
        ...state,
        preselected: action.payload.devices,
      };
    }

    case ActionTypes.DELETE_DEVICES: {

    }

    case ActionTypes.DELETE_DEVICES_SUCCESS: {
        const devices: DeviceListItem[] = [...state.devices];        
        if(action.payload.devices)
        return {
          ...state,
          devices: devices._differenceByKey("id",action.payload.devices)
        }
    }

    case ActionTypes.DELETE_DEVICES_FAIL: {

    }

    default:
      return state;
  }
};
