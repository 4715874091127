import { createSelector } from 'reselect';

import { areaName, DeviceSettingsState } from './types';

const selectDeviceSettingsState = (state: any): DeviceSettingsState => state[areaName] as DeviceSettingsState;

export const selectEntitySettings = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.entitySettings);
export const selectParentEntitySettings = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.parentEntitySettings);

export const selectEntity = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.entity);

export const selectParentEntity = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.parentEntitySettings);

export const selectIsFetchingDeviceSettings = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.isFetching);

export const selectIsSavingSettings = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.isSavingSettings);

export const selectIsSaved = createSelector([selectDeviceSettingsState], (state: DeviceSettingsState) => state.saved);
