import { JSONSchemaWithMeta } from 'device-settings/duck/json-schema-types';

export interface DeviceSettingModel {
  id: number;
  key: string;
  path: string[];
  groupId: string;
  userId: number;
  username: string;
  description: string;
  riskLevel: number;
  riskLevelLabel?: string;
  isInherited: boolean;
  type: DeviceSettingType;
  schema: JSONSchemaWithMeta;
  value?: any;
  timestamp: Date;
  entityId: number;
  entityType: EntityType;
  origin: string;
}

export enum DeviceSettingType {
  text = 0,
  numeric = 1,
  timestamp = 2,
  array = 3,
  jsonb = 4,
  boolean = 5
}

export interface FetchDeviceSettingsResponse {
  settings: {
    entity: DeviceSettingModel[];
    parent: DeviceSettingModel[] | null;
  }
  tree: DeviceSettingGroupTree[]
}

export interface DeviceSettingGroupTree {
  settingKey: string;
  path: Array<DeviceSettingGroup>;
}

export interface DeviceSettingSaveModel {
  id: number;
  key: string;
  value: any;
  revertValue: boolean;
}

export interface DeviceSettingGroup {
  id: number;
  name: string;
  parentId: number;
}

export enum EntityType {
  Brand = 1,
  Workgroup = 2,
  Group = 3,
  Site = 4,
  Hardware = 5,
  Stream = 6,
  FlashParameter = 7
}