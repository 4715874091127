import i18n from 'localization/i18n';
import { ChannelFormModel, ChannelListItem, ThunkTypeAction, ThunkTypeDispatch } from 'models';
import { batch } from 'react-redux';
import { IdName, pushNotification, updateBladeProps } from 'react-tools';
import { ConcurrencyError, ErrorResponse } from 'utils/http';

import { ChannelFormBladeName } from '../form/channel-form.container';
import * as actions from './actions';
import { ChannelService } from './service';

const service = new ChannelService();

export const fetchChannelsAsync = (
  workgroupId: number
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchChannelsStart(workgroupId));

    try {
      const channels: ChannelListItem[] = await service.getChannels(
        workgroupId
      );
      dispatch(actions.fetchChannelsSuccess(channels));
    } catch (err) {
      dispatch(actions.fetchChannelFail(err));
    }
  };
};
export const fetchChannelAsync = (
  channelId: number
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchChannelStart(channelId));

    try {
      const channel = await service.getChannel(channelId);
      dispatch(actions.fetchChannelSuccess(channel));
    } catch (err) {
      dispatch(actions.fetchChannelFail(err));
    }
  };
};

export const saveChannelAsync = (
  bladeId: string,
  channel: ChannelFormModel,
  workgroup: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    const sameWorkgroup = workgroup.id === channel.workgroupId;
    const isCreate = channel.id === 0;

    dispatch(actions.saveChannelStart(channel));

    try {
      const savedChannel = { ...channel };
      const fn = isCreate ? service.saveChannel : service.updateChannel;
      const { channelId, channelVersion } = await fn(channel);
      savedChannel.id = channelId;
      savedChannel.rowVersion = channelVersion;

      batch(() => {
        dispatch(
          actions.saveChannelSuccess(savedChannel, isCreate, sameWorkgroup)
        );
        dispatch(actions.setPreselected([savedChannel]));
        dispatch(
          updateBladeProps(bladeId, ChannelFormBladeName, {
            workgroup,
            channelName: savedChannel.name,
            channelId: savedChannel.id,
          })
        );
      });
    } catch (err) {
      dispatch(catchSaveError(err));
    }
  };
};

export const catchSaveError = (
  error: ConcurrencyError | ErrorResponse
): ThunkTypeAction<void> => {
  return (dispatch: ThunkTypeDispatch) => {
    if (error instanceof ConcurrencyError) {
      dispatch(actions.saveChannelFail(error));
      dispatch(pushNotification(i18n.t('channelForm.concurrency'), 'error'));
    } else {
      dispatch(actions.saveChannelFail(error));
      dispatch(pushNotification(error.message, 'error'));
    }
  };
};
