import { NavigationList, NavigationListItemModel } from 'components';
import React, { useMemo } from 'react';

import LocationIcon from '@material-ui/icons/LocationOn';
import ChannelIcon from '@material-ui/icons/PlayCircleFilled';
import DeviceIcon from '@material-ui/icons/Router';
import DeviceSettingsIcon from '@material-ui/icons/Settings';

export interface WorkgroupActionsProps {
  openWorkgroupLocations: () => void;
  openWorkgroupDevices: () => void;
  openDeviceSettings: () => void;
  openWorkgroupChannels: () => void;
}

export const WorkgroupActions = React.memo((
  props: WorkgroupActionsProps
) => {
  const items = useMemo<NavigationListItemModel[]>(() => [
    { icon: <LocationIcon />, labelKey: "workgroupDetails.nav.locations", onClick: props.openWorkgroupLocations },
    { icon: <DeviceIcon/>, labelKey: "workgroupDetails.nav.devices", onClick: props.openWorkgroupDevices },
    { icon: <DeviceSettingsIcon/>, labelKey: "workgroupDetails.nav.deviceSettings", onClick: props.openDeviceSettings },
    { icon: <ChannelIcon/>, labelKey: "workgroupDetails.nav.channels", onClick: props.openWorkgroupChannels },
  ], [props.openWorkgroupDevices, props.openWorkgroupLocations, props.openWorkgroupChannels, props.openDeviceSettings]);

  return <NavigationList items={items} />;
});

