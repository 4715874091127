interface Normalized<T> {
  [key: string]: T;
}

export function denormalize<T>(obj: Normalized<T>): T[] {
  const arr = Object.keys(obj).map((k) => obj[k]);
  return arr;
}

export function normalize<T, K extends keyof T>(arr: T[], key: K): Normalized<T> {
  return arr.reduce<Normalized<T>>((obj, item) => {
    obj[(item as any)[key]] = item;
    return obj;
  }, {});
}

declare global {
  interface Array<T> {
    _findBy<T>(key: string, value: T): boolean;
    _findIndexBy<T>(key: string, value: T): number;
    _difference<T>(other: T[]): T[];
    _equals<T>(other: T[], property: string): boolean;
    _insert<T>(element: T, position: number): T[];
    _first<T>(): T;
    _firstFiltered<T>(filterFn: (entity: T) => boolean): T;
    _differenceByKey<T>(key: string, other: T[]): T[]; 
  }

  interface String {
    _contains(value: string, caseSensitive?: boolean): boolean;
    _containsAny(values: string[]): boolean;
    _truncate(n: number): string;
  }

  interface Number {
    _padLeft2(): string;
  }
}

/* eslint-disable no-extend-native */

Array.prototype._findBy = function <T>(key: string, value: T) {
  return this.findIndex((t) => t[key] === value) !== -1;
};

Array.prototype._findIndexBy = function <T>(key: string, value: T) {
  return this.findIndex((t) => t[key] === value);
};

Array.prototype._difference = function <T>(other: T[]) {
  return this.filter((f) => other.indexOf(f) === -1);
};

Array.prototype._differenceByKey = function <T>(key: string, other: T[]){
  return this.filter((f) => other._findIndexBy(key, f[key]) === -1);
};

Array.prototype._insert = function <T>(element: T, position: number) {
  this.splice(position, 0, element);
  return this;
};

Array.prototype._equals = function <T>(other: T[], property: string) {
  if (this.length !== other.length) {
    return false;
  }

  var a = [...this].sort();
  var b: any[] = [...other].sort();

  for (var i = 0; i < a.length; i++) {
    if (a[i][property] !== b[i][property]) {
        return false;
    }
  }

  return true;
};

Array.prototype._firstFiltered = function <T>(filterFn: (entity: T) => boolean): T {
  const filtered = this.filter(filterFn);
  return filtered.length > 0 ? filtered[0] : null;
}

Array.prototype._first = function () {
  return this.length > 0 ? this[0] : null;
}

String.prototype._contains = function (value: any, caseSensitive: boolean = false) {
  if (caseSensitive) {
    return this.indexOf(value) !== -1;
  } else {
    return this.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
};

String.prototype._containsAny = function (values: any) {
  return values.some((v: any) => {
    return this.indexOf(v) !== -1;
  });
};

String.prototype._truncate = function (n: number) {
  return (this.length > n) ? this.substr(0, n-3) + '...' : this as string;
};

Number.prototype._padLeft2 = function () {
  const s = "0" + this.toString();
  return s.substr(s.length - 2, 2);
};

/* eslint-enable no-extend-native */
