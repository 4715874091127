import { LocationState, areaName } from './types';
import { createSelector } from 'reselect';

const selectLocationState = (state: any): LocationState => state[areaName] as LocationState;

export const selectLocations = createSelector([selectLocationState], (state: LocationState) => state.locations);

export const selectLocation = createSelector([selectLocationState], (state: LocationState) => state.details.model);

export const selectIsFetchingLocations = createSelector([selectLocationState], (state: LocationState) => state.isFetching);

export const selectIsFetchingLocation = createSelector([selectLocationState], (state: LocationState) => state.details.isFetching);

export const selectIsSavingLocation = createSelector([selectLocationState], (state: LocationState) => state.details.isSaving);

export const selectSaveErrorStatusCode = createSelector([selectLocationState], (state: LocationState) => state.details.errorStatusCode);

export const selectPreselected = createSelector([selectLocationState], (state: LocationState) => state.preselected);