import { JSONObjectSchema } from 'device-settings/duck/json-schema-types';
import React from 'react';

import { useStyles } from '../editor.jss';
import { EditorTreeItem } from '../editorTreeItem';
import { SettingItem } from '../settingItem';
import { TreeItemLabel } from '../treeItemLabel';
import { SchemaEditorProps } from '../types';

export const ObjectStaticEditor: React.FC<SchemaEditorProps<JSONObjectSchema>> = React.memo((props) => {
    const { label, labelChildren, propKey, schema, value } = props;
    const schemaProps = schema.properties;
    const classes = useStyles();
    return (
      <EditorTreeItem
        nodeId={propKey}
        label={<TreeItemLabel label={label ? label : propKey}><div className={classes.spacer}/>{labelChildren}</TreeItemLabel>}
      >
        {schemaProps &&
          Object.keys(schemaProps).map((prop) => (
            <SettingItem
              key={prop}
              propKey={`${propKey}.${prop}`}
              label={prop}
              required={schema.required ? schema.required.includes(prop) : false}
              schema={schemaProps[prop]}
              value={value ? value[prop] : value}
              readOnly={props.readOnly}
            />
          ))}
      </EditorTreeItem>
    );
  });