import React from "react";
import { useStyles } from "./workgroup-details.jss";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TemplateLibrary } from "models";

export interface WorkgroupDetailsTemplatesProps {
  templates: TemplateLibrary[];
  openTemplateLibrarySelection: () => void;
}

export const WorkgroupDetailsTemplates = React.memo((props: WorkgroupDetailsTemplatesProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.templateContainer}>
      <Typography>{t("workgroupDetails.templates.label")}</Typography>
      <Typography variant="caption" className={classes.templateSpan}>
        {props.templates && props.templates.length ? `${props.templates.map(e => e.name).join(", ")}` : t("workgroupDetails.templates.empty")}
      </Typography>
      <Button variant="contained" color="secondary" onClick={() => props.openTemplateLibrarySelection()}>
        {t("workgroupDetails.templates.select")}
      </Button>
    </div>
  );
});
