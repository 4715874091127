
import { ColumnSettings } from "react-tools";
import { ValueColumnFormatter } from '../list/column-formatters';
import { valueFilterSettings } from "device-settings/common/valueFilterSettings";


export const WizardSettingsColumnSettings: Array<ColumnSettings> = [
  {
    name: "value",
    width: 100,
    formatter: ValueColumnFormatter,
    lockVisibility: true,
    filter: valueFilterSettings.filter,
    customFloatingFilterParams: valueFilterSettings.customFloatingFilterParams
  }
];