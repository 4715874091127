import React from 'react';

import FadeIn from 'react-fade-in';
import { useStyles } from './FadeIn.jss';

export const FlexFadeIn: React.FC<{
  classes?: Record<"fadeIn", string>
}> = props => {
  const classes = useStyles(props);

  return (
    <FadeIn className={classes.fadeIn}>
      {props.children}
    </FadeIn>
  );
}

export const withFlexFadeIn = (Component: React.FC<any>) => (
  <FlexFadeIn>
    <Component />
  </FlexFadeIn>
)