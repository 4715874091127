import { useState, useCallback, useEffect } from 'react';
import { useGridApi } from 'react-tools';
import { RowNode } from '@ag-grid-enterprise/all-modules';
import { DeviceSettingModel } from 'models';

export const useCriticalSettingsVisible = (onSetCriticalToggle?: () => void): [boolean, () => void] => {
  const [criticalVisible, setCriticalVisible] = useState(false);
  const [filterModel, setFilterModel] = useState<any>();

  const gridApi = useGridApi();

  const toggleCriticalVisible = useCallback(
    () => setCriticalVisible((currentCriticalVisibility) => {
      if (onSetCriticalToggle && gridApi) {
        onSetCriticalToggle();
      }

      if (gridApi) {
        setFilterModel(gridApi.getFilterModel());
      }

      return !currentCriticalVisibility;
    }),
    [onSetCriticalToggle, gridApi]
  );

  useEffect(() => {
    if (gridApi && filterModel) {
      gridApi.setFilterModel(filterModel);
    }
  }, [filterModel]);

  useEffect(() => {
    if (gridApi && gridApi.onFilterChanged) {
      gridApi.onFilterChanged();
      if (criticalVisible) {
        let scrolled = false;
        gridApi.forEachNode((row: RowNode) => {
          const rowData: DeviceSettingModel = row.data;
          const shouldScroll = rowData && rowData.riskLevel === 0 && !scrolled;
          if (shouldScroll) {
            gridApi.ensureIndexVisible(row.rowIndex, 'middle');
            scrolled = true;
          }
        });
      }
    }
  }, [criticalVisible, gridApi]);

  return [criticalVisible, toggleCriticalVisible];
}