import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem } from 'react-tools';

import Edit from '@material-ui/icons/Edit';
import Report from '@material-ui/icons/Report';

export const useDeviceSettingsBladeButtons = (
  canOpenWizard: boolean,
  openWizard: () => void
) => {
  const [] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        tooltip: 'wizard.title',
        icon: () => <Edit />,
        onClick: openWizard,
        disabled: !canOpenWizard,
      }
    ],
    [canOpenWizard]
  );

  return buttons;
};
