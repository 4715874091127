import classNames from 'classnames';
import { JSONObjectSchema } from 'device-settings/duck/json-schema-types';
import { createObject } from 'device-settings/duck/utils';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip, Typography } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import { useStyles } from '../editor.jss';
import { EditorTreeItem } from '../editorTreeItem';
import { SettingItem } from '../settingItem';
import { TreeItemLabel } from '../treeItemLabel';
import { SchemaEditorProps } from '../types';
import { KeyEditorDialog } from './keyEditorDialog';
import { ObjectNoPropsError } from './objectNoPropsError';

export const ObjectDynamicEditor: React.FC<SchemaEditorProps<
  JSONObjectSchema
>> = React.memo((props) => {
  const { label, labelChildren, propKey, schema, value, readOnly } = props;
  const patternProps = schema.patternProperties;

  const classes = useStyles();
  const [t] = useTranslation();
  const [dialogState, setDialogState] = useState<{
    key?: string;
    open: boolean;
  }>({ key: undefined, open: false });

  if (!patternProps) {
    return <ObjectNoPropsError propKey={propKey} />;
  }

  const [field] = useField(propKey);
  const { setFieldValue, getFieldProps } = useFormikContext();

  const onPropDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const prop = e.currentTarget.name;
      setFieldValue(prop, undefined);
      e.stopPropagation();
    },
    [setFieldValue]
  );

  const onKeyEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      setDialogState({ key: e.currentTarget.name, open: true });
    },
    [setDialogState]
  );

  const onKeyEditDialogCancel = useCallback(
    () => setDialogState({ key: undefined, open: false }),
    [setDialogState]
  );

  const onKeyEditDialogSubmit = useCallback(
    (
      oldKey: string | undefined,
      newKey: string,
      matchedPatternProp: string
    ) => {
      const newProp = `${propKey}.${newKey}`;
      if (oldKey) {
        const oldProp = `${propKey}.${oldKey}`;
        const fieldProps = getFieldProps(oldProp);
        setFieldValue(newProp, fieldProps.value);
        if (newProp !== oldProp) {
          setFieldValue(oldProp, undefined);
        }
      } else {
        setFieldValue(newProp, createObject(patternProps[matchedPatternProp]));
      }
      setDialogState({ key: undefined, open: false });
    },
    [setDialogState, setFieldValue, getFieldProps, patternProps, propKey]
  );

  // value might change from object to null/primitive/array by reverting back to an inherited value
  if (field.value !== Object(field.value) || Array.isArray(field.value)) {
    return (
      <EditorTreeItem
        nodeId={propKey}
        label={
          <TreeItemLabel label={label ? label : propKey}>
            {labelChildren}
          </TreeItemLabel>
        }
      />
    );
  }

  const items = Object.keys(field.value).map((valueKey) => {
    const patternProp = Object.keys(patternProps).find((patternPropKey) => {
      const r = new RegExp(patternPropKey);
      return r.test(valueKey);
    });
    if (!patternProp) {
      return (
        <p>{`Object property ${valueKey} does not match any pattern property`}</p>
      );
    }
    const propSchema = patternProps[patternProp as string];
    const prop = `${propKey}.${valueKey}`;
    return (
      <SettingItem
        key={valueKey}
        readOnly={props.readOnly}
        propKey={prop}
        label={valueKey}
        schema={propSchema}
        value={value ? value[valueKey] : value}
        labelChildren={
          <>
            <Tooltip title={t('settingsEditor.button.editKey').toString()}>
              <IconButton
                disabled={readOnly}
                size="small"
                className={classNames(classes.onHoverOnly)}
                name={valueKey}
                onClick={onKeyEdit}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('settingsEditor.button.removeItem').toString()}>
              <IconButton
                size="small"
                disabled={readOnly}
                className={classNames(classes.onHoverOnly)}
                name={prop}
                onClick={onPropDelete}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        }
      />
    );
  });
  const propertiesCount = Object.keys(field.value).length;
  return (
    <>
      <EditorTreeItem
        nodeId={propKey}
        label={
          <TreeItemLabel label={label ? label : propKey}>
            <div className={classes.arrayItemHeaderContainer}>
              <Typography
                variant="caption"
                className={classes.arrayItemHeaderCountLabel}
              >
                {propertiesCount === 1
                  ? t('settingsEditor.propertiesCountSingle')
                  : t('settingsEditor.propertiesCountMultiple', {
                      count: propertiesCount,
                    })}
              </Typography>
              <Tooltip title={t('settingsEditor.button.addItem').toString()}>
                <IconButton
                  disabled={readOnly}
                  color="secondary"
                  size="small"
                  onClick={onKeyEdit}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </div>
            {labelChildren}
          </TreeItemLabel>
        }
      >
        {items}
      </EditorTreeItem>
      <KeyEditorDialog
        schema={schema}
        propKey={dialogState.key}
        isOpen={dialogState.open}
        onCancel={onKeyEditDialogCancel}
        onSubmit={onKeyEditDialogSubmit}
      />
    </>
  );
});
