import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      overflowY: "auto",
      marginBottom: theme.spacing() / 2,
      marginTop: theme.spacing(), 
      paddingLeft: theme.spacing() * 2,
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        cursor: "pointer",
        background: "#D4CCC7",
      },

      "&::webkit-scrollbar-track": {
        background: "#9A9791",
        cursor: "pointer",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#9A9791",
        borderRadius: "5px",
        cursor: "pointer",

        "&:hover": {
          background: "#9A9791",
        },
      },
    },
    moreButton: {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer"
      }
    }
  }));
