import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BladeActions, TreeActions } from 'react-tools';
import { actions } from 'workgroup/duck';
import { WorkgroupDetailsMode } from 'workgroup/duck/types';

import { WORKGROUP_TREE_NAME } from '../../../constants';

export const useOpenWorkgroupDetails = () => {
  const dispatch = useDispatch();
  const currentlyEditingWorkgroupId = useRef(0);

  return (workgroupId: number, workgroupName: string, onClose: () => void) => {

    const props = {
      mode: WorkgroupDetailsMode.EDIT,
      workgroupId,
      workgroupName,
      parentWorkgroupId: 0,
      onClose
    };

    const previouslySelectedWorkgroupId = currentlyEditingWorkgroupId.current;

    const onCancelAction =
      currentlyEditingWorkgroupId.current !== 0 ?
        [
          TreeActions.selectModel(WORKGROUP_TREE_NAME, currentlyEditingWorkgroupId.current),
          () => currentlyEditingWorkgroupId.current = previouslySelectedWorkgroupId
        ] :
        undefined;

    dispatch(BladeActions.closeBlade(
      undefined,
      [
        actions.openWorkgroupBlade(props),
        TreeActions.selectModel(WORKGROUP_TREE_NAME, workgroupId)
      ],
      onCancelAction
    ));

    currentlyEditingWorkgroupId.current = workgroupId;
  };
}

export const useOpenCreateWorkgroup = () => {
  const dispatch = useDispatch();
  const creatingWorkgroupParentId = useRef(0);

  return (parentWorkgroupId: number, onClose: () => void) => {
    const props = {
      mode: WorkgroupDetailsMode.NEW,
      workgroupId: 0,
      workgroupName: "",
      parentWorkgroupId,
      onClose
    };

    const onCancelAction =
      creatingWorkgroupParentId.current !== 0 ?
        TreeActions.selectModel(WORKGROUP_TREE_NAME, creatingWorkgroupParentId.current) :
        undefined;

    dispatch(
      BladeActions.closeBlade(
        undefined,
        [
          actions.openWorkgroupBlade(props),
          TreeActions.selectModel(WORKGROUP_TREE_NAME, parentWorkgroupId)
        ],
        onCancelAction
      )
    );

    creatingWorkgroupParentId.current = parentWorkgroupId;
  }
};