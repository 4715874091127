import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'react-tools';

import { Divider, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Star from '@material-ui/icons/Star';
import ZoomOutMap from '@material-ui/icons/ZoomOutMapRounded';

import { useStyles } from './workgroup-tree-toolbar.jss';

export interface WorkgroupTreeToolbarProps {
  loading: boolean;
  expandAll: () => void;
  collapseAll: () => void;
  handleFavoriteButtonClick: () => void;
  handleDeleteButtonClick: () => void;
  searchWorkgroups: (searchText: string) => void;
}

export const WorkgroupTreeToolbar = (props: WorkgroupTreeToolbarProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { collapseAll, expandAll } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleCollapse = useCallback(() => {
    if (isCollapsed) {
      props.expandAll();
    } else {
      props.collapseAll();
    }
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, collapseAll, expandAll]);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      props.searchWorkgroups(e.target.value);
    },
    [setSearchTerm]
  );

  return (
    <>
      <div className={classes.buttons}>
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <IconButton
            className={classes.toolbarButton}
            onClick={toggleCollapse}
          >
            {isCollapsed ? <ZoomOutMap /> : <FullscreenExit />}
          </IconButton>

          <IconButton
            className={classes.toolbarButton}
            onClick={props.handleFavoriteButtonClick}
          >
            <Star />
          </IconButton>

          <IconButton
            className={classes.toolbarButton}
            onClick={props.handleDeleteButtonClick}
          >
            <Delete />
          </IconButton>
        </div>
      </div>

      <Divider />
      <SearchBox
        onChange={onSearchChange}
        placeholder={t('search')}
        autoFocus
      />
      <Divider />
    </>
  );
};
