import { createStyles, makeStyles, Theme } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },

    wizardContaier: {
      minHeight: '1px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },

    wizardContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    centerText: {
      textAlign: 'center',
    },

    dangerZoneMathContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    dangerZoneWarningContainer: {
      padding: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.warning.main}`,
      borderBottom: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: theme.palette.warning.light,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(3),
    },

    dangerZoneWarningContent: {
      color: theme.palette.warning.contrastText,
    },

    dangerZoneInputContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
    },

    dangerZoneMath: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: theme.spacing(1),
    },

    dangerZoneInput: {
      width: 80,
      '& input': {
        fontSize: theme.typography.h5.fontSize,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },

    countInputContainer: {
      marginTop: theme.spacing(2),
    },

    countInput: {
      marginTop: theme.spacing(1),
    },

    timerContainer: {
      display: 'flex',
      marginTop: theme.spacing(1),
      position: 'relative'
    },

    progress: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    progressText: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: `calc(50% - ${theme.spacing(3.5)}px)`,
    },

    completedIcon: {
      color: theme.palette.success.main,
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      fontSize: theme.typography.h2.fontSize,
      top: `calc(50% - ${theme.spacing(4)}px)`,
    },
    spacer: {
      flexGrow: 1,
    },
    critDialogCheckRoot: {
      marginLeft: 0,
    },
    confirmDialogSummaryAlertIcon: {
      marginBottom: theme.spacing(-0.5),
    },
  })
);
