import { createSelector } from 'reselect';

import { areaName, ChannelState } from './types';

const selectChannelState = (state: any): ChannelState => state[areaName] as ChannelState;

export const selectChannels = createSelector([selectChannelState], (state: ChannelState) => state.channels);

export const selectChannel = createSelector([selectChannelState], (state: ChannelState) => state.details.model);

export const selectIsFetchingChannels = createSelector([selectChannelState], (state: ChannelState) => state.isFetching);

export const selectIsFetchingChannel = createSelector([selectChannelState], (state: ChannelState) => state.details.isFetching);

export const selectIsSavingChannel = createSelector([selectChannelState], (state: ChannelState) => state.details.isSaving);

export const selectSaveErrorStatusCode = createSelector([selectChannelState], (state: ChannelState) => state.details.errorStatusCode);

export const selectPreselected = createSelector([selectChannelState], (state: ChannelState) => state.preselected);