import { JSONAnySchema, JSONSchema, JSONType } from 'device-settings/duck/json-schema-types';
import { FieldHelperProps, FieldInputProps, useField } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Divider, MenuItem, Select } from '@material-ui/core';

import { createObject } from '../duck/utils';
import { useStyles } from './editor.jss';
import { SettingItem } from './settingItem';
import { SchemaEditorProps } from './types';

const calculateSelectedSchema = (schema: JSONAnySchema, propKey: string, value: any) : JSONSchema | undefined => {
  if (schema.anyOf.length === 0) {
    console.warn(
      `Schema for prop key ${propKey} has no schemas defined in anyOf`
    );
    return {type: 'null'};
  }
  const nullSchema = schema.anyOf.find((s) => s.type === 'null');

  if (value === undefined) {
    if (nullSchema) return nullSchema;
    return schema.anyOf[0];
  }

  if (value === null) {
    if (!nullSchema) {
      console.warn(
        `Value for prop key ${propKey} is null but no schema defined for 'null' in anyOf`
      );
    }
    return nullSchema;
  }

  if (Array.isArray(value)) {
    const arraySchema = schema.anyOf.find((s) => s.type === 'array');

    if (arraySchema) {
      return arraySchema;
    }
  }

  return schema.anyOf.find((s) => typeof value === s.type);
}

export const AnyEditor: React.FC<SchemaEditorProps<JSONAnySchema>> = React.memo(
  ({ schema, propKey, value, readOnly, labelChildren, label }) => {
    const classes = useStyles();
    const [field, meta, helpers] = useField(propKey);
    const [selectedSchema, setSelectedSchema] = useState<JSONSchema | undefined>(
      () => calculateSelectedSchema(schema, propKey, value)
    );

    useEffect(() => {
      var newSchema = calculateSelectedSchema(schema, propKey, value);
      setSelectedSchema(newSchema);
    }, [value]);    

    const onSchemaChanged = useCallback(
      (e: React.ChangeEvent<any>, child: React.ReactNode) => {
        const newType = e.target.value as JSONType;
        var newSchema = schema.anyOf.find((s) => s.type === newType);
        if (newSchema) {
          helpers.setValue(createObject(newSchema));
        }
        setSelectedSchema(newSchema);
      },
      [setSelectedSchema]
    );

    const schemaSelect = useMemo(
      () => (
        <Select
          value={selectedSchema?.type}
          onChange={onSchemaChanged}
          disableUnderline
          readOnly={readOnly}
          className={classes.schemaSelect}
        >
          {schema.anyOf.map((s) => (
            <MenuItem key={s.type} value={s.type}>
              {s.type}
            </MenuItem>
          ))}
        </Select>
      ),
      [onSchemaChanged, selectedSchema, readOnly]
    );
    
    if (!selectedSchema) {
      return schemaSelect;
    }
   
    return (
      <SettingItem
        readOnly={readOnly}
        label={label ? label : propKey}
        propKey={propKey}
        schema={selectedSchema}
        value={value}
        labelChildren={
          <>
            {schemaSelect}
            <div className={classes.spacer}/>
            {labelChildren}
          </>
        }
      />
    );
  }
);
