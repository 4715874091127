import i18n from 'localization/i18n';
import { ChannelType } from 'models/channel';
import { StreamFormModel } from 'models/streams';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BladeActions, BladeConfig, BladeProps, IdName, openBlade, withRegisterBlade
} from 'react-tools';
import { actions, selectors, thunks } from 'stream/duck';
import { useChannelSelectionBlade } from 'stream/hooks/useChannelSelectionBlade';

import { StreamForm } from './stream-form';

export const StreamFormBladeName = 'STREAM_FORM_BLADE_NAME';

export interface StreamFormContainerProps {
  workgroup: IdName;
  streamId?: number;
  streamName?: string;
  device: IdName;
}

const bladeConfig: BladeConfig<StreamFormContainerProps> = {
  bladeType: StreamFormBladeName,
  size: { defaultWidth: 460, minWidth: 400 },
  title: (props: StreamFormContainerProps) =>
    props.streamName ? props.streamName : i18n.t('streamForm.createTitle'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  streamId,
  streamName,
  device,
  workgroup,
  ...bladeProps
}: StreamFormContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const isSaving = useSelector(selectors.selectIsSavingStream);
  const isFetching = useSelector(selectors.selectIsFetchingStream);
  const stream = useSelector(selectors.selectStream);
  const savingErrorCode = useSelector(selectors.selectSaveErrorStatusCode);
  const channel = useSelector(selectors.selectChannel);
  const fetchingFirstChannel = useSelector(
    selectors.selectIsFetchingFirstChannel
  );

  const openChannelSelectionBlade = useChannelSelectionBlade(
    bladeProps.bladeId,
    []
  );

  const fetchStream = useCallback(
    (streamId: number) => dispatch(thunks.fetchStreamAsync(streamId)),
    [dispatch]
  );
  const saveStream = useCallback(
    (stream: StreamFormModel) =>
      dispatch(
        thunks.saveStreamAsync(bladeProps.bladeId, stream)
      ),
    [dispatch]
  );
  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(bladeProps.bladeId)),
    [bladeProps.bladeId]
  );

  const fetchFirstChannel = useCallback(
    () => dispatch(thunks.fetchFirstChannelAsync(workgroup.id, ChannelType.Video)),
    [workgroup?.id]
  );
  return (
    <StreamForm
      {...bladeProps}
      channel={channel}
      device={device}
      streamId={streamId}
      fetchStream={fetchStream}
      saveStream={saveStream}
      stream={stream}
      isSaving={isSaving}
      isFetching={isFetching}
      closeBlade={closeBlade}
      savingErrorCode={savingErrorCode}
      openChannelSelectionBlade={openChannelSelectionBlade}
      workgroup={workgroup}
      fetchFirstChannel={fetchFirstChannel}
      fetchingFirstChannel={fetchingFirstChannel}
    />
  );
};

export const LocationFormContainer = withRegisterBlade<
  StreamFormContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);
