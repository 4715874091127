import i18n from 'localization/i18n';
import { selectLocation } from 'location/duck/selectors';
import { ThunkTypeAction, ThunkTypeDispatch, Workgroup, WorkgroupLocation } from 'models';
import { DeleteDevicesDto, DeviceFormModel, DeviceListItem, DeviceResponseModel, DeviceSaveResultCode, DeviceSaveResultType } from 'models/devices';
import { batch } from 'react-redux';
import { BladeActions, IdName, pushNotification, updateBladeProps } from 'react-tools';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import { BadRequestError, ConcurrencyError, ErrorResponse } from 'utils/http';
import { selectWorkgroupById, selectWorkgroupDetails } from 'workgroup/duck/selectors';

import { DeviceFormBladeName } from '../form/device-form.container';
import * as actions from './actions';
import { DeviceService } from './service';

const service = new DeviceService();

export const fetchDevicesAsync = (
  workgroup: IdName,
  location?: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchDevicesStart(workgroup.id, location?.id));

    try {
      const devices: DeviceListItem[] = await service.getDevices(
        workgroup.id,
        location?.id
      );
      dispatch(actions.fetchDevicesSuccess(devices));
    } catch (err) {
      dispatch(actions.fetchDevicesFail(err));
    }
  };
};

export const fetchDeviceAsync = (deviceId: number): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch, getState: () => AppState) => {
    const workgroup: Workgroup = selectWorkgroupDetails(
      getState()
    ) as Workgroup;
    dispatch(actions.fetchDeviceStart());

    try {
      const device: DeviceFormModel = await service.getDevice(deviceId);
      dispatch(actions.fetchDeviceSuccess(device));
    } catch (err) {
      dispatch(actions.fetchDeviceFail(err));
    }
  };
};

export const deleteDevicesAsync = (devices: DeviceListItem[]): ThunkTypeAction<void> => {
  return  async (dispatch: ThunkTypeDispatch, getState: () => AppState) => {
   // dispatch(actions.deleteDevices(devices));

    try {
      let deleteDevicesDto: DeleteDevicesDto = { devices: devices.map(x => x.id)};

      await service.deleteDevices(deleteDevicesDto);
      batch(() => {
        dispatch(actions.deleteDevicesSuccess(devices));
        if(devices.length === 1)
        {
          dispatch(
              pushNotification(i18n.t('deviceForm.deleteDeviceSuccessToast'), 'success')
          );
        }
          else {
            dispatch(
              pushNotification(i18n.t('deviceForm.deleteDevicesSuccessToast'), 'success')
          );
          }
      })      
    } catch (err) {
      dispatch(actions.deleteDevicesFail(err));
    }
  }

}


export const saveDeviceAsync = (
  bladeId: string,
  device: DeviceFormModel,
  workgroup: IdName,
  location?: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    const sameWorkgroup = workgroup.id === device.workgroupId;
    const isCreate = device.id == 0;
    let saveDevice: DeviceResponseModel;

    dispatch(actions.saveDeviceStart(device));
    try {
      const fn = isCreate ? service.saveDevice : service.updateDevice;
      saveDevice = await fn(device);
      saveDevice.device.locationName = device.locationName;
      saveDevice.device.locationId = device.locationId;
      saveDevice.device.workgroupId = device.workgroupId;
      saveDevice.device.workgroupName = device.workgroupName;
      if (saveDevice.saveResultType != DeviceSaveResultType.Error)
      {
        batch(() => {
        dispatch(
          actions.saveDeviceSuccess(saveDevice.device, isCreate, sameWorkgroup)
        );
        dispatch(
          actions.setPreselected([
            { id: saveDevice.device.id, name: saveDevice.device.name, isExplicit: sameWorkgroup } as DeviceListItem,
          ])
        );
        dispatch(
          updateBladeProps(bladeId, DeviceFormBladeName, {
            deviceName: saveDevice.device.name,
            deviceId: saveDevice.device.id,
            workgroup,
            location,
          })        
        );
        if(saveDevice.saveResultType === DeviceSaveResultType.Warning && saveDevice.saveResultCode === DeviceSaveResultCode.TimeoutError)
        {
          dispatch(pushNotification(i18n.t('deviceForm.timeoutError'), 'warning'));         
        };
        dispatch(pushNotification(i18n.t('deviceForm.saveDeviceSuccessToast'), 'success'));
      });
    }   
    } catch (err) {
      dispatch(catchSaveError(err, err.message.saveResultCode));
    }
  };
};

export const catchSaveError = (
  error: ConcurrencyError | ErrorResponse,
  deviceSaveResultCode: DeviceSaveResultCode
): ThunkTypeAction<void> => {
  return (dispatch: ThunkTypeDispatch) => {
    if (error instanceof BadRequestError) {
      dispatch(actions.saveDeviceFail(error));
      switch (deviceSaveResultCode)
      {
        case DeviceSaveResultCode.SerialExistsError :
          dispatch(pushNotification(i18n.t('deviceForm.serialConflict'), 'error'));
          return;
        case DeviceSaveResultCode.DbConcurrencyError :
          dispatch(pushNotification(i18n.t('deviceForm.concurrency'), 'error'));
          return;
        case DeviceSaveResultCode.PlayersFolderError :
          dispatch(pushNotification(i18n.t('deviceForm.playersFolder'), 'error'));
          return;
        case DeviceSaveResultCode.SqlError :
           dispatch(pushNotification(i18n.t('deviceForm.sqlError'), 'error'));
           return;
        default:
          dispatch(pushNotification(i18n.t('deviceForm.error'), 'error'));         
          return;
      }
    }
    dispatch(actions.saveDeviceFail(error));
    dispatch(pushNotification(error.message, 'error'));
  };
};
