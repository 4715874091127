import { selectors, thunks, actions } from 'device-settings/duck';
import { Entity } from 'device-settings/duck/types';
import i18n from 'localization/i18n';
import { DeviceSettingSaveModel } from 'models';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions, BladeConfig, BladeProps, DataGridContextProvider, IdName, withRegisterBlade
} from 'react-tools';

import { Wizard } from './wizard';

export const WizardBladeName = 'WIZARD';

const bladeConfig: BladeConfig<{}> = {
  bladeType: WizardBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  title: () => i18n.t('wizard.title'),
  allowMultipleInstances: false,
};

export interface WizardContainerProps {
  parentEntity: Entity | null;
}

const WizardContainerComponent = (props: WizardContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const entitySettings = useSelector(selectors.selectEntitySettings);
  const parentEntitySettings = useSelector(
    selectors.selectParentEntitySettings
  );
  const entity = useSelector(selectors.selectEntity);
  const isSavingSettings = useSelector(selectors.selectIsSavingSettings);
  const saved = useSelector(selectors.selectIsSaved);

  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(props.bladeId)),
    [props.bladeId]
  );

  const saveDeviceSettings = useCallback(
    (entity: Entity, parentEntity: Entity | null, settings: DeviceSettingSaveModel[]) =>
      dispatch(thunks.saveDeviceSettingsAsync(entity, parentEntity, settings)),
    [entity]
  );

  const clearSavedFlag = useCallback(() => dispatch(actions.clearSavedFlag()), []);

  return (
    <DataGridContextProvider>
      <Wizard
        {...props}
        isSavingSettings={isSavingSettings}
        saveDeviceSettings={saveDeviceSettings}
        entity={entity}
        entitySettings={entitySettings}
        parentEntitySettings={parentEntitySettings}
        closeBlade={closeBlade}
        saved={saved}
        clearSavedFlag={clearSavedFlag}
      />
    </DataGridContextProvider>
  );
};

export const DeviceSettingsContainer = withRegisterBlade<
  WizardContainerProps & BladeProps
>(bladeConfig)(WizardContainerComponent);
