import { ColumnSettings } from 'react-tools';

export const locationsColumnSettings: Array<ColumnSettings> = [
  {
    name: "id",
    width: 140,
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    name: "name",
    width: 170,
    sort: { order: 0, direction: "asc" },
    lockVisibility: true,
    filter: 'agTextColumnFilter'
  },
  {
    name: "oracleNumber",
    width: 170,
    filter: 'agTextColumnFilter'
  },
  {
    name: "country",
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: "state",
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: "city",
    width: 120,
    filter: 'agTextColumnFilter'
  },
  {
    name: "address",
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: "zipCode",
    width: 120,
    filter: 'agTextColumnFilter'
  },
  {
    name: "timezone",
    width: 120,
    filter: 'agTextColumnFilter'
  },
  {
    name: "workgroupId",
    width: 120,
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    name: "workgroupName",
    width: 120,
    filter: 'agTextColumnFilter'
  },
];
