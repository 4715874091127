import React, { useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MAA } from "MAA.boostrap";

import { BladeActions, BladeSelectors } from "react-tools";
import { denormalize } from "utils";
import { AppBreadcrumbs } from "./breadcrumbs";

export const AppBreadcrumbsContainer = () => {
  const dispatch = useDispatch();

  const exitApp = useCallback(() => MAA.homeFn(), []);
  const setBladeFocus = useCallback((bladeId: string) => dispatch(BladeActions.setFocused(bladeId)), [dispatch]);

  const normalizedActiveBlades = useSelector(BladeSelectors.selectActiveBlades);

  const activeBlades = useMemo(() => denormalize(normalizedActiveBlades), [normalizedActiveBlades]);

  return <AppBreadcrumbs activeBlades={activeBlades} exitApp={exitApp} setBladeFocus={setBladeFocus} />;
};
