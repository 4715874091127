import {
    channelsColumnSettings, fetchChannelsAsync, selectChannels, selectIsFetchingChannels
} from 'channel';
import { WorkgroupFilterFooter } from 'components/ExternalFilterFooter';
import { ChannelListItem } from 'models';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    DataGridSelectionType, IdName, openBlade, SelectionBladeContainerProps, SelectionListBladeType
} from 'react-tools';
import { actions } from 'stream/duck';

import { RowNode } from '@ag-grid-enterprise/all-modules';

export const useChannelSelectionBlade = (bladeId: string, deps: Array<any>) => {
  const isTemplateSelectionOpen = useRef(false);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const openTemplateLibrarySelection = useCallback(
    (workgroupId: number, selectedChannel: IdName) => {
      if (!isTemplateSelectionOpen.current) {
        dispatch(fetchChannelsAsync(workgroupId));

        dispatch(
          openBlade<SelectionBladeContainerProps>(
            bladeId,
            SelectionListBladeType,
            {
              columns: channelsColumnSettings,
              identifier: "channels",
              onClose: (items: ChannelListItem[] | null) => {
                if (items !== null && items.length === 1) {
                  dispatch(actions.setChannel(items[0]));
                }
                isTemplateSelectionOpen.current = false;
              },
              selectionType: DataGridSelectionType.Single,
              title: t("channels.listTitle"),
              itemsSelector: selectChannels,
              selectedItems: [selectedChannel],
              isFetchingSelector: selectIsFetchingChannels,
              externalFilterFn: (item: RowNode) =>
                item.data ? item.data.workgroupId === workgroupId : true,
              footerSecondary: <WorkgroupFilterFooter />,
              defaultIsExternalFilterEnabled: () => true
            }
          )
        );
        isTemplateSelectionOpen.current = true;
      }
    },
    [...deps]
  );

  return openTemplateLibrarySelection;
};
