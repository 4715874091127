import { action } from "typesafe-actions";

import { ActionTypes, WorkgroupDetailsMode } from "./types";
import { Workgroup, WorkgroupDetailsModel, TemplateLibrary } from 'models';
import { WorkgroupDetailsContainerProps, WorkgroupDetailsBladeName } from '../details/workgroup-details.container';
import { openBlade } from 'react-tools';

export const fetchWorkgroupsStart = () => action(ActionTypes.FETCH_WORKGROUPS_START);
export const fetchWorkgroupsSuccess = (workgroups: Workgroup[]) => action(ActionTypes.FETCH_WORKGROUPS_SUCCESS, { workgroups });
export const fetchWorkgroupsFail = (error: any) => action(ActionTypes.FETCH_WORKGROUPS_FAIL, { error });

export const fetchWorkgroupDetailsStart = (workgroupId: number) => action(ActionTypes.FETCH_WORKGROUP_START, { workgroupId });
export const fetchWorkgroupDetailsSuccess = (workgroup: WorkgroupDetailsModel) => action(ActionTypes.FETCH_WORKGROUP_SUCCESS, { workgroup });
export const fetchWorkgroupDetailsFail = (error: any) => action(ActionTypes.FETCH_WORKGROUP_FAIL, { error });

export const setWorkgroupAsFavorite = (workgroupId: number) => action(ActionTypes.WORKGROUP_FAVORITE_REQUEST, { workgroupId });
export const setWorkgroupAsFavoriteSuccess = (workgroupId: number) => action(ActionTypes.WORKGROUP_FAVORITE_SUCCESS, { workgroupId });

export const removeWorkgroupAsFavorite = (workgroupId: number) => action(ActionTypes.WORKGROUP_UNFAVORITE_REQUEST, { workgroupId });
export const removeWorkgroupAsFavoriteSuccess = (workgroupId: number) => action(ActionTypes.WORKGROUP_UNFAVORITE_SUCCESS, { workgroupId });

export const saveWorkgroupStart = (workgroup: WorkgroupDetailsModel, mode: WorkgroupDetailsMode) => action(ActionTypes.SAVE_WORKGROUP_START, { workgroup, mode });
export const saveWorkgroupSuccess = (workgroup: WorkgroupDetailsModel) => action(ActionTypes.SAVE_WORKGROUP_SUCCESS, { workgroup });
export const saveWorkgroupFail = () => action(ActionTypes.SAVE_WORKGROUP_FAIL, {});

export const setWorkgroupTemplates = (templates: TemplateLibrary[]) => action(ActionTypes.SET_WORKGROUP_TEMPLATES, { templates });
export const clearWorkgroupDetails = () => action(ActionTypes.CLEAR_WORKGROUP_DETAILS);

export const fetchTemplatesStart = () => action(ActionTypes.FETCH_TEMPLATES_START);
export const fetchTemplatesSuccess = (templates: TemplateLibrary[]) => action(ActionTypes.FETCH_TEMPLATES_SUCCESS, { templates });
export const fetchTemplatesFail = (error: any) => action(ActionTypes.FETCH_TEMPLATES_FAIL, { error });

export const openWorkgroupBlade = (props: WorkgroupDetailsContainerProps) => openBlade<WorkgroupDetailsContainerProps>(
  "",
  WorkgroupDetailsBladeName,
  props
)