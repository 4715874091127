import { EntityType } from 'models';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, DeviceSettingsState } from './types';

type DeviceSettingState = ActionType<typeof Actions>;

const initialState: DeviceSettingsState = {
  entity: { entityId: 0, entityType: EntityType.Workgroup },
  parentEntity: null,
  entitySettings: [],
  parentEntitySettings: [],
  isFetching: false,

  isSavingSettings: false,
  saved: false
};

export const deviceSettingsReducer: Reducer<
  DeviceSettingsState,
  DeviceSettingState
> = (state = initialState, action: DeviceSettingState): DeviceSettingsState => {
  switch (action.type) {
    case ActionTypes.FETCH_DEVICE_SETTINGS:
      return {
        ...state,
        isFetching: true,
        saved: false
      };
    case ActionTypes.FETCH_DEVICE_SETTINGS_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case ActionTypes.FETCH_DEVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        entity: action.payload.entity,
        parentEntity: action.payload.parentEntity,
        entitySettings: action.payload.entitySettings,
        parentEntitySettings: action.payload.parentSettings,
        isFetching: false,
      };
    case ActionTypes.CLEAR_DEVICE_SETTINGS:
      return {
        ...state,
        entitySettings: [],
        parentEntitySettings: [],
        entity: { entityId: 0, entityType: EntityType.Workgroup },
        parentEntity: null,
      };
    case ActionTypes.SAVE_DEVICE_SETTINGS: {
      return {
        ...state,
        isSavingSettings: true,
      };
    }
    case ActionTypes.SAVE_DEVICE_SETTINGS_FAIL: {
      return {
        ...state,
        isSavingSettings: false,
      };
    }
    case ActionTypes.SAVE_DEVICE_SETTINGS_SUCCESS: {
      const newSettings = action.payload.settings.reduce(
        (a, s) => {
          const index = a.findIndex((x) => x.key === s.key);
          a[index].value = s.value;   
          a[index].userId = s.userId;
          a[index].username = s.username;
          a[index].timestamp = s.timestamp;
          a[index].description = s.description; 
          a[index].origin = s.origin;
          a[index].entityId = s.entityId;
          a[index].entityType = s.entityType;
          a[index].isInherited = s.isInherited;
          return a;
        },
        [...state.entitySettings]
      );

      return {
        ...state,
        entitySettings: newSettings,
        isSavingSettings: false,
        saved: true
      };
    }

    case ActionTypes.CLEAR_SAVED_FLAG: {
      return {
        ...state,
        saved: false
      };
    }
    default:
      return state;
  }
};
