import {
    ChannelFormBladeName, ChannelFormContainerProps
} from 'channel/form/channel-form.container';
import { ChannelFormModel, ChannelListItem } from 'models';
import { openBlade } from 'react-tools';
import { action } from 'typesafe-actions';
import { ErrorResponse } from 'utils/http';

import { ActionTypes } from './types';

export const fetchChannelsStart = (workgroupId: number) => action(ActionTypes.FETCH_CHANNELS_START, { workgroupId });
export const fetchChannelsSuccess = (channels: ChannelListItem[]) => action(ActionTypes.FETCH_CHANNELS_SUCCESS, { channels });
export const fetchChannelsFail = (error: any) => action(ActionTypes.FETCH_CHANNELS_FAIL, { error });

export const fetchChannelStart = (channelId: number) => action(ActionTypes.FETCH_CHANNEL_START, { channelId });
export const fetchChannelSuccess = (channel: ChannelFormModel) => action(ActionTypes.FETCH_CHANNEL_SUCCESS, { channel });
export const fetchChannelFail = (error: any) => action(ActionTypes.FETCH_CHANNEL_FAIL, { error });

export const saveChannelStart = (channel: ChannelFormModel) => action(ActionTypes.SAVE_CHANNEL_START, { channel });
export const saveChannelSuccess = (channel: ChannelFormModel, create: boolean, sameWorkgroup: boolean) => action(ActionTypes.SAVE_CHANNEL_SUCCESS, { channel, create, sameWorkgroup });
export const saveChannelFail = ({ message, status }: ErrorResponse) => action(ActionTypes.SAVE_CHANNEL_FAIL, { message, status });

export const clearChannelDetails = () => action(ActionTypes.CLEAR_CHANNEL_DETAILS);

export const setPreselected = (channels: ChannelListItem[]) => action(ActionTypes.SET_PRESELECTED, { channels });

export const openChannelFormBlade = (parentBladeId: string, props: ChannelFormContainerProps) => openBlade<ChannelFormContainerProps>(
  parentBladeId,
  ChannelFormBladeName,
  props
)