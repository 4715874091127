import { ChannelListItem } from 'models';
import { StreamFormModel, StreamListItem } from 'models/streams';
import { openBlade } from 'react-tools';
import { StreamFormContainerProps } from 'stream/form/stream-form.container';
import { action } from 'typesafe-actions';
import { ErrorResponse } from 'utils/http';

import { StreamFormBladeName } from '../form/stream-form.container';
import { ActionTypes } from './types';

export const fetchStreamsStart = (channelId?: number, deviceId?: number) => action(ActionTypes.FETCH_STREAMS_START, { channelId, deviceId });
export const fetchStreamsSuccess = (streams: StreamListItem[]) => action(ActionTypes.FETCH_STREAMS_SUCCESS, { streams });
export const fetchStreamsFail = (error: any) => action(ActionTypes.FETCH_STREAMS_FAIL, { error });

export const fetchStreamStart = () => action(ActionTypes.FETCH_STREAM_START);
export const fetchStreamSuccess = (stream: StreamFormModel) => action(ActionTypes.FETCH_STREAM_SUCCESS, { stream });
export const fetchStreamFail = (error: any) => action(ActionTypes.FETCH_STREAM_FAIL, { error });

export const saveStreamStart = (stream: StreamFormModel) => action(ActionTypes.SAVE_STREAM_START, { stream });
export const saveStreamSuccess = (stream: StreamFormModel, create: boolean) => action(ActionTypes.SAVE_STREAM_SUCCESS, {stream, create});
export const saveStreamFail = ({ message, status }: ErrorResponse) => action(ActionTypes.SAVE_STREAM_FAIL, { message, status });

export const clearStreamDetails = () => action(ActionTypes.CLEAR_STREAM_DETAILS);
export const setPreselected = (streams: StreamListItem[]) => action(ActionTypes.SET_PRESELECTED, { streams });

export const firstChannelStart = (workgroupId: number) => action(ActionTypes.FETCH_FIRST_CHANNEL_START, { workgroupId });
export const firstChannelSuccess = (channel: ChannelListItem) => action(ActionTypes.FETCH_FIRST_CHANNEL_SUCCESS, {channel});
export const firstChannelFail = ({ message, status }: ErrorResponse) => action(ActionTypes.FETCH_FIRST_CHANNEL_FAIL, { message, status });

export const setChannel = (channel: ChannelListItem) => action(ActionTypes.SET_STREAM_CHANNEL, {channel});
export const openStreamForm = (parentBladeId: string, props: StreamFormContainerProps) => openBlade<StreamFormContainerProps>(parentBladeId, StreamFormBladeName, { ...props });