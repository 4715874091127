import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row-reverse"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    stepIconActive: {
      color: `${theme.palette.secondary.main} !important`,
    },
    stepIconComplete: {
      color: `${theme.palette.text.disabled} !important`,
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }
  })
);
