import { IdName } from 'react-tools';

export interface ChannelListItem extends IdName {
    workgroupId: number;
    workgroupName: string;
    channelType: ChannelType;
    isExplicit: boolean;
}

export interface ChannelFormModel extends IdName {
    workgroupId: number;
    workgroupName: string;
    channelType: ChannelType;
    isExplicit: boolean;
    rowVersion: string;
}

export enum ChannelType
{
    Video = 1,
    Music = 2,
    MusicMessages = 3,
    VOD = 4
}