import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '1px',
      overflowY: 'auto',
      height: 0,
      flex: '1 1 auto',

      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        cursor: 'pointer',
        background: '#D4CCC7',
      },

      '&::webkit-scrollbar-track': {
        background: '#9A9791',
        cursor: 'pointer',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#9A9791',
        borderRadius: '5px',
        cursor: 'pointer',

        '&:hover': {
          background: '#9A9791',
        },
      },
    },
    formContent: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    panelContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    expanded: {
      marginTop: `${theme.spacing(1)}px !important`,
      marginBottom: `${theme.spacing(1)}px !important`,
    },
    loadingContainer: {
      flexGrow: 1,
      display: 'flex',
    },
    loading: {
      margin: 'auto',
    },
    inlineFieldsContainer: {
      display: 'flex',
    },
    inlineField: {
      '&:not(:last-child)': {marginRight: theme.spacing(1)},
      flex: 1
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    actionSelectedChannel: {
      display: 'inline-block',
      marginRight: theme.spacing(6),
    },
    actionSelectedChannelValidation: {
      color: theme.palette.error.main
    }
  });

export const useStyles = makeStyles(styles);
