import React from 'react';

import { TreeItem, TreeItemProps } from '@material-ui/lab';

import { useStyles } from './editor.jss';

export const EditorTreeItem: React.FunctionComponent<TreeItemProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <TreeItem
      onLabelClick={(e: React.MouseEvent<HTMLElement>) => {
        const target: any = e.target;
        if (target.nodeName === 'INPUT') {
          e.stopPropagation();
        }
      }}
      {...props}
      classes={{
        root: classes.muiTreeItemRoot,
        label: classes.muiTreeItemLabel,
      }}
    />
  );
};
