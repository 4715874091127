import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5)
    },
    row: {
      marginTop: '4px',
      marginBottom: '4px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    headerRow: {
      width: 680,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '24px',
      marginTop: '12px'
    }
  });

const useStyles = makeStyles(styles);

export const LocationListLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <Skeleton animation={'wave'} variant='text' width={200} />
        <Skeleton animation={'wave'} variant='text' width={120} />
      </div>
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
      <Skeleton animation={'wave'} variant='text' width={680} height={24} className={classes.row} />
    </div>
  );
}