import i18n from 'localization/i18n';

const mapper: any = {
  1: i18n.t('devices.playerType.windows'),
  2: i18n.t('devices.playerType.android'),
  3: i18n.t('devices.playerType.brightsign'),
  4: i18n.t('devices.playerType.ssp'),
  5: i18n.t('devices.playerType.moki'),
};

export const getPlayerTypeTranslation = (playerType: number): string => {
  if (mapper[playerType]) {
    return mapper[playerType];
  } else {
    return '';
  }
};
