import { NavigationList, NavigationListItemModel } from 'components';
import React, { useMemo } from 'react';

import StreamsIcon from '@material-ui/icons/SettingsInputHdmi';

interface ChannelFormProps {
  openChannelStreams: () => void;
}

export const ChannelFormActions = React.memo((props: ChannelFormProps) => {
  const items = useMemo<NavigationListItemModel[]>(
    () => [
      {
        icon: <StreamsIcon/>,
        labelKey: 'channelForm.nav.streams',
        onClick: props.openChannelStreams,
      },
    ],
    [props.openChannelStreams]
  );

  return <NavigationList items={items} />;
});
