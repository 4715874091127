import { DeviceSettingsService } from 'device-settings/duck/service';
import { Entity } from 'device-settings/duck/types';
import { DeviceSettingSaveModel } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Tooltip,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Done from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Alert } from '@material-ui/lab';

import { useStyles } from '../wizard/wizard.jss';
import { DeviceCountConfirmLoader } from './loader';

interface Props {
  entity: Entity;
  open: boolean;
  onClose: () => void;
  confirmApplySettings: (settings: DeviceSettingSaveModel[]) => void;
  settingsForSaving: DeviceSettingSaveModel[];
  settingsNotForSaving: DeviceSettingSaveModel[];
}

const STEP_SUMMARY = 0;
const STEP_COUNT_CHECK = 1;
const STEP_TIMER = 2;
const STEP_DONE = 3;

export const ConfirmDialog = React.memo(({
  entity,
  open,
  onClose,
  confirmApplySettings,
  settingsForSaving,
  settingsNotForSaving,
}: Props) => {
  const [t] = useTranslation();

  const [step, setStep] = useState(0);
  const [canSave, setCanSave] = useState(false);
  const [settings, setSettings] = useState({
    forSaving: settingsForSaving,
    notForSaving: settingsNotForSaving,
  });

  useEffect(() => {
    if (open) {
      setStep(STEP_SUMMARY);
      setCanSave(false);
      setSettings({
        forSaving: settingsForSaving,
        notForSaving: settingsNotForSaving,
      });
    }
  }, [open]);

  const handleDeviceFormStatusChange = useCallback((newStatus: boolean) => {
    setCanSave((previousCanSave) => {
      return previousCanSave !== newStatus ? newStatus : previousCanSave;
    });
  }, []);

  const onNext = useCallback(() => {
    if (step === STEP_SUMMARY) {
      setStep(STEP_COUNT_CHECK);
    }
    if (canSave && step === STEP_COUNT_CHECK) {
      setStep(STEP_TIMER);
    }

    if (step >= STEP_TIMER) {
      onClose();
    }
  }, [canSave, step]);

  const confirmSave = useCallback(() => {
    const finalSettings = settings.forSaving;
    setTimeout(() => {
      setStep(STEP_DONE);
      confirmApplySettings(finalSettings);
    }, 0);
  }, [settings]);

  const moveSetting = useCallback(
    (settingKey: string, from: SettingList, to: SettingList) => {
      const newSettings = { ...settings };
      const fromList =
        from === SettingList.forSaving
          ? newSettings.forSaving
          : newSettings.notForSaving;
      const toList =
        to === SettingList.forSaving
          ? newSettings.forSaving
          : newSettings.notForSaving;

      const fromIndex = fromList.findIndex((s) => s.key === settingKey);
      const settingToMove = fromList.splice(fromIndex, 1);
      if (settingToMove.length > 0) {
        toList.push(settingToMove[0]);
        setSettings(newSettings);
      }
    },
    [settings, setSettings]
  );

  const MemoTimer = useMemo(
    () => <ApplySettingsCancelTimer onComplete={confirmSave} />,
    [confirmSave]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{t('deviceSettings.applyingSettings')}</DialogTitle>

      <DialogContent>
        {step === STEP_SUMMARY && (
          <Summary
            settingsForSaving={settings.forSaving}
            settingsNotForSaving={settings.notForSaving}
            moveSetting={moveSetting}
          />
        )}
        {step === STEP_COUNT_CHECK && (
          <DeviceCountConfirm
            entity={entity}
            settingIds={settingsForSaving.map(x => x.id)}
            onStatusChange={handleDeviceFormStatusChange}
          />
        )}
        {step === STEP_TIMER && MemoTimer}
      </DialogContent>

      <DialogActions>
        {(step === STEP_SUMMARY || step === STEP_COUNT_CHECK) && (
          <Button onClick={onClose}>{t('cancel')}</Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={onNext}
          disabled={
            (step === STEP_SUMMARY && settings.forSaving.length === 0) ||
            (step === STEP_COUNT_CHECK && !canSave)
          }
        >
          {step === STEP_SUMMARY || step === STEP_COUNT_CHECK
            ? t('next')
            : step === STEP_TIMER
            ? t('deviceSettings.cancelChanges')
            : t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const MS = 5000;
const STEPS = 50;

const ApplySettingsCancelTimer = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [elapsed, setElapsed] = React.useState(0);
  const startTimeRef = React.useRef<number>(0);

  useEffect(() => {
    const progress = () => {
      setElapsed((previous) => {
        if (previous >= MS) {
          clearInterval(timer);
          onComplete();
          return MS;
        }

        return +new Date() - startTimeRef.current;
      });
    };

    startTimeRef.current = +new Date();
    const timer = setInterval(progress, MS / STEPS);
    return () => clearInterval(timer);
  }, [onComplete]);

  const secondsRemaining = useMemo(() => Math.ceil((MS - elapsed) / 1000), [
    elapsed,
  ]);
  const completed = useMemo(() => 100 * (elapsed / MS), [elapsed]);

  return (
    <>
      <Typography variant="h6" align="center">
        {completed < 100
          ? t('deviceSettings.youCanStillCancel')
          : t('deviceSettings.saved')}
      </Typography>
      <div className={classes.timerContainer}>
        {completed < 100 && (
          <Typography variant="h3" className={classes.progressText}>
            {secondsRemaining}
          </Typography>
        )}

        {completed >= 100 && <Done className={classes.completedIcon} />}

        <CircularProgress
          className={classes.progress}
          variant="static"
          value={completed}
          color="secondary"
          size={120}
        />
      </div>
    </>
  );
};

const service = new DeviceSettingsService();

interface DeviceCountConfirmProps {
  entity: Entity;
  settingIds: number[];
  onStatusChange: (newStatus: boolean) => void;
}

const DeviceCountConfirm = ({
  entity,
  settingIds,
  onStatusChange,
}: DeviceCountConfirmProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await service.fetchAffectedDevices(
        entity,
        settingIds
      );
      setCount(response);
      setFetching(false);
    };

    fetchData();
  }, []);

  const handleCountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value);
      onStatusChange(value === count);
    },
    [count]
  );

  return (
    <>
      {fetching && <DeviceCountConfirmLoader />}
      {!fetching && (
        <>
          <Alert severity="warning">
            {t('deviceSettings.playerCountReadme', { count })}
            <br />
          </Alert>
          <DialogContentText className={classes.countInputContainer}>
            {t('deviceSettings.playerCountDialogInfo')}
          </DialogContentText>
          <TextField
            className={classes.countInput}
            size="small"
            color="secondary"
            type="number"
            autoFocus
            label={t('deviceSettings.playerCountLabel')}
            fullWidth
            onChange={handleCountChange}
          />
          {/* </div> */}
        </>
      )}
    </>
  );
};

enum SettingList {
  forSaving,
  notForSaving,
}

interface SummaryProps {
  settingsForSaving: DeviceSettingSaveModel[];
  settingsNotForSaving: DeviceSettingSaveModel[];
  moveSetting: (settingKey: string, from: SettingList, to: SettingList) => void;
}

const Summary: React.FunctionComponent<SummaryProps> = (props) => {
  const { settingsForSaving, settingsNotForSaving, moveSetting } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const removeSetting = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const settingKey = e.currentTarget.name;
      moveSetting(settingKey, SettingList.forSaving, SettingList.notForSaving);
    },
    [settingsForSaving, moveSetting]
  );

  const addSetting = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const settingKey = e.currentTarget.name;
      moveSetting(settingKey, SettingList.notForSaving, SettingList.forSaving);
    },
    [settingsForSaving, moveSetting]
  );

  return (
    <div>
      <Alert severity="info">
        {t('deviceSettings.summarySettingsForSavingPart1')}
        {
          <RemoveIcon
            className={classes.confirmDialogSummaryAlertIcon}
            fontSize="small"
          />
        }
        {t('deviceSettings.summarySettingsForSavingPart2')}
      </Alert>
      <List>
        {settingsForSaving.map((s) => (
          <ListItem key={s.key}>
            <ListItemText primary={t(s.key)} />
            <ListItemSecondaryAction>
              <Tooltip title={t<string>('deviceSettings.summaryRemoveSetting')}>
                <IconButton
                  edge="end"
                  aria-label="remove"
                  onClick={removeSetting}
                  name={s.key}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {/* <Divider className={classes.confirmDialogSummaryDivider} /> */}
      <Alert severity="warning">
        {t('deviceSettings.summarySettingsNotForSavingPart1')}
        {
          <AddIcon
            className={classes.confirmDialogSummaryAlertIcon}
            fontSize="small"
          />
        }
        {t('deviceSettings.summarySettingsNotForSavingPart2')}
      </Alert>
      <List>
        {settingsNotForSaving.map((s) => (
          <ListItem key={s.key}>
            <ListItemText primary={t(s.key)} />
            <ListItemSecondaryAction>
              <Tooltip title={t<string>('deviceSettings.summaryAddSetting')}>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={addSetting}
                  name={s.key}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
