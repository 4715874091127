import { JSONObjectSchema } from 'device-settings/duck/json-schema-types';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
    TextField
} from '@material-ui/core';

export interface KeyEditorDialogProps {
    propKey?: string;
    isOpen: boolean;
    onCancel: () => void;
    onSubmit: (
      oldKey: string | undefined,
      newKey: string,
      matchedPatternProp: string
    ) => void;
    schema: JSONObjectSchema;
  }
  
export const KeyEditorDialog: React.FC<KeyEditorDialogProps> = React.memo((props) => {
    const { propKey } = props;
    const [t] = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
  
    const onSave = useCallback(() => {
      const patternProps = props.schema.patternProperties;
      const newPropKey = inputRef.current?.value;
      if (newPropKey && patternProps) {
        const matchedPatternProp = Object.keys(patternProps).find((k) => {
          var regex = new RegExp(k);
          return regex.test(newPropKey);
        });
        if (matchedPatternProp)
          {
            props.onSubmit(propKey, newPropKey, matchedPatternProp);
          }
      }
    }, [inputRef.current, propKey]);
  
    return (
      <Dialog open={props.isOpen}>
        <DialogTitle>
          {propKey
            ? t('settingsEditor.dialog.editKey')
            : t('settingsEditor.dialog.addKey')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>
          <TextField
            autoFocus
            defaultValue={propKey}
            margin="dense"
            id="key"
            inputRef={inputRef}
            label={t('settingsEditor.propertyKey')}
            fullWidth
            inputProps={{maxLength: 255}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={onSave} color="secondary" variant='contained'>
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
