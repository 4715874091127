import { Entity } from 'device-settings/duck/types';
import { EntityType } from 'models';
import { useEffect } from 'react';
import { IdName } from 'react-tools';

interface Props {
  location: IdName | undefined;
  device: IdName | undefined;
  workgroup: IdName | undefined;
  parentEntity: Entity | null;
  fetchDevicesSettings: (entity: Entity, parentEntity: Entity | null) => void;
}
const entity = (entityId: number, entityType: EntityType): Entity => ({
  entityId,
  entityType,
});

export const useFetchDeviceSettings = ({
  device,
  location,
  workgroup,
  parentEntity,
  fetchDevicesSettings,
}: Props) => {
  useEffect(() => {
    if (location) {
      fetchDevicesSettings(entity(location.id, EntityType.Site), parentEntity);
    }
    if (workgroup) {
      fetchDevicesSettings(
        entity(workgroup.id, EntityType.Workgroup),
        parentEntity
      );
    }

    if (device) {
      fetchDevicesSettings(
        entity(device.id, EntityType.Hardware),
        parentEntity
      );
    }
  }, [location, workgroup, device]);
};
