import React from 'react';
import { ColumnSettings } from 'react-tools';
import { getPlayerTypeTranslation } from 'utils/translation';

import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';

const playerTypeFormatter = (params: ValueFormatterParams) => getPlayerTypeTranslation(params.value)
export const devicesColumnSettings: Array<ColumnSettings> = [
  {
    name: "id",
    width: 140,
    sort: { order: 0, direction: "asc" },
    filter: 'agTextColumnFilter',
    hide: true,
  },

  {
    name: "name",
    width: 170,
    filter: 'agTextColumnFilter',
    lockVisibility: true,
  },
  {
    name: "serial",
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: "type",
    width: 180,
    valueFormatter: playerTypeFormatter,
    filter: 'agSetColumnFilter',
    customFloatingFilterParams: {
      valueFormatter: playerTypeFormatter,
    },
  },
  {
    name: "salesOrderNo",
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: 'locationName',
    width: 140,
    filter: 'agTextColumnFilter'
  },
  {
    name: 'workgroupName',
    width: 140,
    filter: 'agTextColumnFilter'
  }
];
