import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: "100%",
      width: "100%"
    },
    statusRow: {
      alignItems: 'center',
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      backgroundColor: "white",
      borderBottom: "solid 1px #e2e2e2",
    },
    countContainer: {
      marginLeft: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '160px'
    },
    loadingContainer: {
      flexGrow: 1,
      display: "flex",
    },
    loading: {
      margin: "auto",
    },
    quickSearch: {
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '260px'
    }
  });

export const useStyles = makeStyles(styles);
