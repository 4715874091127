import { createSelector } from 'reselect';

import { areaName, DeviceState } from './types';

const selectDeviceState = (state: any): DeviceState => state[areaName] as DeviceState;

export const selectDevices = createSelector([selectDeviceState], (state: DeviceState) => state.devices);

export const selectIsFetchingDevices = createSelector([selectDeviceState], (state: DeviceState) => state.isFetching);

export const selectDevice = createSelector([selectDeviceState], (state: DeviceState) => state.details.model);

export const selectIsFetchingDevice = createSelector([selectDeviceState], (state: DeviceState) => state.details.isFetching);

export const selectIsSavingDevice = createSelector([selectDeviceState], (state: DeviceState) => state.details.isSaving);

export const selectPreselected = createSelector([selectDeviceState], (state: DeviceState) => state.preselected);

export const selectSaveErrorStatusCode = createSelector([selectDeviceState], (state: DeviceState) => state.details.errorStatusCode);