import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: "100%",
      width: "100%",
      "& *": {
        "&::-webkit-scrollbar": {
          background: '#fff'
        }
      }
    },
    checkboxRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0.5),
      backgroundColor: "white",
      borderBottom: "solid 1px #e2e2e2",
    },
    countContainer: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loadingContainer: {
      flexGrow: 1,
      display: "flex",
    },
    loading: {
      margin: "auto",
    },
  });

export const useStyles = makeStyles(styles);
