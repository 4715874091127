import { useMemo } from 'react';
import { DeviceSettingModel } from 'models';
import i18n from 'localization/i18n';

const mapDeviceSettingRiskLevel = (setting: DeviceSettingModel) => ({
  ...setting,
  riskLevelLabel: i18n.t(`settingsEditor.riskLevel${setting.riskLevel}`).toString()
});

export const useMapDeviceSettings = (deviceSettings: DeviceSettingModel[]) =>
  useMemo(() => deviceSettings
    .map(mapDeviceSettingRiskLevel),
    [deviceSettings]
  )

export const useFilterCriticalDeviceSettings = (deviceSettings: DeviceSettingModel[], criticalVisible: boolean) =>
  useMemo(() => deviceSettings
    .filter(e => criticalVisible ? true : e.riskLevel !== 0),
    [deviceSettings, criticalVisible]
  )