import { ChannelListItem } from 'models';
import { StreamFormModel, StreamListItem } from 'models/streams';
import { IdName } from 'react-tools';

export const areaName = "stream";

export interface StreamState {
  streams: StreamListItem[];
  isFetching: boolean;

  details: {
    isSaving: boolean;
    isFetching: boolean;
    errorStatusCode: number;

    isFetchingFirstChannel: boolean;

    selectedChannel: IdName;
    model: StreamFormModel | null;
  };

  preselected: StreamListItem[];
}

export enum ActionTypes {
  FETCH_STREAMS_START = "FETCH_STREAMS_START",
  FETCH_STREAMS_SUCCESS = "FETCH_STREAMS_SUCCESS",
  FETCH_STREAMS_FAIL = "FETCH_STREAMS_FAIL",

  FETCH_STREAM_START = "FETCH_STREAM_START",
  FETCH_STREAM_SUCCESS = "FETCH_STREAM_SUCCESS",
  FETCH_STREAM_FAIL = "FETCH_STREAM_FAIL",

  FETCH_STREAM_LOCATIONS_START = "FETCH_STREAM_LOCATIONS_START",
  FETCH_STREAM_LOCATIONS_SUCCESS = "FETCH_STREAM_LOCATIONS_SUCCESS",
  FETCH_STREAM_LOCATIONS_FAIL = "FETCH_STREAM_LOCATIONS_FAIL",

  SAVE_STREAM_START = 'SAVE_STREAM_START',
  SAVE_STREAM_SUCCESS = 'SAVE_STREAM_SUCCESS',
  SAVE_STREAM_FAIL = 'SAVE_STREAM_FAIL',

  CLEAR_STREAM_DETAILS = 'CLEAR_STREAM_DETAILS',
  SET_PRESELECTED = 'SET_PRESELECTED',

  FETCH_FIRST_CHANNEL_START = 'FETCH_FIRST_CHANNEL_START',
  FETCH_FIRST_CHANNEL_SUCCESS = 'FETCH_FIRST_CHANNEL_SUCCESS',
  FETCH_FIRST_CHANNEL_FAIL = 'FETCH_FIRST_CHANNEL_FAIL',

  SET_STREAM_CHANNEL = 'SET_STREAM_CHANNEL',
}