import { ChannelService } from 'channel/duck/service';
import { FastField, FastFieldProps } from 'formik';
import { ChannelFormModel } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormCard } from 'react-tools';

import { Grid, TextField, TextFieldProps, Typography } from '@material-ui/core';

import { ChannelFormActions } from './channel-form.actions';
import { useStyles } from './channel-form.jss';

interface Props {
  handleChange: (event: React.ChangeEvent) => void;
  openChannelStreams: () => void;
  values: ChannelFormModel;
  showActions: boolean;
}
const channelService = new ChannelService();

export const ChannelFormFields = ({
  handleChange,
  values,
  openChannelStreams,
  showActions,
}: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.formContent}>
      <FormCard marginBottom>
        <Grid container spacing={2} classes={{ root: classes.panelContainer }}>
          {values.id !== 0 && (
            <Grid item>
              <TextField
                label={t('channels.id')}
                variant="filled"
                fullWidth
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
                value={values.id}
              />
            </Grid>
          )}
          <Grid item>
            <FastField name="name">
              {({ form }: FastFieldProps<ChannelFormModel>) => (
                <TextField
                  label={t('channels.name')}
                  variant="filled"
                  fullWidth
                  error={form.errors.name ? true : false}
                  helperText={form.errors.name}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 50 }}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              )}
            </FastField>
          </Grid>
          <Grid item>
            <TextField
              label={t('channels.channelType')}
              variant="filled"
              fullWidth
              disabled
              InputProps={{
                disableUnderline: true,
              }}
              value={channelService.channelTypeToString(values.channelType)}
            />
          </Grid>
        </Grid>
      </FormCard>
      {showActions && (
        <FormCard padding={-2}>
          <ChannelFormActions openChannelStreams={openChannelStreams} />
        </FormCard>
      )}
    </div>
  );
};
