import i18n from 'localization/i18n';
import { ThunkTypeAction, ThunkTypeDispatch, WorkgroupLocation } from 'models';
import { batch } from 'react-redux';
import { IdName, pushNotification, updateBladeProps } from 'react-tools';
import { ConcurrencyError, ErrorResponse } from 'utils/http';

import { LocationFormBladeName } from '../form/location-form.container';
import * as actions from './actions';
import { LocationService } from './service';

const service = new LocationService();

export const fetchLocationsAsync = (
  workgroupId: number
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchLocationsStart(workgroupId));

    try {
      const locations: WorkgroupLocation[] = await service.getLocations(
        workgroupId
      );
      dispatch(actions.fetchLocationsSuccess(locations));
    } catch (err) {
      dispatch(actions.fetchLocationFail(err));
    }
  };
};
export const fetchLocationAsync = (
  locationId: number
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchLocationStart(locationId));

    try {
      const location = await service.getLocation(locationId);
      dispatch(actions.fetchLocationSuccess(location));
    } catch (err) {
      dispatch(actions.fetchLocationFail(err));
    }
  };
};

export const saveLocationAsync = (
  bladeId: string,
  location: WorkgroupLocation,
  workgroup: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    const sameWorkgroup = workgroup.id === location.workgroupId;
    const isCreate = location.id === 0;

    dispatch(actions.saveLocationStart(location));

    try {
      const savedLocation = { ...location };
      const fn = isCreate ? service.saveLocation : service.updateLocation;
      const { siteId, siteVersion } = await fn(location);
      savedLocation.id = siteId;
      savedLocation.rowVersion = siteVersion;

      batch(() => {
        dispatch(
          actions.saveLocationSuccess(savedLocation, isCreate, sameWorkgroup)
        );
        dispatch(actions.setPreselected([savedLocation]));
        dispatch(
          updateBladeProps(bladeId, LocationFormBladeName, {
            workgroup,
            locationName: savedLocation.name,
            locationId: savedLocation.id,
          })
        );
      });
    } catch (err) {
      dispatch(catchSaveError(err));
    }
  };
};

export const catchSaveError = (
  error: ConcurrencyError | ErrorResponse
): ThunkTypeAction<void> => {
  return (dispatch: ThunkTypeDispatch) => {
    if (error instanceof ConcurrencyError) {
      dispatch(actions.saveLocationFail(error));
      dispatch(pushNotification(i18n.t('locationForm.concurrency'), 'error'));
    } else {
      dispatch(actions.saveLocationFail(error));
      dispatch(pushNotification(error.message, 'error'));
    }
  };
};
