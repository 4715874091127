import React from 'react';

import { TreeItem, NormalizedTreeItemActions } from 'react-tools';
import { WorkgroupTreeActionNames } from 'workgroup/duck/types';
import Add from "@material-ui/icons/Add";
import Star from "@material-ui/icons/Star";
import { IconButton, CircularProgress } from '@material-ui/core';
import { useCallback } from 'react';

export const useMarkWorkgroupAsFavorite = (favoriteWorkgroups: number[], toggleFavorite: (id: number, newState: boolean) => void) => {
  const fn = useCallback(
    (item: TreeItem) => {
      return (event: React.MouseEvent) => {
        event.stopPropagation();

        const isFavorite = favoriteWorkgroups.find((e) => e === item.modelId);
        toggleFavorite(item.modelId, !isFavorite);
      };
    },
    [favoriteWorkgroups]
  );

  return fn;
}

export const useOnAddWorkgroupClicked = (openWorkgroupNewBlade: (parentId: number) => void) => {
  const fn = useCallback((item: TreeItem) => {
    return (event: React.MouseEvent) => {
      event.stopPropagation();
      openWorkgroupNewBlade(item.modelId);
    };
  }, []);
  return fn;
}

export const useWorkgroupTreeItemActions = (
  treeActionState: NormalizedTreeItemActions,
  favoriteWorkgroups: number[],
  onFavoriteClicked: (id: number, newState: boolean) => void,
  openAddWorkgroupBlade: (parentId: number) => void
) => {
  const markWorkgroupAsFavorite = useMarkWorkgroupAsFavorite(favoriteWorkgroups, onFavoriteClicked);
  const onAddWorkgroup = useOnAddWorkgroupClicked(openAddWorkgroupBlade);

  return [
    {
      content: (item: TreeItem, selected: boolean) => {
        const actionState = treeActionState[item.treeId];
        const toggleFavoriteState = actionState ? actionState[WorkgroupTreeActionNames.ToggleFavorite] : null;
        const inProgress = toggleFavoriteState ? toggleFavoriteState.inProgress : false;
        const isFavorite = !!favoriteWorkgroups.find((e) => e === item.modelId);
        const visible = inProgress || isFavorite;

        const component = (showOnlyOnHoverClassName: string) => {
          return (
            <IconButton key="Star" size="small" className={showOnlyOnHoverClassName} onClick={markWorkgroupAsFavorite(item)}>
              {inProgress ? <CircularProgress size={24} color={"inherit"} /> : <Star />}
            </IconButton>
          );
        };

        return { component, visible };
      },
      name: WorkgroupTreeActionNames.ToggleFavorite,
      visible: true,
    },
    {
      content: (item: TreeItem, selected: boolean) => ({
        component: (showOnlyOnHoverClassName: string) => (
          <IconButton key="Add" size="small" className={showOnlyOnHoverClassName} onClick={onAddWorkgroup(item)}>
            <Add />
          </IconButton>
        ),
        visible: selected,
      }),
      name: "Add",
      visible: true,
    },
  ];
};