import { useState, useCallback, useMemo } from 'react';

export interface IWorkgroupDirty {
  isDirty: boolean;
  setTemplatesDirty: (dirty: boolean) => void;
  setFieldsDirty: (dirty: boolean) => void;
  clearDirty: () => void;
}

export const useWorkgroupDirty = (): IWorkgroupDirty => {
  const [templatesDirty, setTemplatesDirty] = useState(false);
  const [fieldsDirty, setFieldsDirty] = useState(false);

  const isDirty = useMemo(() => fieldsDirty || templatesDirty, [fieldsDirty, templatesDirty]);

  const clearDirty = useCallback(() => {
    setTemplatesDirty(false);
    setFieldsDirty(false);
  }, []);

  return {
    isDirty,
    setFieldsDirty,
    setTemplatesDirty,
    clearDirty
  }
}