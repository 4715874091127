import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: "100%",
      width: "100%",
      "& *": {
        "&::-webkit-scrollbar": {
          background: '#fff'
        }
      }
    },

    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "1px",
      overflowY: "auto",
      height: 0,
      flex: '1 1 auto',
      padding: theme.spacing(2),

      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        cursor: "pointer",
        background: "#D4CCC7",
      },

      "&::webkit-scrollbar-track": {
        background: "#9A9791",
        cursor: "pointer",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#9A9791",
        borderRadius: "5px",
        cursor: "pointer",

        "&:hover": {
          background: "#9A9791",
        },
      },

    },
    errorMessage: {
      color: theme.palette.error.main
    },
    workgroupNameInput: {
      marginBottom: theme.spacing(1)
    },

    workgroupLogoContainer: {
      display: "flex",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& > img": {
        height: theme.spacing(6)
      }
    },
    workgroupLogoSummary: {
      flexGrow: 2,
      marginLeft: theme.spacing(2),
      "& > p:first-child": {
        fontWeight: 600
      }
    },

    templateContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& > p:first-child": {
        fontWeight: 600
      },
      "& > span:nth-child(2)": {
        fontStyle: "italic"
      },
      "& > button": {
        alignSelf: "flex-end"
      }
    },

    companyExpander: {
      marginTop: '0px !important',
      '&::before': {
        opacity: '0 !important'
      }
    },
    companySummaryExpanded: {
      paddingTop: 0,
      paddingBottom: 0
    },
    companyDetails: {
      flexDirection: "column",
      paddingTop: 0
    },
    tabPanel: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    },
    tabContent: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1)
    },
    inlineFields: {
      display: 'flex'
    },
    inlineFieldEqual: {
      '&:first-child': {
        flexGrow: 1,
        marginRight: theme.spacing(0.5),
      },
      '&:nth-child(2)': {
        flexGrow: 1,
        marginLeft: theme.spacing(0.5),
      }
    },
    inlineField: {
      '&:first-child': {
        flexGrow: 1,
        marginRight: theme.spacing(1),
      },
      '&:nth-child(2)': {
        width: '160px'
      }
    },
    countriesOptions: {
      "& *": {
        "&::-webkit-scrollbar": {
          background: "#fff"
        },
      }
    },

    loadingContainer: {
      flexGrow: 1,
      display: "flex",
    },
    loading: {
      margin: "auto",
    },
    templateSpan: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }
    }
  });

export const useStyles = makeStyles(styles, {
  index: 1
});

