import classNames from 'classnames';
import { JSONNumberSchema, JSONStringSchema } from 'device-settings/duck/json-schema-types';
import { Field, FieldInputProps, FieldProps, useField } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdName } from 'react-tools';

import { Input, MenuItem, Paper, Select, TextField, Tooltip } from '@material-ui/core';

import { useStyles } from './editor.jss';
import { EditorTreeItem } from './editorTreeItem';
import { ErrorTooltip } from './errorTooltip';
import { TreeItemLabel } from './treeItemLabel';
import { SchemaEditorProps } from './types';

export type TextFieldEditorValues = Array<string | number | IdName>;
export interface TextFieldEditorProps {
  label: string;
  labelChildren?: JSX.Element;
  propKey: string;
  schema: JSONStringSchema | JSONNumberSchema;
}

const defaultMaxNumber = 2147483647;
const defaultMaxLength = 2000;

const generateOptions = (
  schema: JSONStringSchema | JSONNumberSchema
): Array<JSX.Element> => {
  const options =
    schema.type === 'integer' || schema.type === 'number'
      ? schema.options
      : undefined;
  if (options)
    return options.map((o) => (
      <MenuItem key={o.id} value={o.id}>
        {o.name}
      </MenuItem>
    ));
  if (schema.enum) {
    return schema.enum.map((e) => (
      <MenuItem key={e} value={e}>
        {e}
      </MenuItem>
    ));
  }
  return [];
};

const MemoTextFieldEditor: React.FunctionComponent<
  SchemaEditorProps<JSONStringSchema | JSONNumberSchema> &
    FieldInputProps<string | number> & { error?: string }
> = React.memo(
  (props) => {
    const {
      label,
      schema,
      propKey,
      value,
      onChange,
      onBlur,
      name,
      labelChildren,
      error,
    } = props;
    const classes = useStyles();
    return (
      <EditorTreeItem
        nodeId={propKey}
        label={
          <TreeItemLabel
            label={label ? label : propKey}
            tooltip={schema.description}
            classes={{label: classes.fixedItemLabel}}
          >
            {schema.enum ? (
              <Select
                variant="filled"
                className={classes.textField}
                classes={{ root: classes.selectRoot }}
                disableUnderline
                name={name}
                value={value}
                onChange={onChange}
                readOnly={props.readOnly}
              >
                {generateOptions(schema)}
              </Select>
            ) : (
              <ErrorTooltip arrow={true} title={error ? error : ''}>
                <TextField
                  size="small"
                  variant="filled"
                  className={classNames(classes.textField, {
                    [classes.green]: schema.type === 'string',
                    [classes.red]:
                      schema.type === 'number' || schema.type === 'integer',
                  })}
                  autoComplete="new-password"
                  type={schema.type === 'string' ? 'text' : 'number'}
                  value={value !== undefined ? value : ""}
                  name={name}
                  error={error !== undefined}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputProps={{
                    disableUnderline: !error,
                    readOnly: props.readOnly,
                  }}
                  inputProps={{
                    max: defaultMaxNumber,
                    maxLength: defaultMaxLength
                  }} 
                />
              </ErrorTooltip>
            )}
            {labelChildren}
          </TreeItemLabel>
        }
      />
    );
  },
  (p, n) =>
    {
      return p.value === n.value && p.error === n.error && p.readOnly === n.readOnly && p.labelChildren === n.labelChildren;
    }
);

export const TextFieldEditor: React.FunctionComponent<SchemaEditorProps<
  JSONStringSchema | JSONNumberSchema
>> = (props) => {
  const { schema, propKey, required } = props;
  const [t] = useTranslation();

  const validate = useCallback(
    (value: string | number | undefined | null) => {
      if (schema.enum) {
        return undefined;
      }
      if (value === undefined && !required) {
        return undefined;
      }

      if (value === undefined && required) {
        return t('settingsEditor.validation.required');
      }

      switch (schema.type) {
        case 'integer':
        case 'number': {
          const nv = value as number;
          if (schema.exclusiveMinimum !== undefined && nv <= schema.exclusiveMinimum)
            return t('settingsEditor.validation.greaterThan', {
              value: schema.exclusiveMinimum,
            });
          if (schema.exclusiveMaximum !== undefined && nv >= schema.exclusiveMaximum)
            return t('settingsEditor.validation.lessThan', {
              value: schema.exclusiveMaximum,
            });
          if (schema.minimum !== undefined && nv < schema.minimum)
            return t('settingsEditor.validation.greaterOrEqualThan', {
              value: schema.minimum,
            });
          if (schema.maximum !== undefined && nv > schema.maximum)
            return t('settingsEditor.validation.lessOrEqualThan', {
              value: schema.maximum,
            });
          if(nv > defaultMaxNumber) {
            return t('settingsEditor.validation.lessOrEqualThan', {
              value: defaultMaxNumber,
            });
          }
          break;
        }
        case 'string': {
          const sv = value as string;
          if (schema.pattern) {
            const regex = new RegExp(schema.pattern);
            const regexMatched = regex.test(sv);
            if (!regexMatched) return t('settingsEditor.validation.pattern');
          }
          if (schema.minLength && sv.length < schema.minLength)
            return t('settingsEditor.validation.longerThan', {
              value: schema.minLength,
            });
          if (schema.maxLength && sv.length > schema.maxLength)
            return t('settingsEditor.validation.shorterThan', {
              value: schema.maxLength,
            });
            if(sv.length > defaultMaxLength) {
              return t('settingsEditor.validation.shorterThan', {
                value: defaultMaxLength,
              });
            }
          break;
        }
      }
      return undefined;
    },
    [schema]
  );
  const [field, meta] = useField({ name: propKey, validate });
  return <MemoTextFieldEditor {...props} {...field} error={meta.error} />;
};
