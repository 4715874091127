import React from 'react';

import ContentLoader from 'react-content-loader';

export const TreeContentLoader = () => (
  <ContentLoader
    speed={2}
    width={320}
    height={360}
    backgroundColor="#404040"
    animate={true}
    foregroundColor="#606060"
  >
    <rect x="40" y="14" rx="3" ry="3" width="150" height="12" />
    <rect x="56" y="54" rx="3" ry="3" width="120" height="12" />
    <rect x="56" y="94" rx="3" ry="3" width="160" height="12" />
    <rect x="56" y="134" rx="3" ry="3" width="130" height="12" />
    <rect x="56" y="174" rx="3" ry="3" width="160" height="12" />

    <rect x="72" y="214" rx="3" ry="3" width="190" height="12" />
    <rect x="72" y="254" rx="3" ry="3" width="170" height="12" />
    <rect x="72" y="294" rx="3" ry="3" width="140" height="12" />
    <rect x="56" y="334" rx="3" ry="3" width="130" height="12" />
    <rect x="56" y="374" rx="3" ry="3" width="160" height="12" />
  </ContentLoader>
)