import React from 'react';
import { BladeInstance } from 'react-tools';
import { moodThemeDark } from 'themes.jss';

import {
    AppBar, Breadcrumbs, IconButton, Link, MuiThemeProvider, Toolbar
} from '@material-ui/core';
import Home from '@material-ui/icons/Home';

import { useStyles } from './breadcrumbs.jss';

export interface AdminAppBreadcrumbProps {
  exitApp: () => void;
  setBladeFocus: (bladeId: string) => void;
  activeBlades: BladeInstance[];
}

export const AppBreadcrumbs = (props: AdminAppBreadcrumbProps) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={moodThemeDark}>
      <AppBar position="static">
        <Toolbar variant='dense' classes={{gutters: classes.toolbarGutters}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="textPrimary" onClick={props.exitApp} className={classes.link}>
              <IconButton className={classes.homeButton}>
                <Home />
              </IconButton>
            </Link>

            <Link className={props.activeBlades.length === 0 ? classes.linkActive : classes.link}>
              Workgroups
            </Link>

            {props.activeBlades.map((blade) => (
              <Link
                key={blade.id}
                className={`${classes.link} ${blade.focused ? classes.linkActive : ""}`}
                onClick={() => props.setBladeFocus(blade.id)}>
                {blade.title}
              </Link>
            ))}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};
