import { Form, Formik } from 'formik';
import { WorkgroupLocation } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import {
  BladeProps, ButtonItem, Footer, FooterSubmit, IdName, useBladeButtons, useBladeClosing
} from 'react-tools';

import Refresh from '@material-ui/icons/Refresh';

import { LocationFormListLoader } from './loader';
import { LocationFormContainerProps } from './location-form.container';
import { useStyles } from './location-form.jss';
import { LocationValidationSchema } from './validation.schema';
import { LocationFormFields } from './location-form.fields';

const getEmptyLocation = (workgroup: IdName): WorkgroupLocation => ({
  address: '',
  city: '',
  country: '',
  zipCode: '',
  phoneNumber: '',
  name: '',
  oracleNumber: '',
  state: '',
  timezone: '',
  id: 0,
  isExplicit: false,
  workgroupId: workgroup.id,
  workgroupName: workgroup.name,
  clientSiteId: '',
});

export interface LocationFormProps extends LocationFormContainerProps {
  bladeId: string;
  location: WorkgroupLocation | null;
  isFetchingLocation: boolean;
  isSavingLocation: boolean;
  savingErrorCode: number;
  closeBlade: () => void;
  onClose: () => void;
  fetchLocation: (locationId: number) => void;
  saveLocation: (location: WorkgroupLocation) => void;
  openLocationDevices: (locationId: number) => void;
  openLocationDeviceSettings: (location: IdName) => void;
}

export const LocationForm = (props: LocationFormProps & BladeProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [location, setLocation] = useState(
    props.location ? props.location : getEmptyLocation(props.workgroup)
  );

  const buttons: ButtonItem[] = useMemo<ButtonItem[]>(
    () => [
      {
        disabled: props.savingErrorCode !== 409,
        icon: () => <Refresh />,
        onClick: () => props.fetchLocation(props.locationId as number),
        tooltip: t('locationForm.refresh'),
      },
    ],
    [props.savingErrorCode, props.locationId]
  );

  useBladeButtons(buttons, [props.savingErrorCode, props.locationId]);
  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.onClose()
  );

  useEffect(() => {
    if (props.locationId) {
      props.fetchLocation(props.locationId);
    } else {
      setLocation(getEmptyLocation(props.workgroup));
    }
  }, [props.locationId, props.workgroup.id]);

  useEffect(() => {
    if (props.location) {
      setLocation(props.location);
    }
  }, [props.location]);

  const openLocationDevices = useCallback(() => {
    if (props.locationId) {
      props.openLocationDevices(props.locationId);
    }
  }, [props.locationId]);

  const onFormChanged = useCallback(
    (dirty: boolean) => () => props.setDirty(dirty),
    [props.setDirty]
  );

  const openLocationDeviceSettings = useCallback(() => {
    if (props.location) {
      props.openLocationDeviceSettings({
        id: props.location.id,
        name: props.location.name
      });
    }
  }, [props.location]);


  const submitForm = useCallback(
    (values: WorkgroupLocation) => {
      values.isExplicit = props.workgroup.id === values.workgroupId;
      props.saveLocation(values);
      props.setDirty(false);
    },
    [props.setDirty, props.saveLocation]
  );

  if (props.isFetchingLocation) {
    return <LocationFormListLoader />;
  }

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={location}
          enableReinitialize={true}
          onSubmit={submitForm}
          validationSchema={LocationValidationSchema}
          validateOnChange
        >
          {({ submitForm, values, handleChange, dirty }) => (
            <Form onBlur={onFormChanged(dirty)} className={classes.form}>
              <LocationFormFields
                showActions={props.locationId !== 0}
                handleChange={handleChange}
                openLocationDeviceSettings={openLocationDeviceSettings}
                openLocationDevices={openLocationDevices}
                values={values}
              />
              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t('cancel')}
                  cancel={props.closeBlade}
                  submitButtonLabel={props.location ? t('save') : t('create')}
                  submitInProgress={props.isSavingLocation}
                  submit={submitForm}
                />
              </Footer>
            </Form>
          )}
        </Formik>
      </FadeIn>
    </div>
  );
};
