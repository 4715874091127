import { Entity } from 'device-settings/duck/types';
import {
  DeviceSettingsBladeName, DeviceSettingsContainerProps
} from 'device-settings/list/device-settings.container';
import { selectors, thunks } from 'device/duck';
import i18n from 'localization/i18n';
import { DeviceFormModel } from 'models/devices';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions, BladeConfig, BladeProps, IdName, openBlade, withRegisterBlade
} from 'react-tools';
import { StreamsBladeName } from 'stream/list/streams.container';

import { DeviceForm } from './device-form';

export const DeviceFormBladeName = 'DEVICE_FORM_BLADE_NAME';

export interface DeviceFormContainerProps {
  deviceId?: number;
  deviceName?: string;
  workgroup: IdName;
  location?: IdName;
}

const bladeConfig: BladeConfig<DeviceFormContainerProps> = {
  bladeType: DeviceFormBladeName,
  size: { defaultWidth: 460, minWidth: 400 },
  title: (props: DeviceFormContainerProps) =>
    props.deviceName ? props.deviceName : i18n.t('deviceForm.createTitle'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  deviceId,
  deviceName,
  location,
  workgroup,
  ...bladeProps
}: DeviceFormContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const isSaving = useSelector(selectors.selectIsSavingDevice);
  const isFetching = useSelector(selectors.selectIsFetchingDevice);
  const device = useSelector(selectors.selectDevice);
  const savingErrorCode = useSelector(selectors.selectSaveErrorStatusCode);

  const fetchDevice = useCallback(
    (deviceId: number) => dispatch(thunks.fetchDeviceAsync(deviceId)),
    [dispatch]
  );
  const saveDevice = useCallback(
    (device: DeviceFormModel, workgroup: IdName, location?: IdName) =>
      dispatch(
        thunks.saveDeviceAsync(bladeProps.bladeId, device, workgroup, location)
      ),
    [dispatch]
  );
  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(bladeProps.bladeId)),
    [bladeProps.bladeId]
  );

  const openDeviceSettings = useCallback(
    (device: IdName, parentEntity: Entity) => dispatch(
      BladeActions.closeChildrenBlades(bladeProps.bladeId,
        openBlade<DeviceSettingsContainerProps>(
          bladeProps.bladeId,
          DeviceSettingsBladeName,
          {
            device,
            parentEntity,
          }
        ))
    ),
    []
  );

  const openStreams = useCallback(
    (device: IdName) => dispatch(
      BladeActions.closeChildrenBlades(bladeProps.bladeId,
        openBlade(bladeProps.bladeId, StreamsBladeName, {
          device, workgroup
        })
      )
    ),
    []
  );

  return (
    <DeviceForm
      {...bladeProps}
      workgroup={workgroup}
      location={location}
      deviceId={deviceId}
      fetchDevice={fetchDevice}
      saveDevice={saveDevice}
      device={device}
      openDeviceSettings={openDeviceSettings}
      isSaving={isSaving}
      isFetching={isFetching}
      closeBlade={closeBlade}
      savingErrorCode={savingErrorCode}
      openStreams={openStreams}
    />
  );
};

export const LocationFormContainer = withRegisterBlade<
  DeviceFormContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);
