import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fadeIn: {
      height: '100%',
      '& > div:first-child': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }
    }
  })
);