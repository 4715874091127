import { JSONSchema, JSONSchemaWithMeta } from 'device-settings/duck/json-schema-types';
import { overrideKey } from 'device-settings/duck/utils';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, IconButton, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Backspace from '@material-ui/icons/Backspace';
import Lock from '@material-ui/icons/Lock';
import Redo from '@material-ui/icons/Redo';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import TreeView from '@material-ui/lab/TreeView';

import { useStyles } from './editor.jss';
import { SettingItem } from './settingItem';

export interface SettingValueEditorProps {
  settingId: number;
  propKey: string;
  settingLabel: string;
  schema: JSONSchemaWithMeta;
  value: any;
  parentValue: any | undefined;
  readOnly: boolean;
  riskLevel: number;
  override: boolean;
  nodeIds: string[];
  openUnlockDialog: (setting: string, saveCallback: () => void) => void;
  removeSelf: (settingId: number) => void;
}

export const SettingValueEditor: React.FunctionComponent<SettingValueEditorProps> = React.memo(
  (props) => {
    const { settingId, schema, override, readOnly, riskLevel } = props;
    const [t] = useTranslation();
    const { setFieldValue, getFieldProps, values } = useFormikContext<any>();
    const classes = useStyles();

    const itemSchema: JSONSchema = schema as JSONSchema;

    const [isOverride, setOverride] = useState(override);
    const onOverrideClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      const newOverride = !isOverride;
      // use value from either parent(inherited) or from own overriden value
      setFieldValue(
        props.propKey,
        newOverride ? props.value : props.parentValue
      );
      setFieldValue(overrideKey(props.propKey), !newOverride);
      setOverride(newOverride);
    };

    const lockNeeded = !readOnly && riskLevel === 0;
    const [locked, setLocked] = useState(() => lockNeeded);
    const onUnlockClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        props.openUnlockDialog(props.propKey, onUnlock);
      },
      [locked, setLocked]
    );
    const onUnlock = useCallback(() => setLocked(false), [setLocked]);
    const onRemoveClick = useCallback(() => props.removeSelf(settingId), [
      settingId,
      props.removeSelf,
    ]);
    const showLock = lockNeeded && locked;
    const showOverride = props.override && !locked;
    return (
      <TreeView
        disableSelection={true}
        defaultExpanded={props.nodeIds}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div className={classes.treeItemDefaultEndIcon} />}
      >
        <SettingItem
          propKey={props.propKey}
          label={props.settingLabel}
          schema={itemSchema}
          value={values[props.propKey]}
          level={0}
          readOnly={props.readOnly || locked || (props.override && !isOverride)}
          labelChildren={
            <>
              {showLock && (
                <Tooltip title={t('settingsEditor.unlock').toString()}>
                  <IconButton
                    size="small"
                    onClick={onUnlockClick}
                    color="secondary"
                  >
                    <Lock />
                  </IconButton>
                </Tooltip>
              )}
              {showOverride && (
                <Tooltip
                  title={
                    isOverride
                      ? t<string>('deviceSettings.deleteOverride')
                      : t<string>('deviceSettings.returnToOverride')
                  }
                >
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={onOverrideClick}
                  >
                    {isOverride ? <Backspace /> : <Redo />}
                  </IconButton>
                </Tooltip>
              )}
              
              {/* <Tooltip title={t<string>('deviceSettings.removeSetting')}> This button has been temporary removed since it's buggy
                <IconButton size="small" onClick={onRemoveClick}>
                  <RemoveIcon className={classes.treeItemLabelTooltipIcon} />
                </IconButton>
              </Tooltip> */}
            </>
          }
        />
      </TreeView>
    );
  }
);
