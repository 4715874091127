import { apiFetch, currentWorkgroupKey, getCookieValue, fetchHelper } from "../../ajax";
import { WorkgroupDetailsMode } from "./types";
import { TemplateLibrary, WorkgroupDetailsModel, Workgroup } from 'models';

export interface IWorkgroupService {
  getWorkgroups: (workgroupId: number) => Promise<Workgroup[]>;
  getWorkgroupTemplates: (workgroupId: number) => Promise<number[]>;
  getTemplates: () => Promise<TemplateLibrary[]>;
  saveWorkgroup: (workgroup: WorkgroupDetailsModel, mode: WorkgroupDetailsMode) => Promise<any>;
  getWorkgroup: (workgroupId: number) => Promise<WorkgroupDetailsModel>;
}

export class WorkgroupService implements IWorkgroupService {

  async getWorkgroups(workgroupId: number = Number(getCookieValue(currentWorkgroupKey))): Promise<Workgroup[]> {
    if ((process.env.REACT_APP_HEADER_01 as string) === "MVision-Current-WorkgroupId") {
      // this will run when the app is called in mvision-html scope
      return (await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/${workgroupId}/tree`)).json();
    } else {
      return (await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/1/tree`)).json();
    }
  }

  async getWorkgroupTemplates(workgroupId: number = Number(getCookieValue(currentWorkgroupKey))): Promise<number[]> {
    return (await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/templateLibraries/${workgroupId}`)).json();
  }

  async getTemplates(): Promise<TemplateLibrary[]> {
    return (await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/templateLibraries`)).json();
  }

  getWorkgroup = async (workgroupId: number): Promise<WorkgroupDetailsModel> => {
    let response;

    if ((process.env.REACT_APP_HEADER_01 as string) === "MVision-Current-WorkgroupId") {
      response = await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/${workgroupId}`);
    } else {
      response = await apiFetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/${workgroupId}`);
    }

    return response.json();
  };

  saveWorkgroup = async (workgroup: WorkgroupDetailsModel, mode: WorkgroupDetailsMode): Promise<any> => {
    let response;

    if (mode === WorkgroupDetailsMode.NEW) {
      response = await fetchHelper(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup`, 'POST', workgroup);
    } else if (mode === WorkgroupDetailsMode.EDIT) {
      response = await fetchHelper(`${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup`, "PUT", workgroup);
    }

    return response?.json();
  }
} 
