import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
    TextField, Typography
} from '@material-ui/core';

import { useStyles } from '../wizard/wizard.jss';

interface Props {
  open: boolean;
  onClose: () => void;
  save: () => void;
}

const getRandom = () => Math.floor(Math.random() * 10);

export const CriticalSettingUnlockDialog = ({ open, onClose, save }: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [answer, setAnswer] = useState(0);
  const [checked, setChecked] = useState(false);

  const [term1, setTerm1] = useState(0);
  const [term2, setTerm2] = useState(0);

  useEffect(() => {
    setChecked(false);
    if (open) {
      setTerm1(getRandom());
      setTerm2(getRandom());
    }
  }, [open]);
  const correctAnswer = term1 + term2;

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setAnswer(parseFloat(event.target.value)),
    []
  );

  const handleCheckboxChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setChecked(checked),
    []
  );

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.centerText}>{t('warning!')}</DialogTitle>
      <DialogContent>
        <div className={classes.dangerZoneWarningContainer}>
          <Typography
            className={classes.dangerZoneWarningContent}
            variant="body1"
            align="center"
          >
            {t('deviceSettings.dangerZoneWarning')}
          </Typography>
        </div>
        <Typography variant="body1" align="center">
          {t('deviceSettings.dangerZoneReadMe')}
        </Typography>

        <br />

        <div className={classes.dangerZoneMathContainer}>
          <Typography variant="body1" align="center">
            {t('deviceSettings.dangerZoneMath')}
          </Typography>

          <div className={classes.dangerZoneInputContainer}>
            <Typography className={classes.dangerZoneMath} variant="h5">
              {term1} + {term2} ={' '}
            </Typography>
            <TextField
              autoFocus={true}
              className={classes.dangerZoneInput}
              size="small"
              type="number"
              variant="filled"
              inputProps={{ min: '0', step: '1' }}
              onChange={handleFieldChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          classes={{ root: classes.critDialogCheckRoot }}
          control={
            <Checkbox checked={checked} onChange={handleCheckboxChange} />
          }
          label={t('deviceSettings.dangerZoneCheckbox')}
        />
        <div className={classes.spacer} />
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!checked || answer !== correctAnswer}
          onClick={save}
        >
          {t('proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
