import { WorkgroupLocation } from 'models';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem, useGridExportEvents } from 'react-tools';

import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';

export const useLocationListBladeButtons = (
  fetchLocations: () => void,
  createLocation: () => void,
  editLocation: (workgroup: WorkgroupLocation) => void,
  selected: WorkgroupLocation[],
  loading: boolean,
  gridApi: any
) => {
  const editLocationCallback = useCallback(() => editLocation(selected[0] as WorkgroupLocation), [selected]);
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(() => [
    {
      tooltip: 'workgroupLocations.refresh',
      icon: () => <Refresh />,
      onClick: fetchLocations,
      disabled: loading
    },
    {
      tooltip: 'workgroupLocations.create',
      icon: () => <Add />,
      onClick: createLocation
    },
    {
      tooltip: 'workgroupLocations.edit',
      icon: () => <Edit />,
      disabled: selected === undefined || selected.length === 0 || selected.length > 1,
      onClick: editLocationCallback
    },
    {
      tooltip: 'workgroupLocations.export',
      icon: () => <CloudDownload />,
      onClick: () => {
        if (exportFunctions && exportFunctions.exportCsv) {
          exportFunctions.exportCsv(t('workgroupLocations.exportFileName').toString());
        }
      }
    }
  ], [selected, loading, gridApi]);

  return buttons;
};