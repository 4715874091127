import React, { useEffect, useRef, useState } from "react";

import { Paper } from "@material-ui/core";
import Draggable, { DraggableEventHandler } from "react-draggable";

import { WorkgroupTreeContainer } from "../workgroup/tree/workgroup-tree.container";
import { useStyles } from "./siderbar.jss";

const MIN_PANEL_WIDTH = 320;
const MAX_PANEL_WIDTH = 620;

export const AppSidebar = () => {
  const classes = useStyles();

  const [width, setWidth] = useState(MIN_PANEL_WIDTH);

  const innerBladeDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (innerBladeDivRef.current) {
      setWidth(Math.max(MIN_PANEL_WIDTH, innerBladeDivRef.current.offsetWidth));
    }
  }, [innerBladeDivRef]);

  const resizeDragStopped: DraggableEventHandler = (e, data) => {
    const newXOffset = width + data.x;
    const newWidthWithMinCheck = Math.max(MIN_PANEL_WIDTH, newXOffset);
    const newWidth = Math.min(newWidthWithMinCheck, MAX_PANEL_WIDTH);
    setWidth(newWidth);
  };

  return (
    <Paper square className={classes.paper} style={width !== 0 ? { width: `${width}px`, minWidth: `${width}px` } : undefined}>
      <div ref={innerBladeDivRef} className={classes.draggableContainer}>
        <WorkgroupTreeContainer />
      </div>

      <Draggable
        axis="x"
        defaultClassName={classes.dragHandle}
        defaultClassNameDragging={width >= MIN_PANEL_WIDTH ? classes.dragHandleActive : classes.dragHandleActiveLessThanMin}
        position={{ x: 0, y: 0 }}
        onStop={resizeDragStopped}>
        <div className={classes.noSelect} />
      </Draggable>
    </Paper>
  );
};
