import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ChevronRight';

import { useStyles } from './FormListItem.jss';

export interface NavigationListItemModel {
  labelKey: string;
  subtitle?: string | React.ReactNode;
  onClick: () => void;
  hideDivider?: boolean;
  icon?: JSX.Element;
}

const NavigationListItem: React.FunctionComponent<NavigationListItemModel> = (
  props
) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem className={classes.button} button onClick={props.onClick}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText
          primary={t(props.labelKey)}
          secondary={props.subtitle}
        />
        <ListItemSecondaryAction className={classes.buttonIcon}>
          <ArrowRightIcon />
        </ListItemSecondaryAction>
      </ListItem>
      {!props.hideDivider && <Divider />}
    </React.Fragment>
  );
};

export const NavigationList: React.FunctionComponent<{
  items: NavigationListItemModel[];
}> = ({ items }) => {
  const classes = useStyles();

  return (
    <List
      className={classes.list}
      classes={{ padding: classes.muiListPadding }}
    >
      {items.map((item, index) => (
        <NavigationListItem
          key={item.labelKey}
          {...item}
          hideDivider={index === items.length - 1}
        />
      ))}
    </List>
  );
};
