import { apiFetch, fetchHelper } from 'ajax';
import { ChannelListItem, ChannelType, WorkgroupLocation } from 'models';
import { StreamFormModel, StreamListItem } from 'models/streams';
import { IdName } from 'react-tools';
import HttpService from 'utils/http';
import { getPlayerTypeTranslation } from 'utils/translation';

export interface IStreamService {
  getStreams(workgroupId: number): Promise<StreamListItem[]>;
  getStream(streamId: number): Promise<StreamFormModel>;
  saveStream(stream: StreamFormModel): Promise<StreamFormModel>;
  updateStream(stream: StreamFormModel): Promise<StreamFormModel>;
  getFirstChannelInWorkgroup(workgroupId: number, channelType: ChannelType): Promise<ChannelListItem>;
}

export class StreamService implements IStreamService {
  streamFormModelToStreamListItem(
    streamFormModel: StreamFormModel
  ): StreamListItem {
    const listItem: StreamListItem = {
      id: streamFormModel.id,
      name: streamFormModel.name,
      audioOutputType: streamFormModel.audioOutputType,
      channelId: streamFormModel.channelId,
      channelName: streamFormModel.channelName,
      hardwareId: streamFormModel.hardwareId,
      hardwareName: streamFormModel.hardwareName,
      x: streamFormModel.x,
      y: streamFormModel.y,
      width: streamFormModel.width,
      height: streamFormModel.height
    };
    return listItem;
  }

  async getStreams(
    channelId?: number,
    deviceId?: number
  ): Promise<StreamListItem[]> {
    if (channelId) {
      return HttpService.get<StreamListItem[]>(`v6/adminApp/stream/list/channel/${channelId}`);
    }
    return HttpService.get<StreamListItem[]>(`v6/adminApp/stream/list/device/${deviceId}`);
  }

  async getStream(streamId: number): Promise<StreamFormModel> {
    const response = await HttpService.getWithVersion<StreamFormModel>(
      `v6/adminApp/stream/${streamId}`
    );
    return { ...response.data, rowVersion: response.version };
  }

  async saveStream(stream: StreamFormModel): Promise<StreamFormModel> {
    const response = await HttpService.postWithReturnedVersion<number>('v6/adminApp/stream', stream);
    return {...stream, id: response.data, rowVersion: response.version};
  }

  async updateStream(stream: StreamFormModel): Promise<StreamFormModel> {
    const response = await HttpService.putWithVersion<number>(
      `v6/adminApp/stream`,
      { ...stream, rowVersion: undefined },
      stream.rowVersion
    );

    return {...stream, rowVersion: response.version};
  }

  async getFirstChannelInWorkgroup(
    workgroupId: number,
    channelType: ChannelType
  ): Promise<ChannelListItem> {
    return await HttpService.get(`v6/adminApp/channel/first/${workgroupId}/${channelType.valueOf()}`);
  }
}
