import { apiFetch, fetchHelper } from 'ajax';
import { WorkgroupLocation } from 'models';
import { DeleteDevicesDto, DeviceFormModel, DeviceListItem, DeviceResponseModel, DeviceSaveResultCode, DeviceSaveResultType } from 'models/devices';
import { IdName } from 'react-tools';
import HttpService from 'utils/http';
import { getPlayerTypeTranslation } from 'utils/translation';

export interface IDeviceService {
  getDevices(workgroupId: number): Promise<DeviceListItem[]>;
}

export class DeviceService implements IDeviceService {
  deviceFormModelToDeviceListItem(
    deviceFormModel: DeviceFormModel,
    isExplicit: boolean
  ): DeviceListItem {
    const listItem: DeviceListItem = {
      workgroupId: deviceFormModel.workgroupId,
      id: deviceFormModel.id,
      isExplicit,
      locationName: deviceFormModel.locationName,
      workgroupName: deviceFormModel.workgroupName,
      locationId: deviceFormModel.locationId,
      name: deviceFormModel.name,
      salesOrderNo: deviceFormModel.salesOrderNumber,
      serial: deviceFormModel.serialNumber,
      type: deviceFormModel.deviceTypeId.toString(),
    };
    return listItem;
  }

  async getDevices(
    workgroupId: number,
    locationId?: number
  ): Promise<DeviceListItem[]> {
    if (locationId) {
      return (
        await apiFetch(
          `${process.env.REACT_APP_ROOT_DOMAIN}v6/adminApp/location/${locationId}/devices`
        )
      ).json();
    }
    return (
      await apiFetch(
        `${process.env.REACT_APP_ROOT_DOMAIN}v6/admin/workgroup/${workgroupId}/devices`
      )
    ).json();
  }

  async getDevice(deviceId: number): Promise<DeviceFormModel> {
    const response = await HttpService.getWithVersion<DeviceFormModel>(
      `v6/adminApp/device/${deviceId}`
    );
    return { ...response.data, rowVersion: response.version };
  }

  async getAvailableLocations(
    workgroupId: number,
    abort: AbortSignal
  ): Promise<IdName[]> {
    return (
      await apiFetch(
        `${process.env.REACT_APP_ROOT_DOMAIN}v6/adminApp/location/simple-list/${workgroupId}`,
        {},
        abort
      )
    ).json();
  }

  async saveDevice(device: DeviceFormModel): Promise<DeviceResponseModel> {
    return HttpService.post('v6/adminApp/device', device);
  }

  async updateDevice(device: DeviceFormModel): Promise<DeviceResponseModel> {
    const response = await HttpService.putWithVersion<DeviceResponseModel>(
      `v6/adminApp/device/${device.id}`,
      { ...device, rowVersion: undefined },
      device.rowVersion as string
    );

    return { device: response.data.device, saveResultCode: response.data.saveResultCode, saveResultType: response.data.saveResultType};
  }

  async deleteDevices(deviceIds: DeleteDevicesDto): Promise<boolean> {
    const response = await HttpService.delete<boolean>( 'v6/adminApp/device/', deviceIds);

    return response;
  }
}
