import { NormalizedIdNames } from 'models';
import { Workgroup, TemplateLibrary, WorkgroupDetailsModel } from 'models';

export const areaName = "workgroup";

export interface WorkgroupState {
  favoriteWorkgroups: number[];

  workgroups: NormalizedIdNames<Workgroup>;
  templates: TemplateLibrary[];

  isFetchingWorkgroups: boolean;
  isFetchingTemplates: boolean,

  isSavingWorkgroupLocation: boolean;

  workgroupDetails: {
    isSaving: boolean;
    isFetching: boolean;
    saved: boolean;
    model: WorkgroupDetailsModel | null;
    templates: TemplateLibrary[];
  };
}

export enum WorkgroupDetailsTabs {
  COMPANY,
  ADDRESS,
  CONTACT
}
export enum WorkgroupDetailsMode {
  NEW,
  EDIT
}

export interface WorkgroupCreateObject {
  ParentId: number;
  BrandId: number;
  Name: string;
  LogoPath: string;
  TemplateLibraries: number[];
}

export enum WorkgroupTreeActionNames {
  ToggleFavorite = "ToggleFavorite",
}

export enum ActionTypes {
  WORKGROUP_FAVORITE_REQUEST = "WORKGROUP_FAVORITE_REQUEST",
  WORKGROUP_FAVORITE_SUCCESS = "WORKGROUP_FAVORITE_SUCCESS",
  WORKGROUP_FAVORITE_ERROR = "WORKGROUP_FAVORITE_ERROR",

  WORKGROUP_UNFAVORITE_REQUEST = "WORKGROUP_UNFAVORITE_REQUEST",
  WORKGROUP_UNFAVORITE_SUCCESS = "WORKGROUP_UNFAVORITE_SUCCESS",
  WORKGROUP_UNFAVORITE_ERROR = "WORKGROUP_UNFAVORITE_ERROR",

  FETCH_WORKGROUPS_START = "FETCH_WORKGROUPS_START",
  FETCH_WORKGROUPS_SUCCESS = "FETCH_WORKGROUPS_SUCCESS",
  FETCH_WORKGROUPS_FAIL = "FETCH_WORKGROUPS_FAIL",

  FETCH_WORKGROUP_START = "FETCH_WORKGROUP_START",
  FETCH_WORKGROUP_SUCCESS = "FETCH_WORKGROUP_SUCCESS",
  FETCH_WORKGROUP_FAIL = "FETCH_WORKGROUP_FAIL",

  SAVE_WORKGROUP_START = "SAVE_WORKGROUP_START",
  SAVE_WORKGROUP_SUCCESS = "SAVE_WORKGROUP_SUCCESS",
  SAVE_WORKGROUP_FAIL = "SAVE_WORKGROUP_FAIL",

  SET_WORKGROUP_TEMPLATES = "SET_WORKGROUP_TEMPLATES",

  CLEAR_WORKGROUP_DETAILS = "CLEAR_WORKGROUP_DETAILS",

  FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START',
  FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL',

  FETCH_WORKGROUP_TEMPLATES_START = 'FETCH_WORKGROUP_TEMPLATES_START',
  FETCH_WORKGROUP_TEMPLATES_SUCCESS = 'FETCH_WORKGROUP_TEMPLATES_SUCCESS',
  FETCH_WORKGROUP_TEMPLATES_FAIL = 'FETCH_WORKGROUP_TEMPLATES_FAIL',
}
