import { ChannelFormModel, ChannelListItem } from 'models';

export const areaName = "channel";

export interface ChannelState {
  channels: ChannelListItem[];
  isFetching: boolean;

  details: {
    isSaving: boolean;
    isFetching: boolean;
    errorStatusCode: number;
    model: ChannelFormModel | null;
  };

  preselected: ChannelListItem[];
}

export enum ActionTypes {
  FETCH_CHANNELS_START = "FETCH_CHANNELS_START",
  FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS",
  FETCH_CHANNELS_FAIL = "FETCH_CHANNELS_FAIL",

  FETCH_CHANNEL_START = "FETCH_CHANNEL_START",
  FETCH_CHANNEL_SUCCESS = "FETCH_CHANNEL_SUCCESS",
  FETCH_CHANNEL_FAIL = "FETCH_CHANNEL_FAIL",

  CLEAR_CHANNEL_DETAILS = 'CLEAR_CHANNEL_DETAILS',

  SAVE_CHANNEL_START = "SAVE_CHANNEL_START",
  SAVE_CHANNEL_SUCCESS = "SAVE_CHANNEL_SUCCESS",
  SAVE_CHANNEL_FAIL = "SAVE_CHANNEL_FAIL",

  SET_PRESELECTED = 'SET_PRESELECTED'
}