import { DependencyList, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BladeActions, IdName, openBlade } from 'react-tools';

export const useOpenChildBlade = (bladeId: string, childToOpenBladeName: string, props: any, deps: DependencyList) => {
  const dispatch = useDispatch();

  const openAction = useCallback((workgroup: IdName) => dispatch(
    BladeActions.closeChildrenBlades(bladeId,
      openBlade(
        bladeId,
        childToOpenBladeName,
        { workgroup, ...props }
      ))), [bladeId, ...deps]);

  return openAction
}