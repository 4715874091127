import classNames from 'classnames';
import { JSONArraySchema, JSONSchema } from 'device-settings/duck/json-schema-types';
import { FieldArray } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, IconButton, Tooltip, Typography } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import Copy from '@material-ui/icons/FileCopy';

import { createObject } from '../duck/utils';
import { useStyles } from './editor.jss';
import { EditorTreeItem } from './editorTreeItem';
import { SettingItem } from './settingItem';
import { TreeItemLabel } from './treeItemLabel';
import { SchemaEditorProps } from './types';

export const ArrayFieldEditor: React.FunctionComponent<SchemaEditorProps<
  JSONArraySchema
>> = React.memo((props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <FieldArray
      name={props.propKey}
      render={(arrayHelpers) => (
        <EditorTreeItem
          nodeId={props.propKey}
          label={
            <TreeItemLabel label={props.label ? props.label : props.propKey}>
              <div className={classes.spacer}/>
              <div className={classes.arrayItemHeaderContainer}>
                <Typography
                  variant="caption"
                  className={classes.arrayItemHeaderCountLabel}
                >{`${
                  arrayHelpers.form.getFieldProps(props.propKey).value?.length
                } items`}</Typography>
                <Tooltip title={t('settingsEditor.button.addItem').toString()}>
                  <IconButton
                    disabled={props.readOnly}
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      arrayHelpers.push(createObject(props.schema.items));
                      e.stopPropagation();
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </div>
              {props.labelChildren}
            </TreeItemLabel>
          }
        >
          {arrayHelpers.form
            .getFieldProps(props.propKey)
            .value?.map((item: any, index: number) => (
              <SettingItem
                readOnly={props.readOnly}
                key={index}
                schema={props.schema.items}
                propKey={`${props.propKey}[${index}]`}
                value={item}
                label={(index + 1).toString()}
                labelChildren={
                  <>
                    <Tooltip
                      title={t('settingsEditor.button.removeItem').toString()}
                    >
                      <IconButton
                        disabled={props.readOnly}
                        size="small"
                        className={classNames(classes.onHoverOnly)}
                        onClick={(e) => {
                          e.stopPropagation();
                          arrayHelpers.remove(index);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={t('settingsEditor.button.cloneItem').toString()}
                    >
                      <IconButton
                        size="small"
                        disabled={props.readOnly}
                        className={classNames(classes.onHoverOnly)}
                        onClick={(e) => {
                          e.stopPropagation();
                          const currentValue = arrayHelpers.form.getFieldProps(
                            props.propKey
                          ).value[index];
                          const clone = JSON.parse(
                            JSON.stringify(currentValue)
                          );
                          arrayHelpers.push(clone);
                        }}
                      >
                        <Copy />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            ))}
        </EditorTreeItem>
      )}
    />
  );
});
