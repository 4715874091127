import { ChannelType } from 'models';
import { StreamListItem } from 'models/streams';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { StreamService } from './service';
import { ActionTypes, StreamState } from './types';

type StreamActions = ActionType<typeof Actions>;
const service = new StreamService();

const initialState: StreamState = {
  streams: [],
  isFetching: false,

  details: {
    errorStatusCode: 0,
    isFetching: false,
    isSaving: false,
    isFetchingFirstChannel: false,
    selectedChannel: {
      id: 0,
      name: '',
    },

    model: null,
  },

  preselected: [],
};

export const streamReducer: Reducer<StreamState, StreamActions> = (
  state: StreamState = initialState,
  action: StreamActions
): StreamState => {
  switch (action.type) {
    case ActionTypes.FETCH_STREAMS_START:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.FETCH_STREAMS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        streams: action.payload.streams,
      };
    }

    case ActionTypes.FETCH_STREAMS_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case ActionTypes.FETCH_STREAM_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          errorStatusCode: 0,
        },
      };
    }

    case ActionTypes.FETCH_STREAM_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          model: action.payload.stream,
          selectedChannel: {
            id: action.payload.stream.channelId,
            name: action.payload.stream.channelName,
          },
        },
      };
    }

    case ActionTypes.FETCH_STREAM_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
      };
    }

    case ActionTypes.SAVE_STREAM_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isSaving: true,
        },
      };
    }

    case ActionTypes.SAVE_STREAM_SUCCESS: {
      const streams: StreamListItem[] = [...state.streams];
      const listItem = service.streamFormModelToStreamListItem(
        action.payload.stream
      );

      if (action.payload.create) {
        streams._insert(listItem, 0);
      } else {
        const index = state.streams.findIndex(
          (d) => d.id === action.payload.stream.id
        );
        streams.splice(index, 1, listItem);
      }
      return {
        ...state,
        streams,
        details: {
          ...state.details,
          model: action.payload.stream,
          isSaving: false,
        },
      };
    }

    case ActionTypes.SAVE_STREAM_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isSaving: false,
          errorStatusCode: action.payload.status,
        },
      };
    }

    case ActionTypes.CLEAR_STREAM_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          model: null,
        },
      };
    }

    case ActionTypes.SET_PRESELECTED: {
      return {
        ...state,
        preselected: action.payload.streams,
      };
    }

    case ActionTypes.FETCH_FIRST_CHANNEL_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetchingFirstChannel: true,
        },
      };
    }

    case ActionTypes.FETCH_FIRST_CHANNEL_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          selectedChannel: action.payload.channel,
          isFetchingFirstChannel: false,
        },
      };
    }

    case ActionTypes.FETCH_FIRST_CHANNEL_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetchingFirstChannel: false,
        },
      };
    }

    case ActionTypes.SET_STREAM_CHANNEL: {
      return {
        ...state,
        details: {
          ...state.details,
          selectedChannel: action.payload.channel,
          isFetchingFirstChannel: false,
        },
      };
    }

    default:
      return state;
  }
};
