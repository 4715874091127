import deepEqual from 'deep-equal';
import { Entity } from 'device-settings/duck/types';
import { overrideKeyPrefix } from 'device-settings/duck/utils';
import { SettingsEditor } from 'device-settings/editor/editor';
import { SettingColumnRenderer } from 'device-settings/list/column-formatters';
import { FormikBag, FormikHelpers, FormikState } from 'formik';
import { DeviceSettingModel, DeviceSettingSaveModel, EntityType } from 'models';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BladeProps, DataGridSelectionType, Footer, TreeGrid, useBladeButtons, useBladeClosing
} from 'react-tools';

import { ConfirmDialog } from '../dialogs/confirm-dialog';
import { useCriticalSettingsVisible, useFilterCriticalDeviceSettings } from '../list/hooks';
import { useDeviceSettingsBladeButtons } from './buttons.hook';
import { WizardSettingsColumnSettings } from './column.settings';
import { EditorDirtyDialog } from './dirty.dialog';
import { SettingsStepper } from './settings-stepper';
import { SettingsStep } from './settings-stepper/settings-stepper';
import { useStyles } from './wizard.jss';

const getItemPath = (item: any) => item.path;
const rowSelectable = (params: any) => params.node.group === false;

interface WizardProps {
  entitySettings: DeviceSettingModel[];
  parentEntitySettings: DeviceSettingModel[] | null;
  entity: Entity;
  parentEntity: Entity | null;
  isSavingSettings: boolean;
  saved: boolean;
  closeBlade: () => void;
  saveDeviceSettings: (
    entity: Entity,
    parentEntity: Entity | null,
    settings: DeviceSettingSaveModel[]
  ) => void;
  clearSavedFlag: () => void;
}

const steps = [SettingsStep.SelectSettings, SettingsStep.SetValues];

const mapSettingsForSave = (
  initialSettings: DeviceSettingModel[],
  settings: {
    [key: string]: any;
  }
): {
  toBeSaved: DeviceSettingSaveModel[];
  notSaved: DeviceSettingSaveModel[];
} => {
  const overrides: { [key: string]: boolean } | undefined =
    settings[overrideKeyPrefix];
  if (settings) {
    return Object.keys(settings).reduce<{
      toBeSaved: DeviceSettingSaveModel[];
      notSaved: DeviceSettingSaveModel[];
    }>(
      (accumulator, settingKey: string) => {
        if (settingKey === overrideKeyPrefix) return accumulator; // the overrides key is only used to keep track of overrides and not part of the final result
        
        let key = settingKey.replace(/--/g, '.');
        const revertValue = overrides
          ? overrides[settingKey]
            ? true
            : false
          : false;
        const value = settings[settingKey];
        let id = initialSettings.find(x => x.key === key)!.id;
        // ignore setting value if it's not different from the initial value
        const initialSetting = initialSettings.find((s) => s.key === key);
        if (
          !revertValue &&
          initialSetting &&
          deepEqual(value, initialSetting.value, { strict: true })
        ) {
          accumulator.notSaved.push({ id, key, value, revertValue });
          return accumulator;
        }

        accumulator.toBeSaved.push({
          id,
          key,
          value,
          revertValue,
        });
        return accumulator;
      },
      { toBeSaved: [], notSaved: [] }
    );
  }

  return { toBeSaved: [], notSaved: [] };
};

const shouldSetDefaultSettings = (
  stored: DeviceSettingModel[],
  incomming: DeviceSettingModel[]
): boolean => {
  if (stored.length !== incomming.length) {
    return true;
  }

  return (
    incomming.filter((e) => stored.filter((f) => f.id === e.id).length === 0)
      .length > 0
  );
};

const checkAndSetDefaultSettingfs = (
  stored: DeviceSettingModel[],
  incomming: DeviceSettingModel[],
  setDefaultSettings: React.Dispatch<React.SetStateAction<DeviceSettingModel[]>>
) => {
  if (shouldSetDefaultSettings(stored, incomming)) {
    setDefaultSettings(incomming);
  }
};

const wizardSteps = [SettingsStep.SelectSettings, SettingsStep.SetValues];

export const Wizard = (props: WizardProps & BladeProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [dirtyDialogOpen, setDirtyDialogOpen] = useState(false);

  const [dirtySettings, setDirtySettings] = useState([] as string[]);

  const onConfirmBack = useCallback(() => {
    setDirtyDialogOpen(false);
    props.setDirty(false);
    setActiveStepIndex(0);
  }, []);

  const onCancelBack = useCallback(() => {
    setDirtyDialogOpen(false);
  }, []);

  const [selectedSettings, setSelectedSettings] = useState<
    DeviceSettingModel[]
  >([]);
  const [defaultSettings, setDefaultSettings] = useState<DeviceSettingModel[]>(
    []
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState<{
    open: boolean;
    toBeSaved: DeviceSettingSaveModel[];
    notSaved: DeviceSettingSaveModel[];
  }>({ open: false, toBeSaved: [], notSaved: [] });

  useEffect(() => {
    if (props.saved) {
      setActiveStepIndex(0);
      setShowConfirmDialog({ open: false, toBeSaved: [], notSaved: [] });
      props.clearSavedFlag();
    }
  }, [props.saved]);

  const handleNext = useCallback(() => {
    if (activeStepIndex === steps.length - 1) {
      formRef.current?.submitForm();
    } else {
      setActiveStepIndex((prevActiveStepIndex) => {
        if (prevActiveStepIndex === steps.length) {
          return prevActiveStepIndex;
        } else {
          return prevActiveStepIndex + 1;
        }
      });
    }

    checkAndSetDefaultSettingfs(
      defaultSettings,
      selectedSettings,
      setDefaultSettings
    );
  }, [activeStepIndex, selectedSettings, defaultSettings]);

  const handleBack = useCallback(() => {
    if (activeStepIndex === 0) {
      props.closeBlade();
    }
    if (activeStepIndex !== 0) {
      if (props.isDirty) {
        setDirtyDialogOpen(true);
      } else {
        setActiveStepIndex(0);
      }
    }
    checkAndSetDefaultSettingfs(
      defaultSettings,
      selectedSettings,
      setDefaultSettings
    );
  }, [activeStepIndex, selectedSettings, props.isDirty, defaultSettings]);

  const formRef = useRef<FormikBag<any, any> & FormikState<any>>();

  const onValuesFormSubmit = (
    values: {
      [key: string]: any;
    },
    formikHelpers: FormikHelpers<{
      [key: string]: any;
    }>
  ) => {
    const settings = mapSettingsForSave(
      props.entitySettings,
      formRef.current?.values
    );
    setShowConfirmDialog({
      open: true,
      toBeSaved: settings.toBeSaved,
      notSaved: settings.notSaved,
    });
  };

  const closeConfirmDialog = useCallback(
    () => setShowConfirmDialog({ ...showConfirmDialog, open: false }),
    []
  );

  const confirmApplySettings = useCallback(
    (settings: DeviceSettingSaveModel[]) => {
      props.setDirty(false);
      props.saveDeviceSettings(props.entity, props.parentEntity, settings);
    },
    []
  );

  const onShowCriticalToggle = useCallback(
    () =>
      checkAndSetDefaultSettingfs(
        defaultSettings,
        selectedSettings,
        setDefaultSettings
      ),
    [selectedSettings, defaultSettings]
  );

  const [criticalVisible, showCritical] = useCriticalSettingsVisible(
    onShowCriticalToggle
  );
  const buttons = useDeviceSettingsBladeButtons(
    criticalVisible,
    steps[activeStepIndex] === SettingsStep.SetValues,
    showCritical
  );

  useBladeButtons(buttons, [buttons]);
  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => {}
  );

  const deviceSettings = useFilterCriticalDeviceSettings(
    props.entitySettings,
    criticalVisible
  );

  const MemoizedTreeGrid = useMemo(
    () => (
      <TreeGrid
        columnSettings={WizardSettingsColumnSettings}
        getRowDataPath={getItemPath}
        items={deviceSettings}
        selectionType={DataGridSelectionType.Multiple}
        identifier={'settingsSelector'}
        groupColumnTitle={t('settingsSelector.setting')}
        rowHeight={36}
        defaultSelection={defaultSettings}
        rowSelectable={rowSelectable}
        onSelect={setSelectedSettings}
        groupColumnRendererComponent="settingsRenderer"
        components={{ settingsRenderer: SettingColumnRenderer }}
        hideHeaderCheckbox={true}
      />
    ),
    [deviceSettings.length, defaultSettings]
  );

  let content = useMemo(() => {
    switch (steps[activeStepIndex]) {
      case SettingsStep.SelectSettings:
        return MemoizedTreeGrid;
      case SettingsStep.SetValues:
        return (
          <SettingsEditor
            entity={props.entity}
            parentEntity={props.parentEntity}
            settings={selectedSettings}
            parentSettings={props.parentEntitySettings}
            ref={formRef}
            onSubmit={onValuesFormSubmit}
            readOnly={false}
            groupSettings={true}
            setDirty={props.setDirty}
            setDirtySettings={setDirtySettings}
          />
        );
    }
  }, [activeStepIndex, MemoizedTreeGrid]);

  return (
    <div className={classes.wizardContaier}>
      <div className={classes.wizardContent}>{content}</div>
      <Footer>
        <SettingsStepper
          steps={wizardSteps}
          loading={props.isSavingSettings}
          activeStepIndex={activeStepIndex}
          onBack={handleBack}
          onNext={handleNext}
          nextDisabled={selectedSettings.length === 0}
        />
      </Footer>
      <ConfirmDialog
        entity={props.entity}
        onClose={closeConfirmDialog}
        open={showConfirmDialog.open}
        confirmApplySettings={confirmApplySettings}
        settingsForSaving={showConfirmDialog.toBeSaved}
        settingsNotForSaving={showConfirmDialog.notSaved}
      />

      <EditorDirtyDialog
        onCancel={onCancelBack}
        onConfirm={onConfirmBack}
        opened={dirtyDialogOpen}
        settings={dirtySettings}
      />
    </div>
  );
};
