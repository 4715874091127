import { Form, Formik } from 'formik';
import { ChannelFormModel, ChannelType } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import {
    BladeProps, ButtonItem, Footer, FooterSubmit, IdName, useBladeButtons, useBladeClosing
} from 'react-tools';

import Refresh from '@material-ui/icons/Refresh';

import { ChannelFormContainerProps } from './channel-form.container';
import { ChannelFormFields } from './channel-form.fields';
import { useStyles } from './channel-form.jss';
import { ChannelFormListLoader } from './loader';
import { ChannelValidationSchema } from './validation.schema';

const getEmptyChannel = (workgroup: IdName, channelType: ChannelType): ChannelFormModel => ({
  id: 0,
  name: '',
  workgroupId: workgroup.id,
  workgroupName: workgroup.name,
  channelType,
  rowVersion: '',
  isExplicit: true
});

export interface ChannelFormProps extends ChannelFormContainerProps {
  bladeId: string;
  channel: ChannelFormModel | null;
  isFetchingChannel: boolean;
  isSavingChannel: boolean;
  savingErrorCode: number;
  closeBlade: () => void;
  onClose: () => void;
  fetchChannel: (channelId: number) => void;
  saveChannel: (channel: ChannelFormModel) => void;
  openChannelStreams: (channelId: number) => void;
}

export const ChannelForm = (props: ChannelFormProps & BladeProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [channel, setChannel] = useState(
    props.channel ? props.channel : getEmptyChannel(props.workgroup, props.channelType)
  );

  const buttons: ButtonItem[] = useMemo<ButtonItem[]>(
    () => [
      {
        disabled: props.savingErrorCode !== 409,
        icon: () => <Refresh />,
        onClick: () => props.fetchChannel(props.channelId as number),
        tooltip: t('channelForm.refresh'),
      },
    ],
    [props.savingErrorCode, props.channelId]
  );

  useBladeButtons(buttons, [props.savingErrorCode, props.channelId]);
  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.onClose()
  );

  useEffect(() => {
    if (props.channelId) {
      props.fetchChannel(props.channelId);
    } else {
      setChannel(getEmptyChannel(props.workgroup, props.channelType));
    }
  }, [props.channelId, props.workgroup.id]);

  useEffect(() => {
    if (props.channel) {
      setChannel(props.channel);
    }
  }, [props.channel]);

  const openChannelStreams = useCallback(() => {
    if (props.channelId) {
      props.openChannelStreams(props.channelId);
    }
  }, [props.channelId]);

  const onFormChanged = useCallback(
    (dirty: boolean) => () => props.setDirty(dirty),
    [props.setDirty]
  );

  const submitForm = useCallback(
    (values: ChannelFormModel) => {
      values.isExplicit = props.workgroup.id === values.workgroupId;
      props.saveChannel(values);
      props.setDirty(false);
    },
    [props.setDirty, props.saveChannel]
  );

  if (props.isFetchingChannel) {
    return <ChannelFormListLoader />;
  }

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={channel}
          enableReinitialize={true}
          onSubmit={submitForm}
          validationSchema={ChannelValidationSchema}
          validateOnChange
        >
          {({ submitForm, values, handleChange, dirty }) => (
            <Form onBlur={onFormChanged(dirty)} className={classes.form}>
              <ChannelFormFields
                showActions={props.channelId !== 0}
                handleChange={handleChange}
                openChannelStreams={openChannelStreams}
                values={values}
              />
              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t('cancel')}
                  cancel={props.closeBlade}
                  submitButtonLabel={props.channel ? t('save') : t('create')}
                  submitInProgress={props.isSavingChannel}
                  submit={submitForm}
                />
              </Footer>
            </Form>
          )}
        </Formik>
      </FadeIn>
    </div>
  );
};
