import { WorkgroupLocation } from 'models';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, LocationState } from './types';

type LocationActions = ActionType<typeof Actions>;

const initialState: LocationState = {
  locations: [],

  isFetching: false,

  details: {
    isFetching: false,
    isSaving: false,
    errorStatusCode: 0,
    model: null
  },

  preselected: []
}

export const locationReducer: Reducer<LocationState, LocationActions> = (state: LocationState = initialState, action: LocationActions): LocationState => {
  switch (action.type) {
    case ActionTypes.FETCH_LOCATIONS_START:
      return {
        ...state,
        isFetching: true
      }

    case ActionTypes.FETCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        locations: action.payload.locations,
      };
    }

    case ActionTypes.FETCH_LOCATIONS_FAIL: {
      return {
        ...state,
        isFetching: false
      }
    }

    case ActionTypes.FETCH_LOCATION_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true
        }
      }
    }

    case ActionTypes.FETCH_LOCATION_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          errorStatusCode: 0,
          model: action.payload.location
        }
      }
    }

    case ActionTypes.FETCH_LOCATION_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false
        }
      }
    }

    case ActionTypes.SAVE_LOCATION_START:
      return {
        ...state,
        details: {
          ...state.details,
          errorStatusCode: 0,
          isSaving: true
        }
      }

    case ActionTypes.SAVE_LOCATION_SUCCESS: {
      const locations: WorkgroupLocation[] = [...state.locations];

      if (action.payload.create) {
        locations._insert(action.payload.location, 0);
      } else {
        const index = locations.findIndex(
          (l) => (l.id === action.payload.location.id)
        );
        locations.splice(index, 1, action.payload.location);
      }
      return {
        ...state,
        locations,
        details: {
          ...state.details,
          model: action.payload.location,
          isSaving: false
        },
      };
    }

    case ActionTypes.SET_PRESELECTED: {
      return {
        ...state,
        preselected: action.payload.locations
      }
    }

    case ActionTypes.SAVE_LOCATION_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          errorStatusCode: action.payload.status,
          isSaving: false
        }
      }
    }

    case ActionTypes.CLEAR_LOCATION_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          model: null
        }
      }
    }

    default:
      return state;
  }
}

