import { CountFooter, WorkgroupFilterFooter } from 'components/ExternalFilterFooter';
import { StreamFormModel, StreamListItem } from 'models/streams';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BladeProps, ButtonItem, ColumnSettings, DataGrid, DataGridHeader, DataGridSelectionType, Footer,
    GridCard, IdName, useBladeButtons, useBladeClosing, useGridApi
} from 'react-tools';

import { CircularProgress } from '@material-ui/core';

import { useStreamListBladeButtons } from './buttons.hook';
import { streamsColumnSettings } from './column.settings';
import { useStyles } from './streams.jss';

export interface StreamsProps {
  bladeId: string;
  items: Array<StreamListItem>;
  channel?: IdName;
  device?: IdName;
  fetchingStreams: boolean;
  preselected: StreamListItem[];
}

export interface StreamsActions {
  fetchStreams: (channel?: IdName, device?: IdName) => void;
  createStream: () => void;
  editStream: (stream: StreamListItem) => void;
  clearPreselected: () => void;
}

type Props = StreamsProps & StreamsActions;

export const Streams: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const columns = useMemo<ColumnSettings[]>(
    () => streamsColumnSettings(props.channel !== undefined),
    [props.channel]
  );
  const gridApi = useGridApi();
  const [t] = useTranslation();

  const [selected, setSelected] = useState<StreamListItem[]>([]);
  const itemsCount = props.items.length;

  const fetchStreamsCallback = useCallback(() => {
    props.fetchStreams(props.channel, props.device);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchStreams, props.channel, props.device]);

  const buttons = useStreamListBladeButtons(
    fetchStreamsCallback,
    props.createStream,
    props.editStream,
    selected,
    props.fetchingStreams,
    gridApi,
    props.device !== undefined
  );

  useBladeButtons(buttons, [selected, props.fetchingStreams]);

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {}
  );

  useEffect(() => {
    props.fetchStreams(props.channel, props.device);
  }, [props.channel, props.device]);

  const MemoizedGrid = useMemo(
    () => (
      <DataGrid
        onSelect={setSelected}
        identifier={'streams'}
        noDataMessage={'streams.noResults'}
        defaultSelection={props.preselected}
        selectionType={DataGridSelectionType.Multiple}
        idColumn={'id'}
        items={props.items}
        columnSettings={columns}
      />
    ),
    [props.preselected, props.items]
  );

  return props.fetchingStreams ? (
    <div className={classes.loadingContainer}>
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={60} />
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <GridCard>
        <DataGridHeader loading={props.fetchingStreams} />
        {MemoizedGrid}
      </GridCard>
      <Footer>
        <CountFooter />
      </Footer>
    </div>
  );
};
