import { useRef, useCallback } from 'react';

export const useDetailsBladeOpen = () => {
  const isDetailsOpen = useRef(false);
  const isOpeningDetails = useRef(false);

  const shouldUnselectAll = useCallback(() => !isOpeningDetails.current, []);

  const resetIsDetailsOpen = useCallback(() => {
    isDetailsOpen.current = false;
    isOpeningDetails.current = false;
  }, []);

  const setIsDetailsOpen = useCallback(() => {
    setTimeout(() => {
      isDetailsOpen.current = true;
      isOpeningDetails.current = false;
    }, 0);
  }, []);

  return { isDetailsOpen, isOpeningDetails, shouldUnselectAll, resetIsDetailsOpen, setIsDetailsOpen };
}