import { colors, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    muiTreeItemRoot: {
      '&:focus $muiTreeItemLabel': {
        background: 'transparent !important',

        '&:hover': {
          background: 'rgba(0, 0, 0, 0.04) !important'
        }
      }
    },
    onHoverOnly: { visibility: 'hidden' },
    muiTreeItemLabel: {
      '&:hover > $onHoverOnly': { visibility: 'visible' },
      minHeight: '38px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    treeItemDefaultEndIcon: {
      width: '24px'
    },
    labelDivider: {
      height: '24px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    fixedItemLabel: {
      width: '350px',
    },
    treeItemLabel: {
      marginRight: theme.spacing(2),
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    treeItemLabelTooltipIcon: {
      color: theme.palette.grey[400],
    },
    textField: {
      //minWidth: '300px',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2),
      flexGrow: 1,

      '& input': {
        fontSize: theme.typography.body2.fontSize,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      }
    },
    selectRoot: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    green: { color: 'green' },
    red: { color: 'red' },
    arrayItemHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      height: '24px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    arrayItemHeaderCountLabel: {
      color: theme.palette.grey[500],
    },
    schemaSelect: {
      marginRight: theme.spacing(2)
    },
    fieldContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    form: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      minHeight: '0px',
      height: '0px',
      overflowY: "auto",
      flex: '1 1 auto',

      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        cursor: "pointer",
        background: "#D4CCC7",
      },

      "&::webkit-scrollbar-track": {
        background: "#9A9791",
        cursor: "pointer",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#9A9791",
        borderRadius: "5px",
        cursor: "pointer",

        "&:hover": {
          background: "#9A9791",
        },
      },
    },
    formContent: {
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    settingGroupCard: {
      marginBottom: theme.spacing(2),
    },
    settingGroupCardRoot: {
      overflow: 'visible'
    },
    spacer: {
      flexGrow: 1
    }
  });
export const useStyles = makeStyles(styles);
