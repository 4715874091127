import { DeviceFormModel, DeviceListItem } from 'models/devices';
import { IdName } from 'react-tools';

export const areaName = "device";

export interface DeviceState {
  devices: DeviceListItem[];
  isFetching: boolean;

  details: {
    isSaving: boolean;
    isFetching: boolean;
    errorStatusCode: number;
    model: DeviceFormModel | null;
  };

  preselected: DeviceListItem[];
}

export enum ActionTypes {
  FETCH_DEVICES_START = "FETCH_DEVICES_START",
  FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS",
  FETCH_DEVICES_FAIL = "FETCH_DEVICES_FAIL",

  FETCH_DEVICE_START = "FETCH_DEVICE_START",
  FETCH_DEVICE_SUCCESS = "FETCH_DEVICE_SUCCESS",
  FETCH_DEVICE_FAIL = "FETCH_DEVICE_FAIL",

  FETCH_DEVICE_LOCATIONS_START = "FETCH_DEVICE_LOCATIONS_START",
  FETCH_DEVICE_LOCATIONS_SUCCESS = "FETCH_DEVICE_LOCATIONS_SUCCESS",
  FETCH_DEVICE_LOCATIONS_FAIL = "FETCH_DEVICE_LOCATIONS_FAIL",

  SAVE_DEVICE_START = 'SAVE_DEVICE_START',
  SAVE_DEVICE_SUCCESS = 'SAVE_DEVICE_SUCCESS',
  SAVE_DEVICE_FAIL = 'SAVE_DEVICE_FAIL',

  CLEAR_DEVICE_DETAILS = 'CLEAR_DEVICE_DETAILS',
  SET_PRESELECTED = 'SET_PRESELECTED',
  DELETE_DEVICES = 'DELETE_DEVICES',
  DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS',
  DELETE_DEVICES_FAIL = 'DELETE_DEVICES_FAIL'
}