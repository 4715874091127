import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DirtySettingsList } from 'device-settings/wizard/dirty-settings-list';

interface Props {
  opened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  settings: string[];
}

export const EditorDirtyDialog = React.memo(({ opened, onCancel, onConfirm, settings }: Props) => {
  const [t] = useTranslation();

  return (
    <Dialog open={opened} maxWidth="xs">
      <DialogTitle>{t("wizard.dirtyDialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("wizard.dirtyDialog.unsavedChangesMessage1")}
          <br />
          {t("wizard.dirtyDialog.unsavedChangesMessage2")}
          <br />
        </DialogContentText>
        <DirtySettingsList settings={settings} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          {t("cancel")}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});