
import i18n from 'localization/i18n';
import { ChannelFormModel, ChannelType } from 'models';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BladeActions, BladeConfig, BladeProps, IdName, openBlade, withRegisterBlade
} from 'react-tools';
import { StreamsBladeName } from 'stream/list/streams.container';

import { actions, selectors, thunks } from '../duck';
import { ChannelForm } from './channel-form';

export const ChannelFormBladeName = 'CHANNEL_FORM_BLADE_NAME';

export interface ChannelFormContainerProps {
  workgroup: IdName;
  channelId?: number;
  channelName: string;
  channelType: ChannelType;
}

const bladeConfig: BladeConfig<ChannelFormContainerProps> = {
  bladeType: ChannelFormBladeName,
  size: { defaultWidth: 460, minWidth: 400 },
  title: (props: ChannelFormContainerProps) =>
    props.channelId ? props.channelName : i18n.t('channelForm.createTitle'),
  allowMultipleInstances: false,
};

const ContainerComponent = (props: ChannelFormContainerProps & BladeProps) => {
  const { workgroup, channelId, channelName, ...bladeProps } = props;

  const dispatch = useDispatch();

  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(props.bladeId)),
    [props.bladeId]
  );

  const onClose = useCallback(() => dispatch(actions.clearChannelDetails()), [
    dispatch,
  ]);

  const fetchChannel = useCallback(
    (channelId: number) => dispatch(thunks.fetchChannelAsync(channelId)),
    []
  );

  const channel = useSelector(selectors.selectChannel);
  const isFetchingChannel = useSelector(selectors.selectIsFetchingChannel);
  const isSavingChannel = useSelector(selectors.selectIsSavingChannel);
  const savingErrorCode = useSelector(selectors.selectSaveErrorStatusCode);

  const saveChannel = useCallback(
    (channel: ChannelFormModel) =>
      dispatch(
        thunks.saveChannelAsync(props.bladeId, channel, props.workgroup)
      ),
    [dispatch, props.workgroup.id]
  );

  const openChannelStreams = useCallback(
    (channelId: number) =>
      dispatch(
        openBlade(props.bladeId, StreamsBladeName, {
          channel: { id: props.channelId, name: props.channelName },
          workgroup,
        })
      ),
    [props.channelId, props.channelName, workgroup]
  );


  return (
    <ChannelForm
      {...bladeProps}
      savingErrorCode={savingErrorCode}
      isSavingChannel={isSavingChannel}
      isFetchingChannel={isFetchingChannel}
      workgroup={workgroup}
      channelId={channelId}
      saveChannel={saveChannel}
      closeBlade={closeBlade}
      onClose={onClose}
      fetchChannel={fetchChannel}
      channel={channel}
      channelName={channelName}
      openChannelStreams={openChannelStreams}
    />
  );
};

export const ChannelFormContainer = withRegisterBlade<
  ChannelFormContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);
