import React, { useState } from "react";
import { Typography, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./dirty-settings-list.jss";

export interface DirtySettingsListProps {
  settings: string[];
  visibleThreshold?: number;
}

const DefaultProps = {
  settings: [],
  visibleThreshold: 5,
};

export const DirtySettingsList = (props: DirtySettingsListProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [settings] = useState(
    props.settings.map((s) => t(s.replace(/--/g, "."))).sort()
  );
  const [hide, setHide] = useState(true);
  const visibleThreshold = props.visibleThreshold
    ? props.visibleThreshold
    : DefaultProps.visibleThreshold;

  const oneSetting = (
    <Typography color="textSecondary" variant="body2">
      {`${t("wizard.dirtyDialog.listHeader")} ${settings[0]}.`}
    </Typography>
  );

  const multipleSettings = (
    <>
      <Typography color="textSecondary" variant="body2">
        {t("wizard.dirtyDialog.listHeader")}:
      </Typography>
      <ul
        className={classes.listContainer}
        style={{ maxHeight: (visibleThreshold + 1) * 20 }}
      >
        {settings
          .filter((s, i) =>
            hide ? (i < visibleThreshold ? true : false) : true
          )
          .map((s, i) => (
            <li key={i}>
              <Typography color="textSecondary" variant="body2">
                {s}
              </Typography>
            </li>
          ))}
      </ul>
      {hide &&
      settings.length > visibleThreshold && (
        <span
          className={classes.moreButton}
          onClick={(e: any) => {
            e.preventDefault();
            setHide(false);
          }}
        >
          <Typography color="textSecondary" variant="body2">
            {t("wizard.dirtyDialog.andmore", {
              count: settings.length - visibleThreshold,
            })}
          </Typography>
        </span>
      )}
    </>
  );

  return settings.length === 1 ? oneSetting : multipleSettings;
};
