import { Entity } from 'device-settings/duck/types';
import { FastField, FastFieldProps, Field, FieldProps, Form, Formik } from 'formik';
import { EntityType } from 'models';
import { DeviceFormModel } from 'models/devices';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import {
  BladeProps, ButtonItem, Footer, FooterSubmit, FormActionsCard, IdName, useBladeButtons,
  useBladeClosing
} from 'react-tools';

import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, Grid,
  IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, TextFieldProps, Tooltip,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Refresh from '@material-ui/icons/Refresh';
import { Autocomplete } from '@material-ui/lab';

import { useLocations } from '../hooks';
import { DeviceFormActions } from './device-form.actions';
import { useStyles } from './device-form.jss';
import { DeviceFormListLoader } from './loader';
import { getDeviceValidationSchema } from './validation.scheme';

const getEmptyDevice = (
  workgroup: IdName,
  location?: IdName
): DeviceFormModel => ({
  id: 0,
  name: '',
  rowVersion: '',
  locationName: location?.name,
  locationId: location?.id,
  workgroupId: workgroup.id,
  workgroupName: workgroup.name,
  deviceTypeId: 2,
  salesOrderNumber: '',
  serialNumber: '',
  createStream: true,
});

interface DeviceFormProps {
  /**
   * Id of the player. If undefined then this is a new player, not saved yet.
   */
  deviceId?: number;
  location?: IdName;
  workgroup: IdName;
  device: DeviceFormModel | null;
  isSaving: boolean;
  isFetching: boolean;
  savingErrorCode: number;
}

interface DeviceFormActions {
  fetchDevice: (deviceId: number) => void;

  saveDevice: (
    device: DeviceFormModel,
    workgroup: IdName,
    location?: IdName
  ) => void;
  closeBlade: () => void;
  openDeviceSettings: (device: IdName, parentEntity: Entity) => void;
  openStreams: (device: IdName) => void;
}

export const DeviceForm = (
  props: DeviceFormActions & DeviceFormProps & BladeProps
) => {
  const classes = useStyles();

  const [device, setDevice] = useState(
    props.device
      ? props.device
      : getEmptyDevice(props.workgroup, props.location)
  );
  const [editableSerial, setEditableSerial] = useState(false);
  const [t] = useTranslation();

  const deviceWorkgroupId = props.deviceId
    ? props.device
      ? props.device.workgroupId
      : 0
    : props.workgroup.id;

  const locations = useLocations(deviceWorkgroupId, [deviceWorkgroupId]);

  const buttons: ButtonItem[] = useMemo<ButtonItem[]>(
    () => [
      {
        disabled: props.savingErrorCode !== 409,
        icon: () => <Refresh />,
        onClick: () => {
          if (props.device) props.fetchDevice(props.device.id);
        },
        tooltip: t('locationForm.refresh'),
      },
    ],
    [props.savingErrorCode, props.location, props.device]
  );

  useBladeButtons(buttons, [props.savingErrorCode, props.location]);
  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => { }
  );

  useEffect(() => {
    if (props.deviceId) {
      props.fetchDevice(props.deviceId);
    } else {
      setDevice(getEmptyDevice(props.workgroup, props.location));
    }
  }, [props.deviceId]);

  useEffect(() => {
    if (props.device) {
      setDevice(props.device);
    }
  }, [props.device]);

  const openDeviceSettings = useCallback(() => {
    if (props.device) {
      props.openDeviceSettings(
        {
          id: props.device.id,
          name: props.device?.name,
        },
        props.device.locationId
          ? { entityId: props.device.locationId, entityType: EntityType.Site }
          : {
            entityId: device.workgroupId,
            entityType: EntityType.Workgroup,
          }
      );
    }
  }, [props.device?.id, props.device?.name, props.device?.locationId]);

  const openStreams = useCallback(() => {
    if (props.device) {
      props.openStreams({
        id: props.device.id,
        name: props.device?.name,
      });
    }
  }, [props.device?.id, props.device?.name, props.device?.locationId]);

  const validationSchema = useMemo(() => getDeviceValidationSchema(editableSerial), [editableSerial]);

  const submitForm = useCallback(
    (values: DeviceFormModel) => {
      const location = locations?.find((l) => l.id === values.locationId);
      const locationName = location ? location.name : '';
      const newDeviceModel = { ...values, locationName };
      props.saveDevice(newDeviceModel, props.workgroup, props.location);
      props.setDirty(false);
    },
    [props.setDirty, props.saveDevice, props.location]
  );

  const onFormChanged = useCallback(
    (dirty: boolean) => () => props.setDirty(dirty),
    [props.setDirty]
  );

  if (props.isFetching || !locations) {
    return <DeviceFormListLoader />;
  }

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={device}
          enableReinitialize={true}
          onSubmit={submitForm}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({ submitForm, values, handleChange, dirty }) => (
            <Form onBlur={onFormChanged(dirty)} className={classes.form}>
              <div className={classes.formContent}>
                <Accordion
                  defaultExpanded
                  classes={{ expanded: classes.expanded }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1">
                      {t('deviceForm.deviceProperties')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      spacing={2}
                      classes={{ root: classes.panelContainer }}
                    >
                      {values.id !== 0 && (
                        <Grid item>
                          <TextField
                            label={t('deviceForm.id')}
                            variant="filled"
                            fullWidth
                            disabled
                            InputProps={{
                              disableUnderline: true,
                            }}
                            value={values.id}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <FastField name="name">
                          {({ form }: FastFieldProps<DeviceFormModel>) => (
                            <TextField
                              label={t('deviceForm.name')}
                              variant="filled"
                              fullWidth
                              error={form.errors.name ? true : false}
                              helperText={form.errors.name}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ maxLength: 50 }}
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          )}
                        </FastField>
                      </Grid>

                      <Grid item>
                        <FastField name="salesOrderNumber">
                          {({ form }: FastFieldProps<DeviceFormModel>) => (
                            <TextField
                              label={t('deviceForm.salesOrderNumber')}
                              variant="filled"
                              fullWidth
                              error={
                                form.errors.salesOrderNumber ? true : false
                              }
                              helperText={form.errors.salesOrderNumber}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ maxLength: 50 }}
                              name="salesOrderNumber"
                              value={values.salesOrderNumber}
                              onChange={handleChange}
                            />
                          )}
                        </FastField>
                      </Grid>

                      <Grid item>
                        <FastField name="deviceTypeId">
                          {({ field }: FastFieldProps<DeviceFormModel>) => (
                            <FormControl
                              variant="filled"
                              fullWidth
                              style={{ width: '100%' }}
                            >
                              <InputLabel>
                                {t('deviceForm.deviceTypeId')}
                              </InputLabel>
                              <Select
                                value={values.deviceTypeId}
                                onChange={handleChange}
                                name="deviceTypeId"
                              >
                                <MenuItem value={2}>
                                  {t('deviceForm.deviceTypeId.android')}
                                </MenuItem>
                                <MenuItem value={1}>
                                  {t('deviceForm.deviceTypeId.windows')}
                                </MenuItem>
                                <MenuItem value={3}>
                                  {t('deviceForm.deviceTypeId.brightsign')}
                                </MenuItem>
                                <MenuItem value={4}>
                                  {t('deviceForm.deviceTypeId.samsung')}
                                </MenuItem>
                                <MenuItem value={5}>
                                  {t('deviceForm.deviceTypeId.moki')}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>

                      <Grid item>
                        <Field name="serialNumber">
                          {({ field, form }: FieldProps) => (
                            <TextField
                              {...field}
                              inputProps={{ maxLength: 180 }}
                              label={t('deviceForm.serialNumber')}
                              fullWidth
                              variant="filled"
                              value={values.serialNumber}
                              disabled={!editableSerial}
                              error={
                                (
                                  form.errors[field.name] !== undefined ||
                                  props.savingErrorCode === 400
                                ) && editableSerial
                              }
                              helperText={
                                props.savingErrorCode === 400
                                  ? t('deviceForm.serialConflict')
                                  : (editableSerial ? form.errors[field.name] : '')
                              }
                              onChange={(e) => {
                                form.setFieldValue(
                                  field.name,
                                  e.currentTarget.value.toUpperCase()
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={
                                        editableSerial
                                          ? t(
                                            'deviceForm.serialNumber.unlocked'
                                          ).toString()
                                          : t(
                                            'deviceForm.serialNumber.locked'
                                          ).toString()
                                      }
                                    >
                                      <IconButton
                                        onClick={() => {
                                          setEditableSerial(!editableSerial);

                                          if (editableSerial) {
                                            form.setFieldValue(
                                              field.name,
                                              form.initialValues[field.name]
                                            );
                                            form.setFieldTouched(
                                              field.name,
                                              true
                                            );
                                          }
                                        }}
                                      >
                                        {editableSerial ? (
                                          <LockOpen />
                                        ) : (
                                            <Lock />
                                          )}
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </Grid>

                      {device && locations && !props.location && (
                        <Grid item>
                          <Field name="locationId">
                            {({ form }: FieldProps<DeviceFormModel>) => (
                              <Autocomplete
                                options={locations}
                                getOptionLabel={(option: IdName) => option.name}
                                fullWidth
                                value={
                                  locations.find(
                                    (e: IdName) => e.id === values.locationId
                                  ) || null
                                }
                                onChange={(e: any, location: IdName | null) => {
                                  if (location) {
                                    form.setFieldValue(
                                      'locationId',
                                      location.id
                                    );
                                  } else {
                                    form.setFieldValue('locationId', '');
                                  }
                                }}
                                renderInput={(params: TextFieldProps) => (
                                  <TextField
                                    {...params}
                                    name="locationId"
                                    error={
                                      form.errors.locationId ? true : false
                                    }
                                    helperText={form.errors.locationId}
                                    label={t('deviceForm.location')}
                                    variant="filled"
                                    value={values.locationId}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password',
                                    }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </Grid>
                      )}

                      {props.deviceId === 0 && (
                        <Grid item>
                          <FastField name="createStream">
                            {() => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.createStream}
                                    onChange={handleChange}
                                    name="createStream"
                                  />
                                }
                                label={t('deviceForm.createStream')}
                              />
                            )}
                          </FastField>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {props.deviceId !== 0 && (
                  <FormActionsCard marginTop padding={-2}>
                    <DeviceFormActions
                      openDeviceSettings={openDeviceSettings}
                      openStreams={openStreams}
                    />
                  </FormActionsCard>
                )}
              </div>

              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t('cancel')}
                  cancel={props.closeBlade}
                  submitButtonLabel={props.device ? t('save') : t('create')}
                  submitInProgress={props.isSaving}
                  submit={submitForm}
                />
              </Footer>
            </Form>
          )}
        </Formik>
      </FadeIn>
    </div>
  );
};
