import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draggableContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    paper: {
      height: "100%",
      backgroundColor: theme.palette.primary.main,
      position: "relative"
    },
    content: {
      flexGrow: 1
    },
    dragHandle: {
      width: "4px",
      height: "100%",
      zIndex: 100,
      cursor: "col-resize",
      right: "-2px",
      position: "absolute",
      top: 0,
      "&:hover": {
        backgroundColor: theme.palette.grey[500]
      }
    },
    dragHandleActive: {
      zIndex: 100,
      backgroundColor: theme.palette.grey[500]
    },
    dragHandleActiveLessThanMin: {
      backgroudColor: "rgba(255, 0, 0, 0.2)"
    },
    noSelect: {
      userSelect: "none"
    }
  })
);
