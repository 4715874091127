import { Reducer } from "redux";
import { ActionType } from "typesafe-actions";

import { ActionTypes, WorkgroupState } from "./types";
import { normalize } from "utils";

import * as Actions from "./actions";

type WorkgroupActions = ActionType<typeof Actions>;

const initialState: WorkgroupState = {
  favoriteWorkgroups: [],

  isFetchingWorkgroups: false,
  isFetchingTemplates: false,
  isSavingWorkgroupLocation: false,

  workgroups: {},
  templates: [],

  workgroupDetails: {
    model: null,
    isSaving: false,
    saved: false,
    isFetching: false,
    templates: []
  },
};

export const workgroupReducer: Reducer<WorkgroupState, WorkgroupActions> = (state: WorkgroupState = initialState, action: WorkgroupActions): WorkgroupState => {
  switch (action.type) {
    case ActionTypes.FETCH_WORKGROUPS_START: {
      return {
        ...state,
        isFetchingWorkgroups: true,
      };
    }

    case ActionTypes.FETCH_WORKGROUPS_SUCCESS: {
      return {
        ...state,
        isFetchingWorkgroups: false,
        workgroups: normalize(action.payload.workgroups, "id"),
      };
    }

    case ActionTypes.WORKGROUP_FAVORITE_SUCCESS: {
      return {
        ...state,
        favoriteWorkgroups: [...state.favoriteWorkgroups, action.payload.workgroupId],
      };
    }

    case ActionTypes.WORKGROUP_UNFAVORITE_SUCCESS: {
      const index = state.favoriteWorkgroups.indexOf(action.payload.workgroupId);
      const favoriteWorkgroups = [...state.favoriteWorkgroups];
      favoriteWorkgroups.splice(index, 1);

      return { ...state, favoriteWorkgroups };
    }

    case ActionTypes.CLEAR_WORKGROUP_DETAILS: {
      return {
        ...state,
        workgroupDetails: {
          isFetching: false,
          isSaving: false,
          saved: false,
          model: null,
          templates: [],
        }
      };
    }

    case ActionTypes.SAVE_WORKGROUP_START: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          isSaving: true,
          saved: false
        }
      }
    }

    case ActionTypes.SAVE_WORKGROUP_SUCCESS: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          model: action.payload.workgroup,
          isSaving: false,
          saved: true
        }
      }
    }

    case ActionTypes.SAVE_WORKGROUP_FAIL: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          isSaving: false,
          saved: false
        }
      }
    }

    case ActionTypes.SET_WORKGROUP_TEMPLATES: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          templates: action.payload.templates
        }
      }
    }

    case ActionTypes.FETCH_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: action.payload.templates,
        isFetchingTemplates: false
      }
    }
    case ActionTypes.FETCH_TEMPLATES_START: {
      return { ...state, isFetchingTemplates: true };
    }

    case ActionTypes.FETCH_TEMPLATES_FAIL: {
      return { ...state, isFetchingTemplates: false };
    }

    case ActionTypes.FETCH_WORKGROUP_START: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          isFetching: true
        }
      }
    }

    case ActionTypes.FETCH_WORKGROUP_SUCCESS: {
      return {
        ...state,
        workgroupDetails: {
          ...state.workgroupDetails,
          isFetching: false,
          model: action.payload.workgroup,
          templates: action.payload.workgroup.templates
        }
      }
    }

    default:
      return state;
  }
};
