import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGridSelectionType, openBlade, SelectionListBladeType, SelectionBladeContainerProps } from 'react-tools';
import { thunks, actions, selectors } from 'workgroup/duck';
import { TemplateLibrary } from 'models';
import { templateLibraryColumns } from '../column.settings';
import { useTranslation } from 'react-i18next';


export const useOpenTemplateLibraryBlade = (bladeId: string, deps: Array<any>) => {
  const isTemplateSelectionOpen = useRef(false);
  const workgroupTemplates = useSelector(selectors.selectWorkgroupTemplates);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const openTemplateLibrarySelection = useCallback(
    () => {
      if (!isTemplateSelectionOpen.current) {
        dispatch(thunks.fetchTemplatesAsync());

        dispatch(
          openBlade<SelectionBladeContainerProps>(
            bladeId,
            SelectionListBladeType,
            {
              columns: templateLibraryColumns,
              identifier: 'templateLibraries',
              onClose: (items: TemplateLibrary[] | null) => {
                if (items !== null) {
                  dispatch(actions.setWorkgroupTemplates(items));
                }
                isTemplateSelectionOpen.current = false;
              },
              selectionType: DataGridSelectionType.Multiple,
              title: t('templateLibraries.listTitle'),
              itemsSelector: selectors.selectTemplates,
              selectedItems: workgroupTemplates,
              isFetchingSelector: selectors.selectIsFetchingTemplates
            }
          )
        );
        isTemplateSelectionOpen.current = true;
      }
    }, [...deps, workgroupTemplates]);

  return openTemplateLibrarySelection;
}