import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { createStyles, Theme } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5)
    },
    input: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    panelHeader: {
      marginTop: '36px',
      marginBottom: '44px',
      marginLeft: '24px'
    }
  });

const useStyles = makeStyles(styles);

export const DeviceFormListLoader = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Skeleton animation={'wave'} variant='text' className={classes.panelHeader} width={120} height={24} />

        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
      </div>

      <div className={classes.container}>
        <Skeleton animation={'wave'} className={classes.panelHeader} variant='text' width={120} height={24} />

        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
        <Skeleton animation={'wave'} width={388} height={24} className={classes.input} />
      </div>
    </>
  );
}