import { createSelector } from "reselect";

import { areaName, WorkgroupState } from "./types";

const selectWorkgroupState = (state: any): WorkgroupState => state[areaName] as WorkgroupState;

export const selectFavoriteWorkgroups = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.favoriteWorkgroups);

export const selectIsFetchingWorkgroups = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.isFetchingWorkgroups);

export const selectWorkgroups = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroups);

export const selectWorkgroupById = (id: number) => createSelector([selectWorkgroups], (workgroups) => workgroups[id]);

export const selectWorkgroupDetailsIsSaving = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.isSaving);

export const selectWorkgroupDetailsSaved = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.saved);

export const selectWorkgroupDetails = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.model);

export const selectIsFetchingWorkgroup = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.isFetching);

export const selectIsSavingWorkgroup = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.isSaving);

export const selectIsFetchingTemplates = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.isFetchingTemplates);

export const selectTemplates = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.templates);

export const selectWorkgroupTemplates = createSelector([selectWorkgroupState], (state: WorkgroupState) => state.workgroupDetails.templates);
