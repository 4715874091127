import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1
        },
        noMarginFormLabel: {
            marginRight: 0
        }
    });

export const useStyles = makeStyles(styles);
