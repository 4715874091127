import { DependencyList, useEffect, useState } from 'react';
import { IdName } from 'react-tools';

import { DeviceService } from '../duck/service';

const service = new DeviceService();

export const useLocations = (
  workgroupId: number,
  deps?: DependencyList
): IdName[] | null => {
  const [locations, setLocations] = useState<IdName[] | null>(null);
  useEffect(() => {
    const abortController = new AbortController();

    if (workgroupId !== 0) {
      const fetchLocations = async () => {
        try {
          const result = await service.getAvailableLocations(
            workgroupId,
            abortController.signal
          );
          setLocations(result);
        } catch (e) {
          if (!abortController.signal.aborted) {
            // TODO: handle error
          }
        }
      };

      fetchLocations();
      return () => {
        abortController.abort();
      };
    }
  }, deps);

  return locations;
};
