import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    app: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    container: { display: 'flex', flexGrow: 1, height: '100%' },

  });

export const useStyles = makeStyles(styles);
