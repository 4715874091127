import { JSONBooleanSchema } from 'device-settings/duck/json-schema-types';
import { Field, FieldInputProps, FieldProps, useField } from 'formik';
import React from 'react';

import { Switch } from '@material-ui/core';

import { useStyles } from './editor.jss';
import { EditorTreeItem } from './editorTreeItem';
import { TreeItemLabel } from './treeItemLabel';
import { SchemaEditorProps } from './types';

const MemoBooleanEditor: React.FunctionComponent<
  SchemaEditorProps<JSONBooleanSchema> & { field: FieldInputProps<boolean> }
> = React.memo(
  (props) => {
    const classes = useStyles();
    return (
      <EditorTreeItem
        nodeId={props.propKey}
        label={
          <TreeItemLabel
            label={props.label ? props.label : props.propKey}
            classes={{ label: classes.fixedItemLabel }}
            tooltip={props.schema.description}
          >
            <Switch
              disabled={props.readOnly}
              color="secondary"
              checked={props.field.value}
              onChange={props.field.onChange}
              name={props.field.name}
            />
            <div className={classes.spacer}/>
            {props.labelChildren}
          </TreeItemLabel>
        }
      />
    );
  },
  (p, n) => p.field.value === n.field.value && p.readOnly === n.readOnly
);

export const BooleanEditor: React.FunctionComponent<SchemaEditorProps<
  JSONBooleanSchema
>> = (props) => {
  const [field, meta] = useField(props.propKey);
  return <MemoBooleanEditor {...props} field={field} />;
};
