import i18n from 'localization/i18n';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { useStyles } from './settings-stepper.jss';
import { CircularProgress } from '@material-ui/core';

export enum SettingsStep {
  SelectSettings,
  SetValues,
  SetTarget,
}

const SettingsStepI18n = {
  [SettingsStep.SelectSettings]: i18n.t(
    "wizard.settingsStepper.settingsSelector"
  ),
  [SettingsStep.SetValues]: i18n.t("wizard.settingsStepper.setValues"),
  [SettingsStep.SetTarget]: i18n.t("wizard.settingsStepper.setTarget"),
};

export interface SettingsStepperProps {
  steps: SettingsStep[];
  activeStepIndex: number;
  loading: boolean;
  onNext: () => void;
  onBack: () => void;
  nextDisabled?: boolean;
  backDisabled?: boolean;
}

export const SettingsStepper = React.memo((props: SettingsStepperProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const isOnFirstStep = props.activeStepIndex === 0;
  const hasFinished = props.activeStepIndex === props.steps.length;
  const hasReachedLastStep = props.activeStepIndex >= props.steps.length - 1;

  return (
    <div className={classes.root}>
      <Stepper activeStep={props.activeStepIndex}>
        {props.steps.map((step) => (
          <Step key={step}>
            <StepLabel
              StepIconProps={{
                classes: {
                  active: classes.stepIconActive,
                  completed: classes.stepIconComplete,
                },
              }}
            >
              {SettingsStepI18n[step]}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={props.loading ? () => { } : props.onNext}
          disabled={props.nextDisabled || hasFinished}
        >
          {props.loading ?
            <CircularProgress size={24} className={classes.buttonProgress} /> :
            hasReachedLastStep
              ? t("wizard.settingsStepper.save")
              : t("wizard.settingsStepper.next")}
        </Button>
        <Button 
          onClick={props.onBack} 
          className={classes.button} 
          disabled={props.backDisabled}
        >
          {isOnFirstStep
            ? t("wizard.settingsStepper.cancel")
            : t("wizard.settingsStepper.back")}
        </Button>
      </div>
    </div>
  );
});
