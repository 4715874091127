import i18n from 'localization/i18n';
import { WorkgroupLocation } from 'models';
import { boolean, number, object, string } from 'yup';

export const LocationValidationSchema = object().shape<WorkgroupLocation>({
  oracleNumber: string().required(i18n.t('validation.required')).max(50),
  name: string().required(i18n.t('validation.required')).max(255),
  timezone: string().required(i18n.t('validation.required')),
  country: string().required(i18n.t('validation.required')),
  city: string().required(i18n.t('validation.required')).max(255),
  zipCode: string().required(i18n.t('validation.required')).max(50),
  state: string()
    .when("country", { is: "United States", then: string().required(i18n.t('validation.required')) })
    .when("country", { is: "Canada", then: string().required(i18n.t('validation.required')) })
    .when("country", { is: "Australia", then: string().required(i18n.t('validation.required')) })
    .when("country", { is: "Brazil", then: string().required(i18n.t('validation.required')) })
    .when("country", { is: "Japan", then: string().required(i18n.t('validation.required')) })
    .when("country", { is: "China", then: string().required(i18n.t('validation.required')) }),
  address: string(),
  isExplicit: boolean(),
  phoneNumber: string(),
  clientSiteId: string().max(64),
  id: number(),
  workgroupId: number(),
  workgroupName: string()
});
