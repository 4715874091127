export const apiFetch = (
  url: string,
  options?: RequestInit,
  abort?: AbortSignal
): Promise<Response> => {
  return fetch(url, {
    [CURRENT_WORKGROUP_HEADER_NAME]: getCookieValue(currentWorkgroupKey),
    signal: abort,
    ...options,
  });
};

export const fetchHelper = (
  url: string,
  verb: string,
  data = {},
  additionalHeaders?: { [key: string]: any }
): Promise<Response> => {
  const headers = {
    'Content-Type': 'application/json',
    [CURRENT_WORKGROUP_HEADER_NAME]: getCookieValue(currentWorkgroupKey),
  };

  if (additionalHeaders) {
    Object.keys(additionalHeaders).map(
      (key: string) => (headers[key] = additionalHeaders[key])
    );
  }

  return fetch(url, {
    method: verb, // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, cors, *same-origin
    credentials: 'include',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers,
    body: JSON.stringify(data),
  });
};

export const getCookieValue = (key: string): string => {
  const b: string[] | null = document.cookie.match(
    `(^|;)\\s*${key}\\s*=\\s*([^;]+)`
  );

  if (b === null) {
    return '';
  }

  const value = b.pop();
  return value ? value : '';
};

export const currentWorkgroupKey: string = 'currentWorkgroup';
export const CURRENT_WORKGROUP_HEADER_NAME: string = process.env
  .REACT_APP_HEADER_01 as string;
