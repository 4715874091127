import i18n from "localization/i18n";
import { RowNode } from "@ag-grid-enterprise/all-modules";

export const valueFilterSettings = {
    filter: 'agTextColumnFilter',
    customFloatingFilterParams: {
      filterOptions: [
        {
          displayKey: 'settingValueSearch',
          displayName: i18n.t('settingValueSearch'),
          test: (filterValue: any, cellValue: any): boolean => {
            const a: string = filterValue.toLowerCase();
            const b: string = cellValue.toLowerCase();

            return b.indexOf(a) > -1;
          }
        },
      ],
      valueGetter: (rowNode: RowNode): any => {
        if (rowNode.data === undefined) {
          return "";
        }
        
        const cellValue = rowNode.data.value;

        if (cellValue === undefined) {
          return "";
        } 
        
        if (cellValue === null) {
          return "null";
        } 
        
        if (typeof cellValue === 'object') {
          return JSON.stringify(cellValue);
        }

        return cellValue;
      },
      suppressAndOrCondition: true
    }
};