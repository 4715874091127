import { IdName } from 'react-tools';

export interface StreamListItem extends IdName {
    channelId: number;
    channelName: string;
    hardwareId: number;
    hardwareName: string;
    
    x: number;
    y: number;
    width: number;
    height: number;

    audioOutputType: StreamAudioOutput
}

export interface StreamFormModel extends IdName {
    channelId: number;
    channelName: string;
    hardwareId: number;
    hardwareName: string;

    x: number;
    y: number;
    width: number;
    height: number;
    audioOutputType: StreamAudioOutput;

    rowVersion: string;
}

export enum StreamAudioOutput {
    All = 0,
    Right = 1,
    Left = 2
}