import React from 'react';
import { createStyles, CircularProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: theme.spacing(1),
      position: 'relative',
      overflow: 'hidden'
    },
    progress: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  });

const useStyles = makeStyles(styles);

export const DeviceCountConfirmLoader = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <CircularProgress
          className={classes.progress}
          variant="indeterminate"
          color="secondary"
          size={120}
        />
      </div>
    </>
  );
}