import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: "flex",
      marginLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    toolbarButton: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    },
    form: {
      display: "flex",
      position: "relative",
    },
    textFieldButtons: {
      display: "flex",
      position: "absolute",
      right: theme.spacing(1),
      paddingTop: theme.spacing(0.5),

      "& hr": {
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
        height: "32px",
      },
    },
    searchFieldIcon: {
      padding: theme.spacing(0.5),
    },
    searchField: {
      flexGrow: 1,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),

      "& div:before": {
        borderBottom: "none",
      },

      "& div:hover:before": {
        borderBottom: "none",
      },

      "& div:after": {
        borderBottom: "none",
      },

      "& label": {
        paddingLeft: theme.spacing(1.9),
      },

      "& input": {
        textIndent: theme.spacing(1.6),
      },
    },
  })
);
