import i18next from 'i18next';
import React from 'react';

import { MenuItem } from '@material-ui/core';

export const enumToMenuItems = <T extends { [name: string]: any }>(
  enumType: T,
  translationPrefix?: string
) => {
  const prefix = translationPrefix ? translationPrefix + '.' : '';
  return Object.keys(enumType)
    .filter((key) => Number.isNaN(Number(key)))
    .map((key) => (
      <MenuItem key={key} value={enumType[key]}>
        {i18next.t<string>(prefix + key.toLowerCase())}
      </MenuItem>
    ));
};
