import { ColumnSettings } from 'react-tools';

export const templateLibraryColumns: ColumnSettings[] = [
  {
    name: 'id',
    width: 120,
    filter: 'agTextColumnFilter'
  },
  {
    name: 'name',
    width: 320,
    lockVisibility: true,
    filter: 'agTextColumnFilter'
  }
];