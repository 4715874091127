import { FlexFadeIn } from 'components';
import { Entity } from 'device-settings/duck/types';
import { DeviceSettingModel } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    DataGridSelectionType, IdName, TreeGrid, useBladeButtons, useBladeClosing} from 'react-tools';

import { useDeviceSettingsBladeButtons } from './buttons.hook';
import { SettingColumnRenderer } from './column-formatters';
import { deviceSettingsColumnSettings } from './column.settings';
import { useFetchDeviceSettings, useMapDeviceSettings } from './hooks';
import { DeviceSettingsListLoader } from './loader';

interface DeviceSettingsProps {
  bladeId: string;
  isFetching: boolean;
  deviceSettings: DeviceSettingModel[];
  workgroup?: IdName;
  location?: IdName;
  device?: IdName;
  parentEntity: Entity | null;

  fetchDevicesSettings: (entity: Entity, parentEntity: Entity | null) => void;
  openWizard: () => void;
  clearDeviceSettings: () => void;
}

export const DeviceSettings = ({
  device,
  location,
  workgroup,
  parentEntity,
  fetchDevicesSettings,
  ...props
}: DeviceSettingsProps) => {
  const [t] = useTranslation();

  useFetchDeviceSettings({
    device,
    location,
    workgroup,
    parentEntity,
    fetchDevicesSettings
  });

  const deviceSettings = useMapDeviceSettings(props.deviceSettings);

  const buttons = useDeviceSettingsBladeButtons(
    !props.isFetching,
    props.openWizard
  );

  useBladeButtons(buttons, [buttons]);
  useBladeClosing(props.bladeId, () => true, props.clearDeviceSettings)

  return props.isFetching ? (
    <DeviceSettingsListLoader />
  ) : (
      <FlexFadeIn>
        <TreeGrid
          columnSettings={deviceSettingsColumnSettings}
          getRowDataPath={(item: any) => item.path}
          items={deviceSettings}
          selectionType={DataGridSelectionType.None}
          identifier={'deviceSettings'}
          groupColumnTitle={t('deviceSettings.setting')}
          rowHeight={36}
          groupColumnRendererComponent="settingsRenderer"
          components={{ settingsRenderer: SettingColumnRenderer }}
        />
      </FlexFadeIn>
    );
};
