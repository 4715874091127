import i18n from 'localization/i18n';
import { WorkgroupLocation } from 'models';
import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    BladeActions, BladeConfig, BladeProps, DataGridContextProvider, IdName, withRegisterBlade
} from 'react-tools';

import { actions, selectors, thunks } from '../duck';
import { WorkgroupLocations } from './locations';

export const WorkgroupLocationsBladeName = "WORKGROUP_LOCATIONS";

interface WorkgroupLocationsContainerProps extends BladeProps {
  workgroup: IdName;
}


const bladeConfig: BladeConfig<WorkgroupLocationsContainerProps> = {
  bladeType: WorkgroupLocationsBladeName,
  size: { defaultWidth: 800, minWidth: 600 },
  title: (props: any) => i18n.t('workgroupLocations.listTitle'),
  allowMultipleInstances: false,
};

const ContainerComponent = (props: WorkgroupLocationsContainerProps & BladeProps) => {
  const { workgroup, bladeId } = props;

  const dispatch = useDispatch();

  const items = useSelector(selectors.selectLocations);
  const fetchingLocations = useSelector(selectors.selectIsFetchingLocations);
  const preselected = useSelector(selectors.selectPreselected);

  const fetchLocations = useCallback(() => dispatch(thunks.fetchLocationsAsync(workgroup.id)), [workgroup.id]);

  const createLocation = useCallback(() =>
    batch(() => dispatch(BladeActions.closeChildrenBlades(bladeId, [
      actions.clearLocationDetails(),
      actions.openLocationFormBlade(bladeId, { workgroup, locationName: '', locationId: 0 })
    ]))), [workgroup]);

  const editLocation = useCallback((location: WorkgroupLocation) =>
    batch(() =>
      dispatch(BladeActions.closeChildrenBlades(bladeId,
        actions.openLocationFormBlade(bladeId, { workgroup, locationName: location.name, locationId: location.id }))
      )), [workgroup]);

  const deleteLocations = useCallback((locations: WorkgroupLocation[]) => alert(`DELETE ${locations.map(e => e.name + ', ')}`), []);

  const onClose = useCallback(() => clearPreselected(), []);
  const clearPreselected = useCallback(() => dispatch(actions.setPreselected([])), []);

  return (
    <DataGridContextProvider>
      <WorkgroupLocations
        bladeId={bladeId}
        onClose={onClose}
        preselected={preselected}
        clearPreselected={clearPreselected}
        workgroup={workgroup}
        items={items}
        fetchingLocations={fetchingLocations}
        fetchLocations={fetchLocations}
        createLocation={createLocation}
        deleteLocations={deleteLocations}
        editLocation={editLocation}
      />
    </DataGridContextProvider>
  );
}

export const WorkgroupLocationsContainer = withRegisterBlade(bladeConfig)(ContainerComponent);