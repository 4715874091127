import { IdName } from 'react-tools';

export interface DeviceListItem extends IdName {
    type: string;
    serial: string;
    salesOrderNo: string;
    isExplicit: boolean;
    workgroupId: number;
    workgroupName: string;
    locationId?: number;
    locationName?: string;
}

export interface DeviceResponseModel {
   saveResultCode: DeviceSaveResultCode;
   saveResultType: DeviceSaveResultType;
   device: DeviceFormModel;
}

export interface DeviceFormModel extends IdName {
    locationId?: number;
    locationName?: string;
    workgroupId: number;
    workgroupName: string;
    rowVersion: string;
    deviceTypeId: number;
    serialNumber: string;
    salesOrderNumber: string;
    createStream?: boolean;
}

export enum DeviceSaveResultCode {
    Error=0,
    Success=1,
    PlayersFolderError=2,
    DbConcurrencyError=3,
    SerialExistsError = 4,
    SqlError =5,       
    TimeoutError=6
}

export enum DeviceSaveResultType {
    Error=0,
    Success=1,
    Warning=2
}

export interface DeleteDevicesDto {
    devices: Number[]
}