import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    muiListPadding: {
      padding: 0
    },
    list: {
      //minHeight: '50px',
      overflowY: 'unset'
    },
    button: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(2)
    },
    buttonIcon: {
      pointerEvents: 'none'
    }
  });

export const useStyles = makeStyles(styles, {
  index: 1
});

