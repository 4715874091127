import { StreamListItem } from 'models/streams';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem, useGridExportEvents } from 'react-tools';

import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';

export const useStreamListBladeButtons = (
  fetchStreams: () => void,
  createStream: () => void,
  editStream: (stream: StreamListItem) => void,
  selected: StreamListItem[],
  loading: boolean,
  gridApi: any,
  canEdit: boolean
) => {
  const editStreamCallback = useCallback(
    () => editStream(selected[0] as StreamListItem),
    [selected]
  );
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(() => {
    const items: ButtonItem[] = [
      {
        tooltip: 'streams.refresh',
        icon: () => <Refresh />,
        onClick: fetchStreams,
        disabled: loading,
      },
    ];
    if (canEdit) {
      items.push({
        tooltip: 'streams.create',
        icon: () => <Add />,
        onClick: createStream,
      });
      items.push({
        tooltip: 'streams.edit',
        icon: () => <Edit />,
        disabled: selected.length === 0 || selected.length > 1,
        onClick: editStreamCallback,
      });
    }
    items.push({
      tooltip: 'streams.export',
      icon: () => <CloudDownload />,
      onClick: () => {
        if (exportFunctions && exportFunctions.exportCsv) {
          exportFunctions.exportCsv(
            `${t('streams.exportFileName').toString()}.csv`
          );
        }
      },
    });
    return items;
  }, [selected, loading, gridApi]);

  return buttons;
};
