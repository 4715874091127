import { DeviceSettingModel, EntityType } from 'models';

export const areaName = 'deviceSettings';

export interface DeviceSettingsState {
  isFetching: boolean;
  entity: Entity,
  parentEntity: Entity | null,
  entitySettings: DeviceSettingModel[],
  parentEntitySettings: DeviceSettingModel[] | null,
  isSavingSettings: boolean;
  saved: boolean;
}

export interface Entity {
  entityId: number,
  entityType: EntityType
}

export enum ActionTypes {
  FETCH_DEVICE_SETTINGS = 'FETCH_DEVICE_SETTINGS',
  FETCH_DEVICE_SETTINGS_FAIL = 'FETCH_DEVICE_SETTINGS_FAIL',
  FETCH_DEVICE_SETTINGS_SUCCESS = 'FETCH_DEVICE_SETTINGS_SUCCESS',
  CLEAR_DEVICE_SETTINGS = "CLEAR_DEVICE_SETTINGS",

  SAVE_DEVICE_SETTINGS = "SAVE_DEVICE_SETTINGS",
  SAVE_DEVICE_SETTINGS_SUCCESS = "SAVE_DEVICE_SETTINGS_SUCCESS",
  SAVE_DEVICE_SETTINGS_FAIL = "SAVE_DEVICE_SETTINGS_FAIL",
  CLEAR_SAVED_FLAG = "CLEAR_SAVED_FLAG"
}
