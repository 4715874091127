import { NavigationList, NavigationListItemModel } from 'components';
import React, { useMemo } from 'react';

import DeviceSettingsIcon from '@material-ui/icons/Settings';
import StreamsIcon from '@material-ui/icons/SettingsInputHdmi';

interface DeviceFormActionsProps {
  openStreams: () => void;
  openDeviceSettings: () => void;
}

export const DeviceFormActions = React.memo((
  props: DeviceFormActionsProps
) => {
  const items = useMemo<NavigationListItemModel[]>(() => [
    { icon: <StreamsIcon/>, labelKey: "workgroupDetails.nav.streams", onClick: props.openStreams },
    { icon: <DeviceSettingsIcon/>, labelKey: "workgroupDetails.nav.deviceSettings", onClick: props.openDeviceSettings },
  ], [props.openStreams, props.openDeviceSettings]);

  return <NavigationList items={items} />;
});
