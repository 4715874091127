import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: "100%",
      width: "100%"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      minHeight: "1px",
      overflowY: "auto",
      height: 0,
      flex: '1 1 auto',

      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        cursor: "pointer",
        background: "#D4CCC7",
      },

      "&::webkit-scrollbar-track": {
        background: "#9A9791",
        cursor: "pointer",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#9A9791",
        borderRadius: "5px",
        cursor: "pointer",

        "&:hover": {
          background: "#9A9791",
        },
      },
    },
    formContent: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(2)
    },
    panelContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    expanded: {
      marginTop: `${theme.spacing(1)}px !important`,
      marginBottom: `${theme.spacing(1)}px !important`
    },
    selectField: {
      marginTop: theme.spacing(1)
    },
    inlineField: {
      '&:first-child': {
        flexGrow: 1,
        marginRight: theme.spacing(1),
      },
      '&:nth-child(2)': {
        width: '160px'
      }
    },
    loadingContainer: {
      flexGrow: 1,
      display: "flex",
    },
    loading: {
      margin: "auto",
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }
    }
  })

export const useStyles = makeStyles(styles);
