import { JSONSchema } from 'device-settings/duck/json-schema-types';
import React from 'react';

import { AnyEditor } from './anyEditor';
import { ArrayFieldEditor } from './arrayEditor';
import { BooleanEditor } from './booleanEditor';
import { EditorTreeItem } from './editorTreeItem';
import { ObjectEditor } from './objectEditor';
import { TextFieldEditor } from './textFieldEditor';
import { TreeItemLabel } from './treeItemLabel';

interface SettingItemProps {
  schema: JSONSchema;
  required?: boolean;
  propKey: string;
  label?: string;
  labelChildren?: JSX.Element;
  value: any;
  level?: number;
  readOnly: boolean;
}

// TODO: determine if we need this null check
// const schemaAllowsNull = (schema: JSONSchema): boolean => {
//   if (schema.type === 'null') return true;
//   if (schema.type !== undefined) return false;
//   return schema.anyOf.findIndex((s) => s.type === 'null') !== -1;
// };

export const SettingItem: React.FunctionComponent<SettingItemProps> = React.memo(
  (props) => {
    const {
      schema,
      value,
      label,
      propKey,
      labelChildren,
      readOnly,
      required,
    } = props;

    switch (schema.type) {
      case 'object':
        return (
          <ObjectEditor
            required={required}
            value={value}
            propKey={propKey}
            schema={schema}
            label={label}
            labelChildren={labelChildren}
            readOnly={readOnly}
          />
        );
      case 'number':
      case 'integer':
        return (
          <TextFieldEditor
            required={required}
            propKey={propKey}
            schema={schema}
            label={label}
            labelChildren={labelChildren}
            readOnly={readOnly}
          />
        );
      case 'string':
        return (
          <TextFieldEditor
            required={required}
            propKey={propKey}
            schema={schema}
            label={label}
            labelChildren={labelChildren}
            readOnly={readOnly}
          />
        );
      case 'boolean':
        return (
          <BooleanEditor
            required={required}
            propKey={propKey}
            label={label}
            schema={schema}
            readOnly={readOnly}
            labelChildren={labelChildren}
          />
        );
      case 'array':
        return (
          <ArrayFieldEditor
            required={required}
            propKey={propKey}
            label={label}
            schema={schema}
            readOnly={readOnly}
            labelChildren={labelChildren}
          />
        );
      case 'null':
        return (
          <EditorTreeItem
            nodeId={propKey}
            label={
              <TreeItemLabel label={label ? label : propKey}>
                {labelChildren}
              </TreeItemLabel>
            }
          />
        );
      case undefined: {
        return (
          <AnyEditor
            required={required}
            propKey={propKey}
            schema={schema}
            value={value}
            label={label}
            readOnly={readOnly}
            labelChildren={labelChildren}
          />
        );
      }
      default:
        return (
          <EditorTreeItem
            nodeId={propKey}
            label={`Unrecognized schema type '${(schema as any).type}'`}
          />
        );
    }
  }
);
