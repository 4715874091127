import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    breadcrumText: {
      marginTop: "auto",
      marginBottom: "auto",
      paddingTop: theme.spacing(0.25),
    },
    link: {
      cursor: "pointer",
      color: '#CCC !important',

      '&:hover': {
        textDecoration: 'none !important',
        color: 'white !important',
      }
    },
    linkActive: {
      fontWeight: 'bold',
      color: 'white !important',
      textDecoration: 'none !important'
    },
    homeButton: {
      padding: theme.spacing(0.5),
    },
    toolbarGutters: {
      paddingLeft: theme.spacing(1)
    }
  });

export const useStyles = makeStyles(styles);
