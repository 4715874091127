import React from 'react';
import { moodThemeDark } from 'themes.jss';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import { AppBreadcrumbsContainer } from './breadcrumbs.container';
import { useStyles } from './layout.jss';
import { AppSidebar } from './sidebar';

export const AppLayout: React.FC<{}> = (props) => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.app}>
        <AppBreadcrumbsContainer />

        <div className={classes.container}>
          <MuiThemeProvider theme={moodThemeDark}>
            <AppSidebar />
          </MuiThemeProvider>
          <main className={classes.content}>{props.children}</main>
        </div>
      </div>
    </>
  );
};
